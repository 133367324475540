/// <reference types="./guards.d.mts" />
import * as $effect from "../../lustre/lustre/effect.mjs";
import * as $msg from "../data/msg.mjs";
import * as $route from "../data/route.mjs";

export function require_page_more_than_0(page, reroute, next) {
  if (page < 0) {
    let page$1 = page;
    let _pipe = reroute();
    return $route.replace(_pipe);
  } else {
    return next();
  }
}
