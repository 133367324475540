/// <reference types="./file.d.mts" />
import * as $mime_types from "../../../mime_types/mime_types.mjs";
import { CustomType as $CustomType } from "../../gleam.mjs";

export class File extends $CustomType {
  constructor(name, content, mime_type) {
    super();
    this.name = name;
    this.content = content;
    this.mime_type = mime_type;
  }
}
