/// <reference types="./charts.d.mts" />
import * as $element from "../../lustre/lustre/element.mjs";
import * as $bar from "../elements/charts/bar.mjs";
import * as $dataset from "../elements/charts/dataset.mjs";
import * as $pie from "../elements/charts/pie.mjs";

export function bar(orientation, datasets) {
  return $bar.bar(orientation, datasets);
}

export function pie(datasets) {
  return $pie.pie(datasets);
}
