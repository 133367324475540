/// <reference types="./model.d.mts" />
import * as $bright from "../../bright/bright.mjs";
import * as $bool from "../../gleam_stdlib/gleam/bool.mjs";
import * as $dict from "../../gleam_stdlib/gleam/dict.mjs";
import * as $float from "../../gleam_stdlib/gleam/float.mjs";
import * as $int from "../../gleam_stdlib/gleam/int.mjs";
import * as $list from "../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../gleam_stdlib/gleam/option.mjs";
import * as $result from "../../gleam_stdlib/gleam/result.mjs";
import * as $effect from "../../lustre/lustre/effect.mjs";
import * as $computed from "../data/model/computed.mjs";
import * as $data from "../data/model/data.mjs";
import * as $route from "../data/route.mjs";
import * as $guards from "../frontend/guards.mjs";
import { makeError } from "../gleam.mjs";

export function compute_page_max(length, block_size) {
  let $ = (() => {
    let _pipe = length;
    let _pipe$1 = $int.to_float(_pipe);
    return $float.divide(_pipe$1, $int.to_float(block_size));
  })();
  if (!$.isOk()) {
    throw makeError(
      "let_assert",
      "data/model",
      72,
      "compute_page_max",
      "Pattern match failed, no pattern matched the value.",
      { value: $ }
    )
  }
  let page_max = $[0];
  let _pipe = page_max;
  let _pipe$1 = $float.ceiling(_pipe);
  let _pipe$2 = $float.round(_pipe$1);
  let _pipe$3 = $int.subtract(_pipe$2, 1);
  return $int.max(_pipe$3, 0);
}

export function replace_proposal_question_page(data, computed) {
  return $bool.guard(
    data.loading.questions,
    $effect.none(),
    () => {
      let page_size = data.proposal_block_page_size;
      let $ = data.route;
      if ($ instanceof $route.Projects &&
      $[0] instanceof $route.Proposals &&
      $[0].proposal instanceof $route.ShowProposal) {
        let pid = $[0].id;
        let route = $[0].proposal;
        return $guards.require_page_more_than_0(
          route.page,
          () => {
            let _pipe = new $route.ShowProposal(
              route.id,
              route.sheet,
              new $option.None(),
              0,
            );
            let _pipe$1 = ((_capture) => {
              return new $route.Proposals(pid, _capture);
            })(_pipe);
            return new $route.Projects(_pipe$1);
          },
          () => {
            let page_max = (() => {
              let _pipe = computed.displayed_questions_count;
              return compute_page_max(_pipe, page_size);
            })();
            return $bool.guard(
              route.page <= page_max,
              $effect.none(),
              () => {
                let _pipe = new $route.ShowProposal(
                  route.id,
                  route.sheet,
                  route.tab,
                  page_max,
                );
                let _pipe$1 = ((_capture) => {
                  return new $route.Proposals(pid, _capture);
                })(_pipe);
                let _pipe$2 = new $route.Projects(_pipe$1);
                return $route.replace(_pipe$2);
              },
            );
          },
        );
      } else {
        return $effect.none();
      }
    },
  );
}

export function replace_content_library_page(data, computed) {
  let page_size = data.content_library.content_library_block_page_size;
  let $ = data.route;
  if ($ instanceof $route.ContentLibrary && $[0] instanceof $route.DataSources) {
    let page = $[0].page;
    let data_sources = (p) => {
      return new $route.ContentLibrary(new $route.DataSources(p));
    };
    return $guards.require_page_more_than_0(
      page,
      () => { return data_sources(0); },
      () => {
        let page_max = (() => {
          let _pipe = $computed.filtered_non_qna_count(data, computed);
          return compute_page_max(_pipe, page_size);
        })();
        return $bool.guard(
          page <= page_max,
          $effect.none(),
          () => { return $route.replace(data_sources(page_max)); },
        );
      },
    );
  } else if ($ instanceof $route.ContentLibrary &&
  $[0] instanceof $route.QuestionBank) {
    let page = $[0].page;
    let question_bank = (p) => {
      return new $route.ContentLibrary(new $route.QuestionBank(p));
    };
    return $guards.require_page_more_than_0(
      page,
      () => { return question_bank(0); },
      () => {
        let page_max = (() => {
          let _pipe = $computed.filtered_qna_count(data, computed);
          return compute_page_max(_pipe, page_size);
        })();
        return $bool.guard(
          page <= page_max,
          $effect.none(),
          () => { return $route.replace(question_bank(page_max)); },
        );
      },
    );
  } else {
    return $effect.none();
  }
}

export function find_element(data, proposal_id, element_id) {
  let _pipe = data.elements.by_proposal;
  let _pipe$1 = $dict.get(_pipe, proposal_id);
  return $result.then$(
    _pipe$1,
    (_capture) => { return $list.key_find(_capture, element_id); },
  );
}

export function unsubscribe_more_proposal(data) {
  return $effect.from(
    (_) => {
      let unsub = $option.unwrap(
        data.more_proposal_unsubscriber,
        () => { return undefined; },
      );
      return unsub();
    },
  );
}
