/// <reference types="./proposal_builder.d.mts" />
import * as $proposal_builder from "../../../common/data/ephemeral/proposal_builder.mjs";
import * as $project from "../../../common/data/project.mjs";
import * as $proposal from "../../../common/data/proposal.mjs";
import * as $proposal_element from "../../../common/data/proposal_element.mjs";
import * as $question from "../../../common/data/proposal_element/question.mjs";
import * as $http from "../../../gleam_http/gleam/http.mjs";
import * as $promise from "../../../gleam_javascript/gleam/javascript/promise.mjs";
import * as $dynamic from "../../../gleam_stdlib/gleam/dynamic.mjs";
import * as $option from "../../../gleam_stdlib/gleam/option.mjs";
import * as $pair from "../../../gleam_stdlib/gleam/pair.mjs";
import * as $result from "../../../gleam_stdlib/gleam/result.mjs";
import * as $effect from "../../../lustre/lustre/effect.mjs";
import * as $data from "../../data/model/data.mjs";
import * as $msg from "../../data/msg.mjs";
import * as $ask from "../../frontend/ask.mjs";
import * as $error from "../../frontend/error.mjs";
import * as $middleware from "../../frontend/middleware.mjs";
import { Ok, toList } from "../../gleam.mjs";

function create_project(access_token, data, org_id, user_id) {
  let builder = data.proposal_builder;
  let _pipe = $ask.to(new $ask.Heimdall(), toList(["projects"]));
  let _pipe$1 = $ask.bearing(_pipe, access_token);
  let _pipe$2 = $ask.via(_pipe$1, new $http.Post());
  let _pipe$3 = $ask.with$(
    _pipe$2,
    $proposal_builder.encode_project(builder, org_id, user_id),
  );
  let _pipe$4 = $ask.expect(_pipe$3, $project.decode);
  return $ask.run(_pipe$4);
}

function create_proposal(access_token, data, org_id, user_id) {
  let _pipe = $proposal_builder.to_form_data(
    data.proposal_builder,
    org_id,
    user_id,
  );
  let _pipe$1 = $result.replace_error(
    _pipe,
    new $error.NilError("Unable to encode"),
  );
  let _pipe$2 = $promise.resolve(_pipe$1);
  return $promise.try_await(
    _pipe$2,
    (form_data) => {
      let at = (() => {
        let $ = data.proposal_builder.proposal_spreadsheet.xlsx;
        if ($ instanceof $option.Some) {
          return toList(["proposals", "sheet"]);
        } else {
          return toList(["proposals", "doc"]);
        }
      })();
      let _pipe$3 = $ask.to(new $ask.Heimdall(), at);
      let _pipe$4 = $ask.bearing(_pipe$3, access_token);
      let _pipe$5 = $ask.via(_pipe$4, new $http.Post());
      let _pipe$6 = $ask.data(_pipe$5, form_data);
      let _pipe$7 = $ask.expect(
        _pipe$6,
        $dynamic.any(
          toList([
            $dynamic.decode2(
              $pair.new$,
              $dynamic.field("proposal", $proposal.decode),
              $dynamic.field(
                "elements",
                $dynamic.list($proposal_element.decode),
              ),
            ),
            $dynamic.decode2(
              $pair.new$,
              $dynamic.field("proposal", $proposal.decode),
              $dynamic.field(
                "questions",
                $dynamic.list(
                  (dyn) => {
                    let _pipe$7 = $question.decode(dyn);
                    return $result.map(
                      _pipe$7,
                      (var0) => { return new $proposal_element.Question(var0); },
                    );
                  },
                ),
              ),
            ),
          ]),
        ),
      );
      return $ask.run(_pipe$7);
    },
  );
}

export function send_proposal_builder(data) {
  return $middleware.require_access_token(
    data,
    (access_token) => {
      return $middleware.require_org_id(
        data,
        (org_id) => {
          return $middleware.require_user_id(
            data,
            (user_id) => {
              return $effect.from(
                (dispatch) => {
                  let _pipe = data.proposal_builder.project_id;
                  let _pipe$1 = $option.map(
                    _pipe,
                    (var0) => { return new Ok(var0); },
                  );
                  let _pipe$2 = $option.map(_pipe$1, $promise.resolve);
                  let _pipe$3 = $option.lazy_unwrap(
                    _pipe$2,
                    () => {
                      let project = create_project(
                        access_token,
                        data,
                        org_id,
                        user_id,
                      );
                      return $promise.map_try(
                        project,
                        (project) => {
                          dispatch(new $msg.ApiReturnedProject(project));
                          return new Ok(project.id);
                        },
                      );
                    },
                  );
                  let _pipe$4 = $promise.try_await(
                    _pipe$3,
                    (project_id) => {
                      let project_id$1 = new $option.Some(project_id);
                      let _pipe$4 = data.proposal_builder.withFields({
                        project_id: project_id$1
                      });
                      let _pipe$5 = ((_capture) => {
                        return $data.set_proposal_builder(data, _capture);
                      })(_pipe$4);
                      return ((_capture) => {
                        return create_proposal(
                          access_token,
                          _capture,
                          org_id,
                          user_id,
                        );
                      })(_pipe$5);
                    },
                  );
                  $promise.map(
                    _pipe$4,
                    (saved_proposal) => {
                      let _pipe$5 = saved_proposal;
                      let _pipe$6 = $result.replace_error(_pipe$5, toList([]));
                      let _pipe$7 = new $msg.ApiReturnedSavedProposal(_pipe$6);
                      let _pipe$8 = new $msg.MsgHandledProposalBuilder(_pipe$7);
                      return dispatch(_pipe$8);
                    },
                  )
                  return undefined;
                },
              );
            },
          );
        },
      );
    },
  );
}
