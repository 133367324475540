/// <reference types="./show.d.mts" />
import * as $birl from "../../../birl/birl.mjs";
import * as $bright from "../../../bright/bright.mjs";
import * as $data_source from "../../../common/data/data_source.mjs";
import * as $translate from "../../../common/data/translate.mjs";
import * as $user from "../../../common/data/user.mjs";
import * as $button from "../../../ds/ds/components/button.mjs";
import * as $checkbox from "../../../ds/ds/components/checkbox.mjs";
import * as $label from "../../../ds/ds/components/label.mjs";
import * as $layout from "../../../ds/ds/components/layout.mjs";
import * as $pp from "../../../ds/ds/components/profile_picture.mjs";
import * as $table from "../../../ds/ds/components/table.mjs";
import * as $icons from "../../../ds/ds/icons.mjs";
import * as $bool from "../../../gleam_stdlib/gleam/bool.mjs";
import * as $list from "../../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../../gleam_stdlib/gleam/option.mjs";
import * as $order from "../../../gleam_stdlib/gleam/order.mjs";
import * as $pair from "../../../gleam_stdlib/gleam/pair.mjs";
import * as $result from "../../../gleam_stdlib/gleam/result.mjs";
import * as $string from "../../../gleam_stdlib/gleam/string.mjs";
import * as $a from "../../../lustre/lustre/attribute.mjs";
import * as $el from "../../../lustre/lustre/element.mjs";
import * as $h from "../../../lustre/lustre/element/html.mjs";
import * as $e from "../../../lustre/lustre/event.mjs";
import * as $content_library from "../../data/content_library.mjs";
import * as $model from "../../data/model.mjs";
import * as $computed from "../../data/model/computed.mjs";
import * as $msg from "../../data/msg.mjs";
import { Ok, toList, isEqual } from "../../gleam.mjs";
import * as $utils from "../../utils.mjs";
import * as $common from "../../view/common.mjs";
import * as $cl_common from "../../view/content_library/common.mjs";
import * as $ps from "../../view/styles/proposals.mjs";

function sources_upload_action() {
  return $h.label(
    toList([]),
    toList([
      $ps.main_file_input(
        toList([]),
        toList([$button.icon($icons.add()), $h.text("Select files")]),
      ),
      $h.input(
        toList([
          $a.type_("file"),
          $a.attribute("multiple", "true"),
          $a.style(toList([["display", "none"]])),
          $utils.on_files_change(
            (files) => {
              return new Ok(new $msg.UserUploadedSourceFiles(files));
            },
          ),
        ]),
      ),
    ]),
  );
}

function view_sources_table_headers() {
  return $table.headers(
    toList([
      (() => {
        let _pipe = $table.header("");
        return $table.fit(_pipe);
      })(),
      (() => {
        let _pipe = $table.header("Format");
        return $table.fit(_pipe);
      })(),
      $table.header("Name"),
      (() => {
        let _pipe = $table.header("Language");
        return $table.fit(_pipe);
      })(),
      (() => {
        let _pipe = $table.header("Status");
        return $table.fit(_pipe);
      })(),
      $table.header("Owner"),
      (() => {
        let _pipe = $table.header("Expiration Date");
        return $table.fit(_pipe);
      })(),
      $table.header("Tags"),
      (() => {
        let _pipe = $table.header("");
        return $table.fit(_pipe);
      })(),
    ]),
  );
}

function view_sources_data(model, page) {
  let $ = $bright.unwrap(model);
  let data = $[0];
  let computed = $[1];
  let sources = $computed.filtered_non_qna_sources(data, computed);
  let page_size = data.content_library.content_library_block_page_size;
  let start = page * page_size;
  let splitted_sources = (() => {
    let _pipe = sources;
    return $utils.slice(_pipe, start, start + page_size);
  })();
  return $table.body(
    $list.map(
      (() => {
        let search = $string.lowercase(data.content_library.search);
        return $bool.guard(
          $string.is_empty(search),
          splitted_sources,
          () => {
            return $list.filter(
              splitted_sources,
              (source) => {
                let name = $string.lowercase(source.display.name);
                return $string.contains(name, search);
              },
            );
          },
        );
      })(),
      (source) => {
        let user = $list.find(
          data.users,
          (user) => { return isEqual(new $option.Some(user.id), source.owner); },
        );
        let _pipe = $table.row(
          toList([
            (() => {
              let _pipe = $table.cell(
                $checkbox.checkbox(
                  $checkbox.checked(
                    $content_library.is_selected(
                      data.content_library,
                      source.id,
                    ),
                  ),
                  (checked) => {
                    return new $msg.ContentLibrary(
                      new $msg.UserSelectedSource(source.id, checked),
                    );
                  },
                ),
              );
              return $table.stop_cell_propagation(_pipe);
            })(),
            $table.cell(
              $common.small_icon($common.select_icon(source.display.mime_type)),
            ),
            $table.cell($el.text(source.display.name)),
            (() => {
              let _pipe = $table.cell(
                $h.div(
                  toList([]),
                  toList([
                    $h.text(
                      (() => {
                        let $1 = source.display.language;
                        if ($1 instanceof $option.None) {
                          return "";
                        } else if ($1 instanceof $option.Some && $1[0] === "") {
                          return "";
                        } else {
                          let language = $1[0];
                          return $translate.from_iso_string_to_display_string(
                            language,
                          );
                        }
                      })(),
                    ),
                  ]),
                ),
              );
              return $table.justify(_pipe, "start");
            })(),
            $table.cell(
              (() => {
                let $1 = (() => {
                  let $2 = source.status;
                  if ($2 instanceof $data_source.Pending) {
                    return [$label.current(), "Pending"];
                  } else if ($2 instanceof $data_source.NotVerified) {
                    return [$label.neutral(), "Not verified"];
                  } else if ($2 instanceof $data_source.Verified) {
                    return [$label.success(), "Verified"];
                  } else if ($2 instanceof $data_source.Outdated) {
                    return [$label.error(), "Outdated"];
                  } else {
                    return [$label.error(), "Archived"];
                  }
                })();
                let status = $1[0];
                let content = $1[1];
                return $label.label(toList([status, $label.content(content)]));
              })(),
            ),
            $table.cell(
              $common.name_small(
                toList([]),
                (() => {
                  if (!user.isOk()) {
                    return toList([$el.text("")]);
                  } else {
                    let name = user[0].name;
                    let picture = user[0].picture;
                    let initials = user[0].initials;
                    return toList([
                      $pp.from_user(picture, initials, new $pp.Medium()),
                      $el.text(name),
                    ]);
                  }
                })(),
              ),
            ),
            $table.cell(
              (() => {
                let $1 = (() => {
                  let $2 = source.expiration_date;
                  if ($2 instanceof $option.None) {
                    return [$label.neutral(), "None"];
                  } else {
                    let expiration_date = $2[0];
                    let _pipe = (() => {
                      let $3 = $birl.compare(expiration_date, $birl.utc_now());
                      if ($3 instanceof $order.Gt) {
                        return $label.success();
                      } else if ($3 instanceof $order.Eq) {
                        return $label.success();
                      } else {
                        return $label.error();
                      }
                    })();
                    return $pair.new$(
                      _pipe,
                      $utils.time_to_normalized_date(expiration_date, "/"),
                    );
                  }
                })();
                let status = $1[0];
                let content = $1[1];
                return $label.label(toList([status, $label.content(content)]));
              })(),
            ),
            $table.cell(
              $layout.row(
                toList([$layout.gap(8)]),
                toList([]),
                $list.filter_map(
                  source.tags,
                  (tag) => {
                    let tag$1 = $list.find(
                      data.tags,
                      (t) => { return t.id === tag; },
                    );
                    return $result.map(
                      tag$1,
                      (tag) => {
                        return $label.label(
                          toList([$label.neutral(), $label.content(tag.name)]),
                        );
                      },
                    );
                  },
                ),
              ),
            ),
            (() => {
              let _pipe = $table.cell(
                $ps.trash_icon_button(
                  toList([
                    $e.on_click(
                      new $msg.UserDisplayedDeleteDataSourceModal(source.id),
                    ),
                  ]),
                  toList([$icons.tiny($icons.trash())]),
                ),
              );
              let _pipe$1 = $table.justify(_pipe, "end");
              return $table.stop_cell_propagation(_pipe$1);
            })(),
          ]),
        );
        return $table.on_click(
          _pipe,
          new $msg.OpenLink(
            (() => {
              let _pipe$1 = source.display.origin;
              return $option.map(
                _pipe$1,
                (_capture) => {
                  return $pair.new$(source.display.name, _capture);
                },
              );
            })(),
          ),
        );
      },
    ),
  );
}

const empty_text = "Connect your Drive, or upload directly files, directly with the button above.";

function view_sources_table_content(model, page) {
  let $ = $bright.unwrap(model);
  let data = $[0];
  let computed = $[1];
  let is_loading = data.loading.content_library;
  let is_no_content = $list.is_empty(computed.non_qna_sources.all);
  return $bool.lazy_guard(
    is_loading,
    () => { return $cl_common.view_loading_table(7); },
    () => {
      return $bool.lazy_guard(
        is_no_content,
        () => { return $cl_common.view_empty_table(empty_text); },
        () => { return view_sources_data(model, page); },
      );
    },
  );
}

export function view(model, page) {
  let $ = $bright.unwrap(model);
  let data = $[0];
  let computed = $[1];
  let total_count = $computed.filtered_non_qna_count(data, computed);
  return $el.fragment(
    toList([
      $layout.row(
        toList([$layout.gap(32)]),
        toList([]),
        toList([
          $cl_common.state_card(
            "green",
            computed.non_qna_sources_count.verified,
            new $data_source.Verified(),
            isEqual(
              new $option.Some(new $data_source.Verified()),
              data.content_library.filter_selected
            ),
          ),
          $cl_common.state_card(
            "blue",
            computed.non_qna_sources_count.pending,
            new $data_source.Pending(),
            isEqual(
              new $option.Some(new $data_source.Pending()),
              data.content_library.filter_selected
            ),
          ),
          $cl_common.state_card(
            "grey",
            computed.non_qna_sources_count.not_verified,
            new $data_source.NotVerified(),
            isEqual(
              new $option.Some(new $data_source.NotVerified()),
              data.content_library.filter_selected
            ),
          ),
          $cl_common.state_card(
            "red",
            computed.non_qna_sources_count.outdated,
            new $data_source.Outdated(),
            isEqual(
              new $option.Some(new $data_source.Outdated()),
              data.content_library.filter_selected
            ),
          ),
        ]),
      ),
      (() => {
        let _pipe = $cl_common.view_content_library_filters(
          model,
          sources_upload_action(),
        );
        return $el.map(
          _pipe,
          (var0) => { return new $msg.ContentLibrary(var0); },
        );
      })(),
      $table.table(
        toList([
          view_sources_table_headers(),
          view_sources_table_content(model, page),
          $cl_common.footer(data, page, total_count),
        ]),
      ),
    ]),
  );
}
