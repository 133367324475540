/// <reference types="./dataset.d.mts" />
import { CustomType as $CustomType } from "../../gleam.mjs";

export class Dataset extends $CustomType {
  constructor(colors, data, labels) {
    super();
    this.colors = colors;
    this.data = data;
    this.labels = labels;
  }
}
