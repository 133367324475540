/// <reference types="./loading.d.mts" />
import { CustomType as $CustomType } from "../../gleam.mjs";

export class Loading extends $CustomType {
  constructor(connectors, connectors_settings, content_library, copilot_questions, notifications, organization_members, projects, proposals, questions, users) {
    super();
    this.connectors = connectors;
    this.connectors_settings = connectors_settings;
    this.content_library = content_library;
    this.copilot_questions = copilot_questions;
    this.notifications = notifications;
    this.organization_members = organization_members;
    this.projects = projects;
    this.proposals = proposals;
    this.questions = questions;
    this.users = users;
  }
}

export function new$() {
  return new Loading(
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    false,
    true,
  );
}

export function connectors(loading) {
  return loading.withFields({ connectors: false });
}

export function connectors_settings(loading) {
  return loading.withFields({ connectors_settings: false });
}

export function content_library(loading) {
  return loading.withFields({ content_library: false });
}

export function copilot_questions(loading) {
  return loading.withFields({ copilot_questions: false });
}

export function notifications(loading) {
  return loading.withFields({ notifications: false });
}

export function organization_members(loading) {
  return loading.withFields({ organization_members: false });
}

export function projects(loading) {
  return loading.withFields({ projects: false });
}

export function proposals(loading) {
  return loading.withFields({ proposals: false });
}

export function questions_loaded(loading) {
  return loading.withFields({ questions: false });
}

export function questions_loading(loading) {
  return loading.withFields({ questions: true });
}

export function users(loading) {
  return loading.withFields({ users: true });
}
