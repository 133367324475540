/// <reference types="./update.d.mts" />
import * as $pair from "../../../gleam_stdlib/gleam/pair.mjs";
import * as $effect from "../../../lustre/lustre/effect.mjs";
import * as $model from "../../elements/editor/model.mjs";
import { Model } from "../../elements/editor/model.mjs";
import * as $editor from "../../elements/editor/tiptap/editor.mjs";
import * as $events from "../../elements/editor/tiptap/events.mjs";
import { CustomType as $CustomType, makeError } from "../../gleam.mjs";

export class EditorEmitted extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class UserClickedBold extends $CustomType {}

export class UserClickedItalic extends $CustomType {}

export class UserClickedUnderline extends $CustomType {}

export class UserSelectedRatio extends $CustomType {
  constructor(ratio) {
    super();
    this.ratio = ratio;
  }
}

export class UserUpdatedContent extends $CustomType {
  constructor(content) {
    super();
    this.content = content;
  }
}

export class UserUpdatedEditable extends $CustomType {
  constructor(editable) {
    super();
    this.editable = editable;
  }
}

export class Bold extends $CustomType {}

export class Italic extends $CustomType {}

export class Underline extends $CustomType {}

function update_text_style(model, editor, style) {
  if (style instanceof Bold) {
    return [model.withFields({ bold: model.bold }), $editor.bold(editor)];
  } else if (style instanceof Italic) {
    return [model.withFields({ italic: model.italic }), $editor.italic(editor)];
  } else {
    return [
      model.withFields({ underline: model.underline }),
      $editor.underline(editor),
    ];
  }
}

function update_editor_required(model, msg) {
  return $model.require_editor(
    model,
    (editor) => {
      if (msg instanceof UserClickedBold) {
        return update_text_style(model, editor, new Bold());
      } else if (msg instanceof UserClickedItalic) {
        return update_text_style(model, editor, new Italic());
      } else if (msg instanceof UserClickedUnderline) {
        return update_text_style(model, editor, new Underline());
      } else if (msg instanceof EditorEmitted) {
        let msg$1 = msg[0];
        let _pipe = model;
        let _pipe$1 = $model.update_state(_pipe);
        return $pair.new$(_pipe$1, $events.bubble(msg$1));
      } else {
        throw makeError(
          "panic",
          "elements/editor/update",
          47,
          "",
          "[editor/view] Should be handled outside",
          {}
        )
      }
    },
  );
}

export function update(model, msg) {
  if (msg instanceof UserSelectedRatio) {
    let ratio = msg.ratio;
    return [model.withFields({ ratio: ratio }), $effect.none()];
  } else if (msg instanceof UserUpdatedContent) {
    let content = msg.content;
    return [model.withFields({ content: content }), $effect.none()];
  } else if (msg instanceof UserUpdatedEditable) {
    let e = msg.editable;
    return [model.withFields({ editable: e }), $effect.none()];
  } else if (msg instanceof EditorEmitted && msg[0] instanceof $events.Connected) {
    let editor = msg[0][0];
    let _pipe = model;
    let _pipe$1 = $model.set_editor(_pipe, editor);
    return $pair.new$(_pipe$1, $effect.none());
  } else {
    return update_editor_required(model, msg);
  }
}
