import { Extension } from '@tiptap/core'

export const Index = Extension.create({
  addGlobalAttributes() {
    return [
      {
        types: ['heading', 'paragraph'],
        attributes: {
          index: {
            default: null,
            renderHTML: attributes => ({ ['data-index']: attributes.index }),
            parseHTML: element => element.getAttribute('data-index'),
          },
          alignment: {
            default: null,
            renderHTML: attrs => ({ ['data-alignment']: attrs.alignment }),
            parseHTML: element => element.getAttribute('data-alignment'),
          },
          originalStyle: {
            default: null,
            renderHTML: a => ({ ['data-original-style']: a.originalStyle }),
            parseHTML: element => element.getAttribute('data-original-style'),
          },
        },
      },
    ]
  },
})
