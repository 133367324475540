import { Editor, Node } from '@tiptap/core'
import * as h from '../utils/html'

const name = 'steerlabBlock'

export const SteerlabBlock = Node.create({
  name,
  group: 'block',
  content: 'block*',
  addAttributes() {
    return {
      id: {
        parseHTML: element => element.getAttribute('data-id'),
        renderHTML: attributes => ({ ['data-id']: attributes.id }),
      },
      index: {
        default: -1,
        parseHTML: element => element.getAttribute('data-index'),
        renderHTML: attributes => ({ ['data-index']: attributes.index }),
      },
    }
  },
  parseHTML() {
    return [{ tag: 'steerlab-block' }]
  },
  renderHTML({ HTMLAttributes }) {
    return ['steerlab-block', HTMLAttributes, 0]
  },
  addKeyboardShortcuts() {
    return {
      Enter: () => {
        console.log('handleEnter')
        const result = findSteerlabBlockPosition(this.editor)
        if (!result) return false
        const { block, pos, depth } = result
        const endIndex = pos + block.content.size
        const toIndex = this.editor.state.selection.to + 1
        const isEnd = endIndex == toIndex
        if (!isEnd) return false
        const id = crypto.randomUUID()
        const after = this.editor.state.selection.$from.after(depth)
        const content = `<steerlab-block data-id="${id}"><p></p></steerlab-block>`
        this.editor.commands.insertContentAt(after, content)
        return true
      },
    }
  },
  addNodeView() {
    return props => {
      const index = h.attribute('data-index', props.node.attrs.index)
      const id = h.id(props.node.attrs.id)
      const class_ = h.class_('steerlab-block-content')
      const contentDOM = h.div([class_], [])
      const dom = h.element('steerlab-block', [index, id], [contentDOM])
      return { dom, contentDOM }
    }
  },
})

function findSteerlabBlockPosition(editor: Editor) {
  if (editor.state.selection.to !== editor.state.selection.from) return
  let depth = editor.state.selection.$from.depth
  while (depth >= 0) {
    const block = editor.state.selection.$from.node(depth)
    if (block.type.name === 'steerlabBlock') {
      const pos = editor.state.selection.$from.posAtIndex(0, depth)
      return { block, pos, depth }
    }
    depth--
  }
}
