import TipTapTable from '@tiptap/extension-table'
import { class_, div, table, tbody } from '../utils/html'

export const Table = TipTapTable.extend({
  addNodeView() {
    return () => {
      const contentDOM = tbody([], [])
      // prettier-ignore
      const dom =
        div([class_('table-responsive')], [
          table([], [contentDOM])
        ])
      return { dom, contentDOM }
    }
  },
})
