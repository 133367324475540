/// <reference types="./proposal_builder.d.mts" />
import * as $birl from "../../birl/birl.mjs";
import { File } from "../../common/data/ephemeral/file.mjs";
import * as $pb from "../../common/data/ephemeral/proposal_builder.mjs";
import { ProposalBuilder } from "../../common/data/ephemeral/proposal_builder.mjs";
import * as $proposal from "../../common/data/proposal.mjs";
import * as $proposal_element from "../../common/data/proposal_element.mjs";
import * as $qualification_matrix from "../../common/data/qualification_matrix.mjs";
import * as $promise from "../../gleam_javascript/gleam/javascript/promise.mjs";
import * as $bool from "../../gleam_stdlib/gleam/bool.mjs";
import * as $dict from "../../gleam_stdlib/gleam/dict.mjs";
import * as $function from "../../gleam_stdlib/gleam/function.mjs";
import * as $list from "../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../gleam_stdlib/gleam/option.mjs";
import { None } from "../../gleam_stdlib/gleam/option.mjs";
import * as $pair from "../../gleam_stdlib/gleam/pair.mjs";
import * as $result from "../../gleam_stdlib/gleam/result.mjs";
import * as $level from "../../grille_pain/grille_pain/toast/level.mjs";
import * as $effect from "../../lustre/lustre/effect.mjs";
import * as $mime_types from "../../mime_types/mime_types.mjs";
import * as $file from "../../plinth/plinth/browser/file.mjs";
import * as $data from "../data/model/data.mjs";
import { Data } from "../data/model/data.mjs";
import * as $msg from "../data/msg.mjs";
import * as $route from "../data/route.mjs";
import * as $effects from "../frontend/effects.mjs";
import * as $proposal_effects from "../frontend/effects/proposal_builder.mjs";
import { toList, makeError } from "../gleam.mjs";
import * as $document_selector from "../update/document_selector.mjs";
import * as $spreadsheet_selector from "../update/spreadsheet_selector.mjs";

function update_proposal_builder_field(data, msg) {
  if (msg instanceof $msg.UserSubmittedProposalDetails) {
    let is_qm_hidden = !$data.is_qualification_matrix_hidden(data);
    return $bool.guard(
      is_qm_hidden,
      $pb.next_step,
      () => {
        return (t) => {
          let _pipe = t;
          let _pipe$1 = $pb.next_step(_pipe);
          return $pb.next_step(_pipe$1);
        };
      },
    );
  } else if (msg instanceof $msg.UserSkippedProposalQualificationMatrix) {
    let is_qm_hidden = !$data.is_qualification_matrix_hidden(data);
    return $bool.guard(
      is_qm_hidden,
      $pb.next_step,
      () => {
        return (t) => {
          let _pipe = t;
          let _pipe$1 = $pb.next_step(_pipe);
          return $pb.next_step(_pipe$1);
        };
      },
    );
  } else if (msg instanceof $msg.UserUpdatedProjectName) {
    let name = msg.name;
    return $pb.update_project_name(name);
  } else if (msg instanceof $msg.UserUpdatedProjectClient) {
    let project_client = msg.name;
    return $pb.update_project_client(project_client);
  } else if (msg instanceof $msg.UserUpdatedProjectDeadline) {
    let date = msg.deadline;
    return $pb.update_project_deadline(date);
  } else if (msg instanceof $msg.UserEditedProjectCollaborator &&
  msg.action instanceof $msg.UserAddedCollaborator) {
    let u = msg.action.user_id;
    return $pb.add_project_collaborator(u);
  } else if (msg instanceof $msg.UserEditedProjectCollaborator &&
  msg.action instanceof $msg.UserRemovedCollaborator) {
    let u = msg.action.user_id;
    return $pb.remove_project_collaborator(u);
  } else if (msg instanceof $msg.UserSelectedProposalQualificationMatrix) {
    let id = msg.id;
    let qm = msg.answer;
    return $pb.update_qualification_matrix_item(id, qm);
  } else if (msg instanceof $msg.UserAddedProjectLanguage) {
    let l = msg.language;
    return $pb.add_project_language(l);
  } else if (msg instanceof $msg.UserAddedProjectTag) {
    let t = msg.tag;
    return $pb.add_project_tag(t);
  } else if (msg instanceof $msg.UserRemovedProjectLanguage) {
    let l = msg.language;
    return $pb.remove_project_language(l);
  } else if (msg instanceof $msg.UserRemovedProjectTag) {
    let t = msg.tag;
    return $pb.remove_project_tag(t);
  } else if (msg instanceof $msg.UserEditedProposalCollaborator &&
  msg.action instanceof $msg.UserAddedCollaborator) {
    let c = msg.action.user_id;
    return $pb.add_proposal_collaborator(c);
  } else if (msg instanceof $msg.UserAddedProposalLanguage) {
    let l = msg.language;
    return $pb.add_proposal_language(l);
  } else if (msg instanceof $msg.UserEditedProposalCollaborator &&
  msg.action instanceof $msg.UserRemovedCollaborator) {
    let c = msg.action.user_id;
    return $pb.remove_proposal_collaborator(c);
  } else if (msg instanceof $msg.UserRemovedProposalLanguage) {
    let l = msg.language;
    return $pb.remove_proposal_language(l);
  } else if (msg instanceof $msg.UserSubmittedProjectDetails) {
    return $pb.next_step;
  } else {
    throw makeError(
      "panic",
      "update/proposal_builder",
      215,
      "update_proposal_builder_field",
      "Should be handled in outer update",
      {}
    )
  }
}

export function update(data, msg) {
  if (msg instanceof $msg.ApiReturnedSavedProposal && msg.saved_proposal.isOk()) {
    let proposal = msg.saved_proposal[0][0];
    let questions = msg.saved_proposal[0][1];
    let proposals = (() => {
      let _pipe = $list.key_set(data.proposals, proposal.id, proposal);
      return $list.sort(
        _pipe,
        (a, b) => { return $birl.compare(b[1].created_at, a[1].created_at); },
      );
    })();
    let _pipe = questions;
    let _pipe$1 = $list.map(
      _pipe,
      (q) => { return [$proposal_element.id(q), q]; },
    );
    let _pipe$2 = ((_capture) => {
      return $dict.insert(data.elements.by_proposal, proposal.id, _capture);
    })(_pipe$1);
    let _pipe$3 = ((by_proposal) => {
      return data.elements.withFields({ by_proposal: by_proposal });
    })(_pipe$2);
    let _pipe$4 = ((elements) => {
      return data.withFields({ proposals: proposals, elements: elements });
    })(_pipe$3);
    return $pair.new$(
      _pipe$4,
      (() => {
        let _pipe$5 = new $route.ShowProposal(
          proposal.id,
          new None(),
          new None(),
          0,
        );
        let _pipe$6 = ((_capture) => {
          return new $route.Proposals(proposal.project_id, _capture);
        })(_pipe$5);
        let _pipe$7 = new $route.Projects(_pipe$6);
        return $route.replace(_pipe$7);
      })(),
    );
  } else if (msg instanceof $msg.ApiReturnedSavedProposal &&
  !msg.saved_proposal.isOk()) {
    let _pipe = "Unable to save the proposal. Please, retry later";
    let _pipe$1 = $effects.toast(_pipe, new $level.Error());
    return ((_capture) => { return $pair.new$(data, _capture); })(_pipe$1);
  } else if (msg instanceof $msg.ProposalBuilderHandledDocumentSelector &&
  msg.msg instanceof $msg.UserSubmittedDocument) {
    let _pipe = new $route.AddProposal(new None(), true);
    let _pipe$1 = new $route.Projects(_pipe);
    let _pipe$2 = ((_capture) => { return $data.update_route(data, _capture); })(
      _pipe$1,
    );
    return $pair.new$(_pipe$2, $proposal_effects.send_proposal_builder(data));
  } else if (msg instanceof $msg.ProposalBuilderHandledSpreadsheetSelector &&
  msg.msg instanceof $msg.UserSubmittedXlsx) {
    let _pipe = new $route.AddProposal(new None(), true);
    let _pipe$1 = new $route.Projects(_pipe);
    let _pipe$2 = ((_capture) => { return $data.update_route(data, _capture); })(
      _pipe$1,
    );
    return $pair.new$(_pipe$2, $proposal_effects.send_proposal_builder(data));
  } else if (msg instanceof $msg.ProposalBuilderHandledSpreadsheetSelector &&
  msg.msg instanceof $msg.UserCancelledSpreadsheetSelection) {
    let _pipe = data.proposal_builder;
    let _pipe$1 = $pb.back_to_upload(_pipe);
    let _pipe$2 = ((_capture) => {
      return $data.set_proposal_builder(data, _capture);
    })(_pipe$1);
    return $pair.new$(_pipe$2, $effect.none());
  } else if (msg instanceof $msg.ProposalBuilderHandledSpreadsheetSelector) {
    let msg$1 = msg.msg;
    let $ = data.proposal_builder;
    let proposal_spreadsheet = $.proposal_spreadsheet;
    let _pipe = $spreadsheet_selector.update(data, proposal_spreadsheet, msg$1);
    let _pipe$1 = $pair.map_first(
      _pipe,
      (proposal_spreadsheet) => {
        let _pipe$1 = data.proposal_builder.withFields({
          proposal_spreadsheet: proposal_spreadsheet
        });
        let _pipe$2 = (() => {
          let $1 = $spreadsheet_selector.is_xlsx_fetched(msg$1);
          if ($1) {
            return $pb.next_step;
          } else {
            return $function.identity;
          }
        })()(_pipe$1);
        let _pipe$3 = $pb.insert_default_language(proposal_spreadsheet)(_pipe$2);
        return ((_capture) => {
          return $data.set_proposal_builder(data, _capture);
        })(_pipe$3);
      },
    );
    let _pipe$2 = $pair.map_second(
      _pipe$1,
      ((_capture) => {
        return $effect.map(
          _capture,
          (var0) => {
            return new $msg.ProposalBuilderHandledSpreadsheetSelector(var0);
          },
        );
      }),
    );
    return $pair.map_second(
      _pipe$2,
      (_capture) => {
        return $effect.map(
          _capture,
          (var0) => { return new $msg.MsgHandledProposalBuilder(var0); },
        );
      },
    );
  } else if (msg instanceof $msg.ProposalBuilderHandledDocumentSelector) {
    let msg$1 = msg.msg;
    let $ = data.proposal_builder;
    let proposal_document = $.proposal_document;
    let _pipe = $document_selector.update(data, proposal_document, msg$1);
    let _pipe$1 = $pair.map_first(
      _pipe,
      (proposal_document) => {
        let _pipe$1 = data.proposal_builder.withFields({
          proposal_document: proposal_document
        });
        let _pipe$2 = (() => {
          let $1 = $document_selector.is_docx_fetched(msg$1);
          if ($1) {
            return $pb.next_step;
          } else {
            return $function.identity;
          }
        })()(_pipe$1);
        return ((_capture) => {
          return $data.set_proposal_builder(data, _capture);
        })(_pipe$2);
      },
    );
    let _pipe$2 = $pair.map_second(
      _pipe$1,
      ((_capture) => {
        return $effect.map(
          _capture,
          (var0) => {
            return new $msg.ProposalBuilderHandledDocumentSelector(var0);
          },
        );
      }),
    );
    return $pair.map_second(
      _pipe$2,
      (_capture) => {
        return $effect.map(
          _capture,
          (var0) => { return new $msg.MsgHandledProposalBuilder(var0); },
        );
      },
    );
  } else if (msg instanceof $msg.UserUsedProposalQualificationMatrix) {
    let _pipe = data.qualification_matrix;
    let _pipe$1 = $list.map(
      _pipe,
      (_capture) => {
        return $pair.new$(_capture, new $qualification_matrix.NotSelected());
      },
    );
    let _pipe$2 = ((_capture) => {
      return $pb.use_qualification_matrix(data.proposal_builder, _capture);
    })(_pipe$1);
    let _pipe$3 = ((_capture) => {
      return $data.set_proposal_builder(data, _capture);
    })(_pipe$2);
    return $pair.new$(_pipe$3, $effect.none());
  } else if (msg instanceof $msg.UserConfirmedProposalQualificationMatrix) {
    return $pb.require_qualification_matrix(
      data.proposal_builder,
      (qm) => {
        let $ = $proposal.count_qualification_matrix_answers(qm);
        let success = $[0];
        let failure = $[1];
        let total = $[2];
        let is_incomplete = (success + failure) !== total;
        return $bool.lazy_guard(
          is_incomplete,
          () => {
            let _pipe = $data.set_modal(
              data,
              new $data.IncompleteQualificationMatrix(),
            );
            return $pair.new$(_pipe, $effect.none());
          },
          () => {
            let _pipe = (() => {
              let $1 = success > failure;
              if (!$1) {
                return $data.set_modal(
                  data,
                  new $data.DangerousQualificationMatrix(),
                );
              } else {
                let _pipe = data.proposal_builder;
                let _pipe$1 = $pb.next_step(_pipe);
                return ((_capture) => {
                  return $data.set_proposal_builder(data, _capture);
                })(_pipe$1);
              }
            })();
            return $pair.new$(_pipe, $effect.none());
          },
        );
      },
    );
  } else if (msg instanceof $msg.UserConfirmedModalProposalQualificationMatrix) {
    let _pipe = data.proposal_builder;
    let _pipe$1 = $pb.next_step(_pipe);
    let _pipe$2 = ((_capture) => {
      return $data.set_proposal_builder(data, _capture);
    })(_pipe$1);
    let _pipe$3 = $data.set_modal(_pipe$2, new $data.NoModal());
    return $pair.new$(_pipe$3, $effect.none());
  } else if (msg instanceof $msg.UserAddedProjectAttachments) {
    let attachments = msg.attachments;
    return [
      data,
      $effect.from(
        (dispatch) => {
          let _pipe = attachments;
          let _pipe$1 = $list.map(
            _pipe,
            (attachment) => {
              return $promise.map(
                $file.bytes(attachment),
                (body) => {
                  return $result.map(
                    $mime_types.from_string($file.mime(attachment)),
                    (mime) => {
                      return new File($file.name(attachment), body, mime);
                    },
                  );
                },
              );
            },
          );
          let _pipe$2 = $promise.await_list(_pipe$1);
          $promise.map(
            _pipe$2,
            (files) => {
              let _pipe$3 = files;
              let _pipe$4 = $result.all(_pipe$3);
              let _pipe$5 = $result.unwrap(_pipe$4, toList([]));
              let _pipe$6 = new $msg.BrowserExtractedProjectAttachments(_pipe$5);
              let _pipe$7 = new $msg.MsgHandledProposalBuilder(_pipe$6);
              return dispatch(_pipe$7);
            },
          )
          return undefined;
        },
      ),
    ];
  } else if (msg instanceof $msg.UserRemovedProjectAttachment) {
    let index = msg.index;
    let _pipe = data.proposal_builder;
    let _pipe$1 = $pb.remove_project_attachment(index)(_pipe);
    let _pipe$2 = ((_capture) => {
      return $data.set_proposal_builder(data, _capture);
    })(_pipe$1);
    return $pair.new$(_pipe$2, $effect.none());
  } else if (msg instanceof $msg.BrowserExtractedProjectAttachments) {
    let attachments = msg.attachments;
    let _pipe = data.proposal_builder;
    let _pipe$1 = $pb.add_project_attachments(attachments)(_pipe);
    let _pipe$2 = ((_capture) => {
      return $data.set_proposal_builder(data, _capture);
    })(_pipe$1);
    return $pair.new$(_pipe$2, $effect.none());
  } else {
    let _pipe = data.proposal_builder;
    let _pipe$1 = update_proposal_builder_field(data, msg)(_pipe);
    let _pipe$2 = ((_capture) => {
      return $data.set_proposal_builder(data, _capture);
    })(_pipe$1);
    return $pair.new$(_pipe$2, $effect.none());
  }
}
