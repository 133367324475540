import * as analytics from '@vercel/analytics'
import * as speed from '@vercel/speed-insights'
import * as barChart from './elements/charts/elements/bar'
import * as pieChart from './elements/charts/elements/pie'
import * as editor from './elements/editor/tiptap/element/element'
import { main } from './frontend.gleam'

// Setup Vercel toolings.
if (import.meta.env.PROD) {
  analytics.inject({ mode: 'production' })
  speed.injectSpeedInsights()
}

// Register the custom elements upfront.
pieChart.register()
barChart.register()
editor.register()

// Start the Lustre app.
document.addEventListener('DOMContentLoaded', main)
