/// <reference types="./question_mark.d.mts" />
import * as $attribute from "../../../lustre/lustre/attribute.mjs";
import { attribute } from "../../../lustre/lustre/attribute.mjs";
import * as $html from "../../../lustre/lustre/element/html.mjs";
import * as $svg from "../../../lustre/lustre/element/svg.mjs";
import { toList } from "../../gleam.mjs";

export function question_mark() {
  return $html.svg(
    toList([
      attribute("xmlns", "http://www.w3.org/2000/svg"),
      attribute("fill", "none"),
      attribute("viewBox", "0 0 24 24"),
    ]),
    toList([
      $svg.g(
        toList([attribute("clip-path", "url(#clip0_6782_407)")]),
        toList([
          $svg.path(
            toList([
              attribute("fill", "currentColor"),
              attribute(
                "d",
                "M12 22.8503C6.477 22.8503 2 18.3733 2 12.8503C2 7.32734 6.477 2.85034 12 2.85034C17.523 2.85034 22 7.32734 22 12.8503C22 18.3733 17.523 22.8503 12 22.8503ZM12 20.8503C14.1217 20.8503 16.1566 20.0075 17.6569 18.5072C19.1571 17.0069 20 14.9721 20 12.8503C20 10.7286 19.1571 8.69378 17.6569 7.19349C16.1566 5.6932 14.1217 4.85034 12 4.85034C9.87827 4.85034 7.84344 5.6932 6.34315 7.19349C4.84285 8.69378 4 10.7286 4 12.8503C4 14.9721 4.84285 17.0069 6.34315 18.5072C7.84344 20.0075 9.87827 20.8503 12 20.8503ZM11 15.8503H13V17.8503H11V15.8503ZM13 14.2053V14.8503H11V13.3503C11 13.0851 11.1054 12.8308 11.2929 12.6432C11.4804 12.4557 11.7348 12.3503 12 12.3503C12.2841 12.3503 12.5623 12.2696 12.8023 12.1177C13.0423 11.9657 13.2343 11.7487 13.3558 11.4919C13.4773 11.2351 13.5234 10.9491 13.4887 10.6672C13.454 10.3852 13.34 10.1189 13.1598 9.89925C12.9797 9.67958 12.7409 9.51557 12.4712 9.42631C12.2015 9.33705 11.912 9.32621 11.6364 9.39505C11.3608 9.46389 11.1104 9.60957 10.9144 9.81516C10.7183 10.0207 10.5847 10.2778 10.529 10.5563L8.567 10.1633C8.68863 9.55543 8.96951 8.99071 9.38092 8.52693C9.79233 8.06314 10.3195 7.71692 10.9086 7.52366C11.4977 7.3304 12.1275 7.29703 12.7337 7.42696C13.3399 7.55689 13.9007 7.84546 14.3588 8.26317C14.8169 8.68088 15.1559 9.21275 15.3411 9.80441C15.5263 10.3961 15.5511 11.0263 15.4129 11.6306C15.2747 12.235 14.9785 12.7918 14.5545 13.2442C14.1306 13.6966 13.5941 14.0283 13 14.2053Z",
              ),
            ]),
          ),
        ]),
      ),
      $svg.defs(
        toList([]),
        toList([
          $svg.clip_path(
            toList([$attribute.id("clip0_6782_407")]),
            toList([
              $svg.rect(
                toList([
                  attribute("transform", "translate(0 0.850342)"),
                  attribute("fill", "white"),
                  attribute("height", "24"),
                  attribute("width", "24"),
                ]),
              ),
            ]),
          ),
        ]),
      ),
    ]),
  );
}
