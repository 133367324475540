import { Node } from '@tiptap/core'
import { Plugin } from '@tiptap/pm/state'
import * as h from '../../utils/html'

export const AnswerBlock = Node.create({
  name: 'answerBlock',
  group: 'block',
  content: 'block*',
  addAttributes() {
    return {
      id: {
        parseHTML: element => element.getAttribute('data-id'),
        renderHTML: attributes => ({ ['data-id']: attributes.id }),
      },
    }
  },
  addProseMirrorPlugins() {
    return [
      new Plugin({
        props: {
          handleKeyDown: (view, event) => {
            const state: any = view.state
            state.deleting = event.code === 'Backspace'
          },
        },
        filterTransaction: (tr, state) => {
          let shouldContinue = true
          const deleting = (state as any).deleting
          tr.steps.forEach(step => {
            step.getMap().forEach((oldStart, oldEnd) => {
              state.doc.nodesBetween(oldStart, oldEnd, node => {
                if ((step as any).jsonID !== 'replace') return
                if (!deleting) return
                if (node.type.name !== 'answerBlock') return
                if (node.textContent.trim() !== '') return
                shouldContinue = false
              })
            })
          })
          return shouldContinue
        },
      }),
    ]
  },
  parseHTML() {
    return [{ tag: 'steerlab-answer' }]
  },
  renderHTML({ HTMLAttributes }) {
    return ['steerlab-answer', HTMLAttributes, 0]
  },
  addNodeView() {
    return props => {
      const label = h.div(
        [h.contentEditable(false), h.class_('steerlab-answer-label')],
        [h.text('Your answer')]
      )
      const contentDOM = h.div([], [])
      const dom = h.element(
        'steerlab-answer',
        [h.attribute('data-id', props.node.attrs.id)],
        [contentDOM]
      )
      return { dom, contentDOM }
    }
  },
})
