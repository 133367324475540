/// <reference types="./model.d.mts" />
import * as $option from "../../../gleam_stdlib/gleam/option.mjs";
import * as $effect from "../../../lustre/lustre/effect.mjs";
import * as $editor from "../../elements/editor/tiptap/editor.mjs";
import { CustomType as $CustomType } from "../../gleam.mjs";

export class Model extends $CustomType {
  constructor(editor, bold, italic, underline, ratio, content, editable) {
    super();
    this.editor = editor;
    this.bold = bold;
    this.italic = italic;
    this.underline = underline;
    this.ratio = ratio;
    this.content = content;
    this.editable = editable;
  }
}

export function init() {
  return new Model(new $option.None(), false, false, false, 1.0, "", true);
}

export function set_editor(model, editor) {
  let editor$1 = new $option.Some(editor);
  return model.withFields({ editor: editor$1 });
}

export function update_state(model) {
  let $ = model.editor;
  if ($ instanceof $option.None) {
    return model;
  } else {
    let editor = $[0];
    let bold = $editor.is_bold(editor);
    let italic = $editor.is_italic(editor);
    let underline = $editor.is_underline(editor);
    return model.withFields({ bold: bold, italic: italic, underline: underline });
  }
}

export function require_editor(model, next) {
  let $ = model.editor;
  if ($ instanceof $option.Some) {
    let editor = $[0];
    return next(editor);
  } else {
    return [model, $effect.none()];
  }
}
