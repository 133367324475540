/// <reference types="./kind.d.mts" />
import * as $json from "../../../gleam_json/gleam/json.mjs";
import * as $dynamic from "../../../gleam_stdlib/gleam/dynamic.mjs";
import * as $decode from "../../../gleam_stdlib/gleam/dynamic/decode.mjs";
import { Ok, Error, toList, CustomType as $CustomType } from "../../gleam.mjs";

export class Question extends $CustomType {}

export class Section extends $CustomType {}

export class Subsection extends $CustomType {}

export class Other extends $CustomType {}

export function decode(dyn) {
  let $ = $dynamic.string(dyn);
  if ($.isOk() && $[0] === "question") {
    return new Ok(new Question());
  } else if ($.isOk() && $[0] === "question-answer") {
    return new Ok(new Question());
  } else if ($.isOk() && $[0] === "question_answer") {
    return new Ok(new Question());
  } else if ($.isOk() && $[0] === "section") {
    return new Ok(new Section());
  } else if ($.isOk() && $[0] === "subsection") {
    return new Ok(new Subsection());
  } else if ($.isOk() && $[0] === "other") {
    return new Ok(new Other());
  } else {
    return new Error(toList([]));
  }
}

export function decoder() {
  return $decode.then$(
    $decode.string,
    (content) => {
      if (content === "question") {
        return $decode.success(new Question());
      } else if (content === "question-answer") {
        return $decode.success(new Question());
      } else if (content === "question_answer") {
        return $decode.success(new Question());
      } else if (content === "section") {
        return $decode.success(new Section());
      } else if (content === "subsection") {
        return $decode.success(new Subsection());
      } else if (content === "other") {
        return $decode.success(new Other());
      } else {
        return $decode.failure(new Question(), "Kind");
      }
    },
  );
}

export function to_string(kind) {
  if (kind instanceof Other) {
    return "other";
  } else if (kind instanceof Question) {
    return "question";
  } else if (kind instanceof Section) {
    return "section";
  } else {
    return "subsection";
  }
}

export function encode(kind) {
  let _pipe = to_string(kind);
  return $json.string(_pipe);
}

export function decode_question(dyn) {
  let $ = decode(dyn);
  if ($.isOk() && $[0] instanceof Question) {
    return new Ok(new Question());
  } else {
    return new Error(toList([]));
  }
}
