/// <reference types="./effects.d.mts" />
import * as $auth0 from "../../auth0_client/auth0/client.mjs";
import * as $bool from "../../gleam_stdlib/gleam/bool.mjs";
import * as $dynamic from "../../gleam_stdlib/gleam/dynamic.mjs";
import * as $option from "../../gleam_stdlib/gleam/option.mjs";
import { Some } from "../../gleam_stdlib/gleam/option.mjs";
import * as $effect from "../../lustre/lustre/effect.mjs";
import * as $window from "../../plinth/plinth/browser/window.mjs";
import * as $data from "../data/model/data.mjs";
import { Data } from "../data/model/data.mjs";
import * as $msg from "../data/msg.mjs";
import * as $route from "../data/route.mjs";
import * as $auth from "../frontend/auth.mjs";
import * as $content_library_effects from "../frontend/effects/content_library.mjs";
import * as $copilot_effects from "../frontend/effects/copilot.mjs";
import * as $organization_effects from "../frontend/effects/organization.mjs";
import * as $project_effects from "../frontend/effects/project.mjs";
import * as $proposal_effects from "../frontend/effects/proposal.mjs";
import * as $user_effects from "../frontend/effects/user.mjs";
import * as $ffi from "../frontend/ffi.mjs";
import { toList } from "../gleam.mjs";

export function fetch_user_data(data) {
  return $effect.batch(
    toList([
      $copilot_effects.fetch_copilot_questions(data),
      $organization_effects.fetch_connectors_status(data),
      $content_library_effects.fetch_content_library(data),
      $content_library_effects.fetch_status_stats(data),
      $organization_effects.fetch_connectors_settings(data),
      $organization_effects.fetch_connectors_status(data),
      $organization_effects.fetch_organization_members(data),
      $organization_effects.fetch_qualification_matrix(data),
      $organization_effects.fetch_tags(data),
      $project_effects.fetch_projects(data),
      $proposal_effects.fetch_proposals(data),
      $user_effects.fetch_notifications(data),
    ]),
  );
}

export function toast(msg, level) {
  return $effect.from(
    (dispatch) => {
      let _pipe = new $msg.DisplayToast(level, msg);
      return dispatch(_pipe);
    },
  );
}

export function blur_active_element() {
  return $effect.from((_) => { return $ffi.blur_active_element(); });
}

export function on_connected_user(data) {
  return $effect.batch(
    toList([
      $user_effects.register_slack_user(data),
      $user_effects.connect_websocket(data),
      fetch_user_data(data),
    ]),
  );
}

export function listen_popup_messages() {
  return $effect.from(
    (dispatch) => {
      return $window.add_event_listener(
        "message",
        (event) => {
          let data = $ffi.get_data_from_event(event);
          let $ = $dynamic.string(data);
          if ($.isOk() && $[0] === "flow-ended") {
            return dispatch(new $msg.FetchUserData());
          } else {
            return undefined;
          }
        },
      );
    },
  );
}

export function on_route(data, old_route) {
  let $ = data.route;
  if ($ instanceof $route.SlackLogin) {
    return $user_effects.register_slack_user(data);
  } else if ($ instanceof $route.Projects &&
  $[0] instanceof $route.Proposals &&
  $[0].proposal instanceof $route.ShowProposal) {
    let r = $[0].proposal;
    let should_skip = $route.is_already_on_proposal(old_route, r.id);
    return $bool.guard(
      should_skip,
      $effect.none(),
      () => {
        return $effect.from(
          (dispatch) => {
            return dispatch(
              new $msg.ApplicationQueriedProposalElements(0, r.id),
            );
          },
        );
      },
    );
  } else if ($ instanceof $route.Login &&
  $.invitation instanceof Some &&
  $.organization instanceof Some) {
    let invitation = $.invitation[0];
    let organization = $.organization[0];
    let _pipe = new $auth.Invitation(organization, invitation);
    let _pipe$1 = ((_capture) => { return $auth.client(_capture); })(_pipe);
    return $auth0.login_with_redirect(
      _pipe$1,
      (var0) => { return new $msg.AuthStateChanged(var0); },
    );
  } else {
    return $effect.none();
  }
}
