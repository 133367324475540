/// <reference types="./pie.d.mts" />
import * as $attribute from "../../../lustre/lustre/attribute.mjs";
import * as $element from "../../../lustre/lustre/element.mjs";
import * as $dataset from "../../elements/charts/dataset.mjs";
import { toList } from "../../gleam.mjs";

export function pie(datasets) {
  let pie_chart = (_capture) => {
    return $element.element("pie-chart", _capture, toList([]));
  };
  return pie_chart(
    toList([
      $attribute.style(toList([["display", "block"]])),
      $attribute.property("datasets", datasets),
    ]),
  );
}
