/// <reference types="./paragraph.d.mts" />
import * as $json from "../../../gleam_json/gleam/json.mjs";
import * as $bool from "../../../gleam_stdlib/gleam/bool.mjs";
import * as $decode from "../../../gleam_stdlib/gleam/dynamic/decode.mjs";
import * as $int from "../../../gleam_stdlib/gleam/int.mjs";
import * as $list from "../../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../../gleam_stdlib/gleam/option.mjs";
import { None } from "../../../gleam_stdlib/gleam/option.mjs";
import * as $string from "../../../gleam_stdlib/gleam/string.mjs";
import * as $a from "../../../lustre/lustre/attribute.mjs";
import * as $element from "../../../lustre/lustre/element.mjs";
import * as $html from "../../../lustre/lustre/element/html.mjs";
import * as $kind from "../../data/docx/kind.mjs";
import * as $text from "../../data/docx/text.mjs";
import { toList, CustomType as $CustomType } from "../../gleam.mjs";

export class Paragraph extends $CustomType {
  constructor(index, tag, children, alignment, original_style, metadata) {
    super();
    this.index = index;
    this.tag = tag;
    this.children = children;
    this.alignment = alignment;
    this.original_style = original_style;
    this.metadata = metadata;
  }
}

export class Left extends $CustomType {}

export class Center extends $CustomType {}

export class Right extends $CustomType {}

export class Justify extends $CustomType {}

export class Ul extends $CustomType {}

export class Ol extends $CustomType {}

export class Metadata extends $CustomType {
  constructor(kind, is_list, list_type, list_level) {
    super();
    this.kind = kind;
    this.is_list = is_list;
    this.list_type = list_type;
    this.list_level = list_level;
  }
}

export function is_empty(paragraph) {
  return $bool.guard(
    $list.is_empty(paragraph.children),
    true,
    () => {
      let is_filled = (text) => { return $string.trim(text.text) !== ""; };
      return !$list.any(paragraph.children, is_filled);
    },
  );
}

function type_decoder() {
  return $decode.then$(
    $decode.string,
    (string) => {
      if (string === "paragraph") {
        return $decode.success(undefined);
      } else {
        return $decode.failure(undefined, "paragraph");
      }
    },
  );
}

function alignment_decoder() {
  return $decode.then$(
    $decode.string,
    (string) => {
      if (string === "left") {
        return $decode.success(new Left());
      } else if (string === "center") {
        return $decode.success(new Center());
      } else if (string === "right") {
        return $decode.success(new Right());
      } else if (string === "justify") {
        return $decode.success(new Justify());
      } else {
        return $decode.failure(new Left(), "alignment");
      }
    },
  );
}

function encode_alignment(alignment) {
  if (alignment instanceof Center) {
    return $json.string("center");
  } else if (alignment instanceof Justify) {
    return $json.string("justify");
  } else if (alignment instanceof Left) {
    return $json.string("left");
  } else {
    return $json.string("right");
  }
}

function alignment_to_string(alignment) {
  if (alignment instanceof Center) {
    return "center";
  } else if (alignment instanceof Justify) {
    return "justify";
  } else if (alignment instanceof Left) {
    return "left";
  } else {
    return "right";
  }
}

function list_type_decoder() {
  return $decode.then$(
    $decode.string,
    (string) => {
      if (string === "ul") {
        return $decode.success(new Ul());
      } else if (string === "ol") {
        return $decode.success(new Ol());
      } else {
        return $decode.failure(new Ul(), "list_type");
      }
    },
  );
}

function encode_list_type(list_type) {
  if (list_type instanceof Ol) {
    return $json.string("ol");
  } else {
    return $json.string("ul");
  }
}

export function encode(paragraph) {
  return $json.object(
    toList([
      ["type", $json.string("paragraph")],
      ["index", $json.int(paragraph.index)],
      ["html_tag", $json.string(paragraph.tag)],
      ["children", $json.array(paragraph.children, $text.encode)],
      ["alignment", encode_alignment(paragraph.alignment)],
      ["original_style", $json.string(paragraph.original_style)],
      [
        "metadata",
        $json.object(
          toList([
            [
              "kind",
              $json.nullable(
                paragraph.metadata.kind,
                (a) => { return $json.string($kind.to_string(a)); },
              ),
            ],
            ["is_list", $json.bool(paragraph.metadata.is_list)],
            [
              "list_type",
              $json.nullable(paragraph.metadata.list_type, encode_list_type),
            ],
            [
              "list_level",
              $json.nullable(paragraph.metadata.list_level, $json.int),
            ],
          ]),
        ),
      ],
    ]),
  );
}

function list_type_to_tag(list_type) {
  if (list_type instanceof Ul) {
    return "ul";
  } else {
    return "ol";
  }
}

export function to_html(paragraph) {
  let attributes = (() => {
    let _pipe = toList([
      ["data-index", $int.to_string(paragraph.index)],
      ["data-alignment", alignment_to_string(paragraph.alignment)],
      ["data-original-style", paragraph.original_style],
    ]);
    return $list.map(_pipe, (a) => { return $a.attribute(a[0], a[1]); });
  })();
  let $ = paragraph.metadata;
  let is_list = $.is_list;
  let list_type = $.list_type;
  let list_level = $.list_level;
  if (is_list &&
  list_level instanceof $option.Some &&
  list_type instanceof $option.Some) {
    let list_type$1 = list_type[0];
    let tag = list_type_to_tag(list_type$1);
    return $element.element(
      tag,
      attributes,
      $list.map(
        paragraph.children,
        (child) => {
          return $html.li(toList([]), toList([$text.to_html(child)]));
        },
      ),
    );
  } else if (is_list) {
    return $element.element(
      paragraph.tag,
      attributes,
      $list.map(paragraph.children, (child) => { return $text.to_html(child); }),
    );
  } else {
    return $element.element(
      paragraph.tag,
      attributes,
      $list.map(paragraph.children, (child) => { return $text.to_html(child); }),
    );
  }
}

export function to_labelling(paragraph) {
  let content = to_html(paragraph);
  let is_empty$1 = is_empty(paragraph);
  return $bool.guard(
    is_empty$1,
    content,
    () => {
      let index = $a.attribute("data-index", $int.to_string(paragraph.index));
      let kind = (() => {
        let _pipe = paragraph.metadata.kind;
        let _pipe$1 = $option.map(_pipe, $kind.to_string);
        let _pipe$2 = $option.map(
          _pipe$1,
          (_capture) => { return $a.attribute("kind", _capture); },
        );
        return $option.unwrap(_pipe$2, $a.none());
      })();
      return $element.element(
        "document-labeller",
        toList([index, kind]),
        toList([content]),
      );
    },
  );
}

function metadata_decoder() {
  return $decode.optional_field(
    "kind",
    new None(),
    $decode.optional($kind.decoder()),
    (kind) => {
      return $decode.optional_field(
        "is_list",
        false,
        $decode.bool,
        (is_list) => {
          return $decode.optional_field(
            "list_type",
            new None(),
            $decode.optional(list_type_decoder()),
            (list_type) => {
              return $decode.optional_field(
                "list_level",
                new None(),
                $decode.optional($decode.int),
                (list_level) => {
                  return $decode.success(
                    new Metadata(kind, is_list, list_type, list_level),
                  );
                },
              );
            },
          );
        },
      );
    },
  );
}

function empty_metadata() {
  return new Metadata(new None(), false, new None(), new None());
}

export function decoder() {
  return $decode.field(
    "type",
    type_decoder(),
    (_) => {
      return $decode.field(
        "index",
        $decode.int,
        (index) => {
          return $decode.field(
            "html_tag",
            $decode.string,
            (tag) => {
              return $decode.optional_field(
                "children",
                toList([]),
                (() => {
                  let _pipe = $decode.optional($decode.list($text.decoder()));
                  return $decode.map(
                    _pipe,
                    (_capture) => {
                      return $option.unwrap(_capture, toList([]));
                    },
                  );
                })(),
                (children) => {
                  return $decode.optional_field(
                    "alignment",
                    new Left(),
                    (() => {
                      let _pipe = $decode.optional(alignment_decoder());
                      return $decode.map(
                        _pipe,
                        (_capture) => {
                          return $option.unwrap(_capture, new Left());
                        },
                      );
                    })(),
                    (alignment) => {
                      return $decode.optional_field(
                        "original_style",
                        "",
                        (() => {
                          let _pipe = $decode.optional($decode.string);
                          return $decode.map(
                            _pipe,
                            (_capture) => {
                              return $option.unwrap(_capture, "");
                            },
                          );
                        })(),
                        (original_style) => {
                          return $decode.optional_field(
                            "metadata",
                            empty_metadata(),
                            metadata_decoder(),
                            (metadata) => {
                              return $decode.success(
                                new Paragraph(
                                  index,
                                  tag,
                                  children,
                                  alignment,
                                  original_style,
                                  metadata,
                                ),
                              );
                            },
                          );
                        },
                      );
                    },
                  );
                },
              );
            },
          );
        },
      );
    },
  );
}
