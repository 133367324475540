import * as tiptap from '@tiptap/core'
import { Color } from '@tiptap/extension-color'
import TableCell from '@tiptap/extension-table-cell'
import TableHeader from '@tiptap/extension-table-header'
import TableRow from '@tiptap/extension-table-row'
import TextAlign from '@tiptap/extension-text-align'
import TextStyle from '@tiptap/extension-text-style'
import Underline from '@tiptap/extension-underline'
import StarterKit from '@tiptap/starter-kit'
import { DocumentLabeller } from './modules/document-labeller.node'
import { FontSize } from './modules/font-size.extension'
import { Index } from './modules/index.extension'
import { LineHeight } from './modules/line-height.extension'
import { AnswerBlock } from './modules/question/answer-block.node'
import { QuestionBlock } from './modules/question/question-block.node'
import { SubjectBlock } from './modules/question/subject-block.node'
import { SteerlabBlock } from './modules/steerlab-block.node'
import { Table } from './modules/table.extend'
import inlineDebug from './utils/debug-styling.css?inline'
import * as html from './utils/html'
import inlineStyle from './utils/styling.css?inline'
import * as utils from './utils/utils'
import { Attribute } from './utils/utils'

export function register() {
  customElements.define('tiptap-editor', TiptapEditor)
}

class TiptapEditor extends HTMLElement {
  #shadow: ShadowRoot
  #container: HTMLElement
  #editor?: tiptap.Editor
  #interval?: NodeJS.Timeout
  #contents: { [key: string]: string }
  content: string

  static get observedAttributes() {
    const observed = Object.values(utils.attributes)
    return observed
  }

  constructor() {
    super()
    this.content ??= ''
    this.#shadow = this.attachShadow({ mode: 'open' })
    this.#container = html.div([], [])
    this.#container.style.setProperty('--ratio', '1.0')
    this.#container.style.setProperty('--font-size', '1.0rem')
    this.#container.style.setProperty('--border-color', '#ccc')
    this.#container.style.setProperty('--header-background', '#eee')
    this.#container.style.setProperty('--selected-cell-background', '#ccf2')
    this.#container.setAttribute('class', 'tiptap-editor')
    this.#shadow.appendChild(this.#container)
    this.#contents = {}
    this.#appendStyles()
  }

  connectedCallback() {
    const types = ['heading', 'paragraph', 'textStyle']
    const editable = this.#readEditableState()
    if (editable) this.#setupEditableEditor()
    this.#editor = new tiptap.Editor({
      element: this.#container,
      extensions: [
        DocumentLabeller,
        AnswerBlock,
        SubjectBlock,
        QuestionBlock,
        SteerlabBlock,
        Index,
        StarterKit,
        Table.configure({ resizable: true }),
        TableHeader,
        TableRow,
        TableCell,
        Underline,
        TextStyle,
        Color,
        TextAlign.configure({ types }),
        FontSize.configure({ types }),
        LineHeight.configure({ types }),
      ],
      content: this.content,
      injectCSS: false,
      editable,
      onBeforeCreate: () => this.#dispatchEvent('beforecreate'),
      onBlur: p => this.#dispatchEvent('blur', { transaction: p.transaction }),
      onContentError: () => this.#dispatchEvent('contenterror'),
      onCreate: () => this.#dispatchEvent('create'),
      onDestroy: () => this.#dispatchEvent('destroy'),
      onDrop: () => this.#dispatchEvent('drop'),
      onFocus: p =>
        this.#dispatchEvent('focus', { transaction: p.transaction }),
      onPaste: () => this.#dispatchEvent('paste'),
      onSelectionUpdate: p =>
        this.#dispatchEvent('selectionupdate', { transaction: p.transaction }),
      onTransaction: p =>
        this.#dispatchEvent('transaction', { transaction: p.transaction }),
      onUpdate: p =>
        this.#dispatchEvent('update', { transaction: p.transaction }),
    })
    this.#dispatchEvent('connected')
  }

  attributeChangedCallback(name: Attribute, _previous: string, next: string) {
    switch (name) {
      case utils.attributes.ratio: {
        const ratio = next
        this.#container.style.setProperty('--ratio', ratio)
        return Object.entries(utils.styles(ratio)).forEach(([key, value]) => {
          this.#container.style.setProperty(key, value)
        })
      }
      default:
        return this.#setProperty(name, next)
    }
  }

  disconnectedCallback() {
    console.log('disconnected')
    clearInterval(this.#interval)
    this.#saveContent()
    this.#editor?.destroy()
  }

  getContent() {
    return this.#editor?.getHTML()
  }

  async #appendStyles() {
    const stylesheet = new CSSStyleSheet()
    await stylesheet.replace(inlineStyle)
    this.#shadow.adoptedStyleSheets.push(stylesheet)
    if (import.meta.env.VITE_EDITOR_DEBUG === 'true') {
      const stylesheet = new CSSStyleSheet()
      await stylesheet.replace(inlineDebug)
      this.#shadow.adoptedStyleSheets.push(stylesheet)
    }
  }

  #dispatchEvent(name: string, details: object = {}) {
    const content = { detail: { editor: this.#editor, ...details } }
    const event = new CustomEvent(name, content)
    this.dispatchEvent(event)
  }

  #setProperty(name: string, value: string) {
    const property = utils.getProperty(name)
    this.#container.style.setProperty(property, value)
  }

  #readEditableState() {
    const attribute = this.attributes.getNamedItem('editable')
    const value = attribute?.value
    if (value === 'false') return false
    return true
  }

  #setupEditableEditor() {
    const dom = new DOMParser().parseFromString(this.content, 'text/html')
    for (const child of dom.body.children) {
      const id = child.getAttribute('data-id')
      if (id) this.#contents[id] = child.innerHTML
    }
    this.#interval = setInterval(() => this.#saveContent(), 2000)
    this.#saveContent()
  }

  #saveContent() {
    const content = this.#editor?.getHTML() ?? ''
    const dom = new DOMParser().parseFromString(content, 'text/html')
    for (const child of dom.body.children) {
      const id = child.getAttribute('data-id')
      if (!id) continue
      const type = child.tagName.toLowerCase()
      const previousContent = this.#contents[id]
      const innerHTML = child.innerHTML
      if (previousContent === innerHTML) continue
      this.#contents[id] = innerHTML
      switch (type) {
        case 'steerlab-block': {
          const node = { type, id, content: innerHTML }
          const event = new CustomEvent('updated', { detail: node })
          this.dispatchEvent(event)
          break
        }
        case 'steerlab-question': {
          const subject = child.getElementsByTagName('steerlab-subject')[0]
          const answer_ = child.getElementsByTagName('steerlab-answer')[0]
          if (subject && answer_) {
            const question = subject.innerHTML
            const answer = answer_.innerHTML
            const node = { type, id, question, answer }
            const event = new CustomEvent('updated', { detail: node })
            this.dispatchEvent(event)
          }
          break
        }
        default:
          break
      }
    }
  }
}
