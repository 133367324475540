/// <reference types="./events.d.mts" />
import * as $dynamic from "../../../../gleam_stdlib/gleam/dynamic.mjs";
import * as $decode from "../../../../gleam_stdlib/gleam/dynamic/decode.mjs";
import * as $result from "../../../../gleam_stdlib/gleam/result.mjs";
import * as $attribute from "../../../../lustre/lustre/attribute.mjs";
import * as $event from "../../../../lustre/lustre/event.mjs";
import * as $editor from "../../../elements/editor/tiptap/editor.mjs";
import * as $transaction from "../../../elements/editor/tiptap/transaction.mjs";
import * as $ffi from "../../../frontend/ffi.mjs";
import { toList, CustomType as $CustomType } from "../../../gleam.mjs";

export class BeforeCreate extends $CustomType {}

export class Blur extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class ContentError extends $CustomType {}

export class Create extends $CustomType {}

export class Destroy extends $CustomType {}

export class Drop extends $CustomType {}

export class Focus extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class Paste extends $CustomType {}

export class SelectionUpdate extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class Transaction extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class Update extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class Connected extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class Updated extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export function bubble(event) {
  let event$1 = $ffi.coerce(event);
  return $event.emit("passthrough", event$1);
}

export function listen() {
  return $event.on(
    "passthrough",
    (event) => {
      let _pipe = $decode.at(toList(["detail"]), $decode.dynamic);
      let _pipe$1 = $decode.map(_pipe, $ffi.coerce);
      let _pipe$2 = ((_capture) => { return $decode.run(event, _capture); })(
        _pipe$1,
      );
      return $result.replace_error(_pipe$2, toList([]));
    },
  );
}

export const empty = /* @__PURE__ */ toList([
  ["beforecreate", /* @__PURE__ */ new BeforeCreate()],
  ["contenterror", /* @__PURE__ */ new ContentError()],
  ["create", /* @__PURE__ */ new Create()],
  ["destroy", /* @__PURE__ */ new Destroy()],
  ["drop", /* @__PURE__ */ new Drop()],
  ["paste", /* @__PURE__ */ new Paste()],
]);

export const transactions = /* @__PURE__ */ toList([
  ["blur", (var0) => { return new Blur(var0); }],
  ["transaction", (var0) => { return new Transaction(var0); }],
  ["focus", (var0) => { return new Focus(var0); }],
  ["selectionupdate", (var0) => { return new SelectionUpdate(var0); }],
  ["update", (var0) => { return new Update(var0); }],
]);
