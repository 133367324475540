/// <reference types="./bar.d.mts" />
import * as $attribute from "../../../lustre/lustre/attribute.mjs";
import * as $element from "../../../lustre/lustre/element.mjs";
import * as $dataset from "../../elements/charts/dataset.mjs";
import { toList } from "../../gleam.mjs";

export function bar(orientation, datasets) {
  let bar_chart = (_capture) => {
    return $element.element("bar-chart", _capture, toList([]));
  };
  return bar_chart(
    toList([
      $attribute.style(toList([["display", "block"]])),
      $attribute.property("datasets", datasets),
      $attribute.property("orientation", orientation),
    ]),
  );
}
