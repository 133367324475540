/// <reference types="./document_selector.d.mts" />
import * as $docx from "../../common/data/docx.mjs";
import * as $kind from "../../common/data/docx/kind.mjs";
import * as $page_break from "../../common/data/docx/page_break.mjs";
import * as $paragraph from "../../common/data/docx/paragraph.mjs";
import * as $table from "../../common/data/docx/table.mjs";
import * as $document_selector from "../../common/data/ephemeral/document_selector.mjs";
import { DocumentSelector } from "../../common/data/ephemeral/document_selector.mjs";
import { File } from "../../common/data/ephemeral/file.mjs";
import * as $form_data from "../../gleam_fetch/gleam/fetch/form_data.mjs";
import * as $http from "../../gleam_http/gleam/http.mjs";
import * as $promise from "../../gleam_javascript/gleam/javascript/promise.mjs";
import * as $json from "../../gleam_json/gleam/json.mjs";
import * as $bool from "../../gleam_stdlib/gleam/bool.mjs";
import * as $dict from "../../gleam_stdlib/gleam/dict.mjs";
import * as $dynamic from "../../gleam_stdlib/gleam/dynamic.mjs";
import * as $decode from "../../gleam_stdlib/gleam/dynamic/decode.mjs";
import * as $function from "../../gleam_stdlib/gleam/function.mjs";
import * as $int from "../../gleam_stdlib/gleam/int.mjs";
import * as $io from "../../gleam_stdlib/gleam/io.mjs";
import * as $list from "../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../gleam_stdlib/gleam/option.mjs";
import { Some } from "../../gleam_stdlib/gleam/option.mjs";
import * as $pair from "../../gleam_stdlib/gleam/pair.mjs";
import * as $result from "../../gleam_stdlib/gleam/result.mjs";
import * as $toast from "../../grille_pain/grille_pain/lustre/toast.mjs";
import * as $effect from "../../lustre/lustre/effect.mjs";
import * as $mime_types from "../../mime_types/mime_types.mjs";
import * as $file from "../../plinth/plinth/browser/file.mjs";
import * as $data from "../data/model/data.mjs";
import * as $msg from "../data/msg.mjs";
import * as $events from "../elements/editor/tiptap/events.mjs";
import * as $transaction from "../elements/editor/tiptap/transaction.mjs";
import * as $ask from "../frontend/ask.mjs";
import * as $middleware from "../frontend/middleware.mjs";
import { Ok, toList, makeError, isEqual } from "../gleam.mjs";

export function is_docx_fetched(msg) {
  if (msg instanceof $msg.ApiFetchedDocx && msg.decoded_docx.isOk()) {
    return true;
  } else {
    return false;
  }
}

function decode_docx(access_token, org_id, user_id, file) {
  let _pipe = $ask.to(new $ask.Nabu(), toList(["decode-docx"]));
  let _pipe$1 = $ask.via(_pipe, new $http.Post());
  let _pipe$2 = $ask.bearing(_pipe$1, access_token);
  let _pipe$3 = $ask.data(
    _pipe$2,
    (() => {
      let _pipe$3 = $json.object(
        toList([["org_id", org_id], ["user_id", user_id]]),
      );
      let _pipe$4 = $json.to_string(_pipe$3);
      let _pipe$5 = ((_capture) => {
        return $form_data.append($form_data.new$(), "content", _capture);
      })(_pipe$4);
      return $form_data.append_bits(_pipe$5, "blob", file.content);
    })(),
  );
  let _pipe$4 = $ask.expect(
    _pipe$3,
    (dyn) => {
      let _pipe$4 = $decode.run(dyn, $docx.decoder());
      let _pipe$5 = $result.map_error(_pipe$4, $io.debug);
      return $result.replace_error(_pipe$5, toList([]));
    },
  );
  return $ask.run(_pipe$4);
}

function detect_paragraphs(access_token, org_id, user_id, docx) {
  let elements = $json.array(docx.elements, $docx.encode_element);
  let _pipe = $ask.to(new $ask.Nabu(), toList(["detect-paragraphs"]));
  let _pipe$1 = $ask.via(_pipe, new $http.Post());
  let _pipe$2 = $ask.bearing(_pipe$1, access_token);
  let _pipe$3 = $ask.with$(
    _pipe$2,
    $json.object(
      toList([["org_id", org_id], ["user_id", user_id], ["elements", elements]]),
    ),
  );
  let _pipe$4 = $ask.expect(
    _pipe$3,
    (dyn) => {
      let _pipe$4 = $decode.run(dyn, $decode.list($docx.element_decoder()));
      let _pipe$5 = $result.map_error(_pipe$4, $io.debug);
      return $result.replace_error(_pipe$5, toList([]));
    },
  );
  return $ask.run(_pipe$4);
}

function send_docx_decoding_request(data, file) {
  return $middleware.require_access_token(
    data,
    (access_token) => {
      return $middleware.require_org_id(
        data,
        (org_id) => {
          return $middleware.require_user_id(
            data,
            (user_id) => {
              return $effect.from(
                (dispatch) => {
                  return $function.tap(
                    undefined,
                    (_) => {
                      let org_id$1 = $json.string(org_id);
                      let user_id$1 = $json.string(user_id);
                      let docx = decode_docx(
                        access_token,
                        org_id$1,
                        user_id$1,
                        file,
                      );
                      return $promise.try_await(
                        docx,
                        (docx) => {
                          let paragraphs = detect_paragraphs(
                            access_token,
                            org_id$1,
                            user_id$1,
                            docx,
                          );
                          return $promise.map_try(
                            paragraphs,
                            (paragraphs) => {
                              let _pipe = docx.withFields({
                                elements: $list.map2(
                                  docx.elements,
                                  paragraphs,
                                  (el1, el2) => {
                                    if (el1 instanceof $docx.PageBreak &&
                                    el2 instanceof $docx.PageBreak &&
                                    (el1[0].index === el2[0].index)) {
                                      let p1 = el1[0];
                                      let p2 = el2[0];
                                      return new $docx.PageBreak(
                                        p1.withFields({ metadata: p2.metadata }),
                                      );
                                    } else if (el1 instanceof $docx.Paragraph &&
                                    el2 instanceof $docx.Paragraph &&
                                    (el1[0].index === el2[0].index)) {
                                      let p1 = el1[0];
                                      let p2 = el2[0];
                                      return new $docx.Paragraph(
                                        p1.withFields({ metadata: p2.metadata }),
                                      );
                                    } else if (el1 instanceof $docx.Table &&
                                    el2 instanceof $docx.Table &&
                                    (el1[0].index === el2[0].index)) {
                                      let p1 = el1[0];
                                      let p2 = el2[0];
                                      return new $docx.Table(
                                        p1.withFields({ metadata: p2.metadata }),
                                      );
                                    } else {
                                      return el1;
                                    }
                                  },
                                )
                              });
                              let _pipe$1 = new Ok(_pipe);
                              let _pipe$2 = new $msg.ApiFetchedDocx(_pipe$1);
                              dispatch(_pipe$2)
                              return new Ok(undefined);
                            },
                          );
                        },
                      );
                    },
                  );
                },
              );
            },
          );
        },
      );
    },
  );
}

export function update(data, selector, msg) {
  if (msg instanceof $msg.ApiFetchedDocx && msg.decoded_docx.isOk()) {
    let document = msg.decoded_docx[0];
    let _pipe = $document_selector.set_document(selector, document);
    let _pipe$1 = ((selector) => {
      return selector.withFields({ loading: false });
    })(_pipe);
    return $pair.new$(_pipe$1, $effect.none());
  } else if (msg instanceof $msg.ApiFetchedDocx) {
    return [
      selector.withFields({ loading: false }),
      $toast.error("Impossible to handle file. Please, retry later."),
    ];
  } else if (msg instanceof $msg.BrowserDecodedDocxFile) {
    let file_ = msg.file;
    let file = new Some(file_);
    let _pipe = selector.withFields({ file: file, loading: true });
    return $pair.new$(_pipe, send_docx_decoding_request(data, file_));
  } else if (msg instanceof $msg.UserIssuedTransaction) {
    let event = msg.event;
    if (event instanceof $events.BeforeCreate) {
      return [selector, $effect.none()];
    } else if (event instanceof $events.ContentError) {
      return [selector, $effect.none()];
    } else if (event instanceof $events.Create) {
      return [selector, $effect.none()];
    } else if (event instanceof $events.Destroy) {
      return [selector, $effect.none()];
    } else if (event instanceof $events.Drop) {
      return [selector, $effect.none()];
    } else if (event instanceof $events.Connected) {
      return [selector, $effect.none()];
    } else if (event instanceof $events.Blur) {
      return [selector, $effect.none()];
    } else if (event instanceof $events.Focus) {
      return [selector, $effect.none()];
    } else if (event instanceof $events.SelectionUpdate) {
      return [selector, $effect.none()];
    } else if (event instanceof $events.Update) {
      return [selector, $effect.none()];
    } else if (event instanceof $events.Updated) {
      return [selector, $effect.none()];
    } else if (event instanceof $events.Paste) {
      return [selector, $effect.none()];
    } else {
      let tx = event[0];
      let meta = $transaction.meta(tx);
      let should_skip = !isEqual($dict.get(meta, "action"), new Ok("labelling"));
      let default$ = [selector, $effect.none()];
      return $bool.guard(
        should_skip,
        default$,
        () => {
          return ((_capture) => { return $result.unwrap(_capture, default$); })(
            $result.try$(
              $dict.get(meta, "index"),
              (index) => {
                return $result.try$(
                  $int.parse(index),
                  (index) => {
                    return $result.try$(
                      $dict.get(meta, "kind"),
                      (kind) => {
                        return $result.try$(
                          $dict.get(meta, "previous-kind"),
                          (previous) => {
                            let kind$1 = $decode.run(
                              $dynamic.from(kind),
                              $kind.decoder(),
                            );
                            return $result.try$(
                              (() => {
                                let _pipe = kind$1;
                                return $result.replace_error(_pipe, undefined);
                              })(),
                              (kind) => {
                                let previous$1 = $decode.run(
                                  $dynamic.from(previous),
                                  $kind.decoder(),
                                );
                                return $result.map(
                                  (() => {
                                    let _pipe = previous$1;
                                    return $result.replace_error(
                                      _pipe,
                                      undefined,
                                    );
                                  })(),
                                  (previous) => {
                                    let _pipe = $document_selector.modify_count(
                                      selector,
                                      previous,
                                      kind,
                                    );
                                    let _pipe$1 = $document_selector.update_element(
                                      _pipe,
                                      index,
                                      kind,
                                    );
                                    return $pair.new$(_pipe$1, $effect.none());
                                  },
                                );
                              },
                            );
                          },
                        );
                      },
                    );
                  },
                );
              },
            ),
          );
        },
      );
    }
  } else if (msg instanceof $msg.UserSelectedDocx) {
    let file = msg.file;
    return [
      selector,
      $effect.from(
        (dispatch) => {
          return $function.tap(
            undefined,
            (_) => {
              return $promise.map(
                $file.bytes(file),
                (content) => {
                  return $result.map(
                    $mime_types.from_string($file.mime(file)),
                    (mime_type) => {
                      let file$1 = new File(
                        $file.name(file),
                        content,
                        mime_type,
                      );
                      return dispatch(new $msg.BrowserDecodedDocxFile(file$1));
                    },
                  );
                },
              );
            },
          );
        },
      ),
    ];
  } else {
    throw makeError(
      "panic",
      "update/document_selector",
      99,
      "update",
      "Unhandled document_selector msg",
      {}
    )
  }
}
