/// <reference types="./docx.d.mts" />
import * as $json from "../../gleam_json/gleam/json.mjs";
import * as $dict from "../../gleam_stdlib/gleam/dict.mjs";
import * as $decode from "../../gleam_stdlib/gleam/dynamic/decode.mjs";
import * as $list from "../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../gleam_stdlib/gleam/option.mjs";
import { Some } from "../../gleam_stdlib/gleam/option.mjs";
import * as $utils from "../common/utils.mjs";
import * as $kind from "../data/docx/kind.mjs";
import * as $page_break from "../data/docx/page_break.mjs";
import * as $paragraph from "../data/docx/paragraph.mjs";
import * as $table from "../data/docx/table.mjs";
import { toList, CustomType as $CustomType } from "../gleam.mjs";

export class Docx extends $CustomType {
  constructor(styles, elements) {
    super();
    this.styles = styles;
    this.elements = elements;
  }
}

export class PageBreak extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class Paragraph extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class Table extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export function encode_styles(styles) {
  return $json.object(
    $list.map(
      styles,
      (_use0) => {
        let style = _use0[0];
        let value = _use0[1];
        return [style, $json.string(value)];
      },
    ),
  );
}

export function element_to_html(element) {
  if (element instanceof PageBreak) {
    let page_break = element[0];
    return $page_break.to_html(page_break);
  } else if (element instanceof Paragraph) {
    let paragraph = element[0];
    return $paragraph.to_html(paragraph);
  } else {
    let table = element[0];
    return $table.to_html(table);
  }
}

export function to_html(docx) {
  return $list.map(
    docx.elements,
    (element) => { return element_to_html(element); },
  );
}

export function to_labelling(docx) {
  return $list.map(
    docx.elements,
    (element) => {
      if (element instanceof PageBreak) {
        let page_break = element[0];
        return $page_break.to_html(page_break);
      } else if (element instanceof Paragraph) {
        let paragraph = element[0];
        return $paragraph.to_labelling(paragraph);
      } else {
        let table = element[0];
        return $table.to_labelling(table);
      }
    },
  );
}

export function element_decoder() {
  return $decode.one_of(
    (() => {
      let _pipe = $table.decoder();
      return $decode.map(_pipe, (var0) => { return new Table(var0); });
    })(),
    toList([
      (() => {
        let _pipe = $page_break.decoder();
        return $decode.map(_pipe, (var0) => { return new PageBreak(var0); });
      })(),
      (() => {
        let _pipe = $paragraph.decoder();
        return $decode.map(_pipe, (var0) => { return new Paragraph(var0); });
      })(),
    ]),
  );
}

export function decoder() {
  return $decode.field(
    "style_html_tag_map",
    $decode.dict(
      $decode.string,
      (() => {
        let _pipe = $decode.dynamic;
        return $decode.map(_pipe, $utils.to_json);
      })(),
    ),
    (styles) => {
      return $decode.field(
        "elements",
        $decode.list(element_decoder()),
        (elements) => {
          let styles$1 = $dict.to_list(styles);
          return $decode.success(new Docx(styles$1, elements));
        },
      );
    },
  );
}

export function encode_element(element) {
  if (element instanceof PageBreak) {
    let pb = element[0];
    return $page_break.encode(pb);
  } else if (element instanceof Paragraph) {
    let p = element[0];
    return $paragraph.encode(p);
  } else {
    let t = element[0];
    return $table.encode(t);
  }
}

export function encode(docx) {
  return $json.object(
    toList([
      ["type", $json.string("docx")],
      ["elements", $json.array(docx.elements, encode_element)],
      ["styles", encode_styles(docx.styles)],
    ]),
  );
}

export function index(element) {
  if (element instanceof PageBreak) {
    let p = element[0];
    return p.index;
  } else if (element instanceof Paragraph) {
    let p = element[0];
    return p.index;
  } else {
    let t = element[0];
    return t.index;
  }
}

export function update_kind(element, kind) {
  if (element instanceof PageBreak) {
    let p = element[0];
    let metadata = new $page_break.Metadata(new Some(kind));
    return new PageBreak(p.withFields({ metadata: metadata }));
  } else if (element instanceof Paragraph) {
    let p = element[0];
    let metadata = p.metadata.withFields({ kind: new Some(kind) });
    return new Paragraph(p.withFields({ metadata: metadata }));
  } else {
    let t = element[0];
    let metadata = new $table.Metadata(new Some(kind));
    return new Table(t.withFields({ metadata: metadata }));
  }
}

export function kind(element) {
  let _pipe = (() => {
    if (element instanceof PageBreak) {
      let p = element[0];
      return p.metadata.kind;
    } else if (element instanceof Paragraph) {
      let p = element[0];
      return p.metadata.kind;
    } else {
      let t = element[0];
      return t.metadata.kind;
    }
  })();
  return $option.unwrap(_pipe, new $kind.Other());
}
