/// <reference types="./text.d.mts" />
import * as $json from "../../../gleam_json/gleam/json.mjs";
import * as $bool from "../../../gleam_stdlib/gleam/bool.mjs";
import * as $dict from "../../../gleam_stdlib/gleam/dict.mjs";
import * as $decode from "../../../gleam_stdlib/gleam/dynamic/decode.mjs";
import * as $list from "../../../gleam_stdlib/gleam/list.mjs";
import * as $pair from "../../../gleam_stdlib/gleam/pair.mjs";
import * as $a from "../../../lustre/lustre/attribute.mjs";
import * as $h from "../../../lustre/lustre/element/html.mjs";
import { toList, CustomType as $CustomType } from "../../gleam.mjs";

export class Text extends $CustomType {
  constructor(text, style) {
    super();
    this.text = text;
    this.style = style;
  }
}

export function decoder() {
  return $decode.optional_field(
    "text",
    "",
    $decode.string,
    (text) => {
      return $decode.optional_field(
        "style",
        toList([]),
        (() => {
          let _pipe = $decode.dict($decode.string, $decode.string);
          return $decode.map(_pipe, $dict.to_list);
        })(),
        (style) => { return $decode.success(new Text(text, style)); },
      );
    },
  );
}

export function to_html(text) {
  return $bool.guard(
    $list.is_empty(text.style),
    $h.text(text.text),
    () => {
      return $h.span(
        toList([$a.style(text.style)]),
        toList([$h.text(text.text)]),
      );
    },
  );
}

export function encode(text) {
  return $json.object(
    toList([
      ["text", $json.string(text.text)],
      [
        "style",
        (() => {
          let _pipe = text.style;
          let _pipe$1 = $list.map(
            _pipe,
            (_capture) => { return $pair.map_second(_capture, $json.string); },
          );
          return $json.object(_pipe$1);
        })(),
      ],
    ]),
  );
}
