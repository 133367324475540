/// <reference types="./proposal.d.mts" />
import * as $app_config from "../../../app_config/app_config.mjs";
import * as $proposal from "../../../common/data/proposal.mjs";
import { Proposal } from "../../../common/data/proposal.mjs";
import * as $translate from "../../../common/data/translate.mjs";
import * as $sentry from "../../../common/sentry.mjs";
import * as $fetch from "../../../gleam_fetch/gleam/fetch.mjs";
import * as $http from "../../../gleam_http/gleam/http.mjs";
import * as $request from "../../../gleam_http/gleam/http/request.mjs";
import * as $promise from "../../../gleam_javascript/gleam/javascript/promise.mjs";
import * as $json from "../../../gleam_json/gleam/json.mjs";
import * as $dynamic from "../../../gleam_stdlib/gleam/dynamic.mjs";
import * as $list from "../../../gleam_stdlib/gleam/list.mjs";
import * as $result from "../../../gleam_stdlib/gleam/result.mjs";
import * as $string from "../../../gleam_stdlib/gleam/string.mjs";
import * as $level from "../../../grille_pain/grille_pain/toast/level.mjs";
import * as $effect from "../../../lustre/lustre/effect.mjs";
import * as $global from "../../../plinth/plinth/javascript/global.mjs";
import * as $data from "../../data/model/data.mjs";
import { Data } from "../../data/model/data.mjs";
import * as $msg from "../../data/msg.mjs";
import * as $ask from "../../frontend/ask.mjs";
import * as $error from "../../frontend/error.mjs";
import * as $ffi from "../../frontend/ffi.mjs";
import * as $middleware from "../../frontend/middleware.mjs";
import { Ok, toList, makeError } from "../../gleam.mjs";

export function download_xlsx(data, proposal_id, toast_id, project_name) {
  return $middleware.require_access_token(
    data,
    (access_token) => {
      return $effect.from(
        (dispatch) => {
          let url = $app_config.heimdall_endpoint();
          let language = $translate.decode_language_iso639_1(data.language);
          let url$1 = (() => {
            let _pipe = toList([
              url,
              "proposals",
              proposal_id,
              "export",
              language,
            ]);
            return $string.join(_pipe, "/");
          })();
          let $ = $request.to(url$1);
          if (!$.isOk()) {
            throw makeError(
              "let_assert",
              "frontend/effects/proposal",
              80,
              "",
              "Pattern match failed, no pattern matched the value.",
              { value: $ }
            )
          }
          let request = $[0];
          let file_name = (() => {
            if (project_name === "") {
              return "Untitled";
            } else {
              let name = project_name;
              return name;
            }
          })();
          let authorization = "Bearer " + access_token;
          let _pipe = request;
          let _pipe$1 = $request.set_header(
            _pipe,
            "authorization",
            authorization,
          );
          let _pipe$2 = $request.set_method(_pipe$1, new $http.Get());
          let _pipe$3 = $fetch.send(_pipe$2);
          let _pipe$4 = $promise.try_await(_pipe$3, $fetch.read_bytes_body);
          let _pipe$5 = $promise.map(
            _pipe$4,
            (_capture) => {
              return $result.map_error(
                _capture,
                (var0) => { return new $error.FetchError(var0); },
              );
            },
          );
          let _pipe$6 = $promise.tap(
            _pipe$5,
            (dyn) => {
              if (dyn.isOk()) {
                let response = dyn[0];
                return $ffi.dl_file(file_name, response.body);
              } else {
                return $promise.resolve(undefined);
              }
            },
          );
          let _pipe$7 = $promise.map(
            _pipe$6,
            (_) => {
              dispatch(new $msg.HideToast(toast_id));
              let msg = "File downloaded successfully!";
              $global.set_timeout(
                1000,
                () => {
                  return dispatch(
                    new $msg.DisplayToast(new $level.Success(), msg),
                  );
                },
              );
              return new Ok(undefined);
            },
          );
          $promise.rescue(
            _pipe$7,
            (dyn) => {
              let $1 = $sentry.capture(dyn);
              
              dispatch(new $msg.HideToast(toast_id));
              let msg = "Impossible to download the file. Please, retry later.";
              $global.set_timeout(
                1000,
                () => {
                  return dispatch(
                    new $msg.DisplayToast(new $level.Error(), msg),
                  );
                },
              );
              return new Ok(undefined);
            },
          )
          return undefined;
        },
      );
    },
  );
}

export function fetch_proposals(data) {
  return $middleware.require_access_token(
    data,
    (access_token) => {
      return $effect.from(
        (dispatch) => {
          let _pipe = $ask.to(new $ask.Heimdall(), toList(["proposals"]));
          let _pipe$1 = $ask.bearing(_pipe, access_token);
          let _pipe$2 = $ask.expect(_pipe$1, $dynamic.list($proposal.decode));
          let _pipe$3 = $ask.notify(
            _pipe$2,
            (p) => { return dispatch(new $msg.ApiReturnedProposals(p)); },
          );
          let _pipe$4 = $ask.run(_pipe$3);
          $promise.try_await(
            _pipe$4,
            (proposals) => {
              let _pipe$5 = $list.map(
                proposals,
                (p) => {
                  let _pipe$5 = $ask.to(
                    new $ask.Heimdall(),
                    toList(["translations", "proposal", p.id]),
                  );
                  let _pipe$6 = $ask.bearing(_pipe$5, access_token);
                  let _pipe$7 = $ask.expect(
                    _pipe$6,
                    $dynamic.list($translate.decode_translation),
                  );
                  let _pipe$8 = $ask.notify(
                    _pipe$7,
                    (q) => {
                      return dispatch(new $msg.ApiReturnedTranslations(q));
                    },
                  );
                  return $ask.run(_pipe$8);
                },
              );
              let _pipe$6 = $promise.await_list(_pipe$5);
              return $promise.map(_pipe$6, (var0) => { return new Ok(var0); });
            },
          )
          return undefined;
        },
      );
    },
  );
}

function require_proposal(data, proposal_id, next) {
  let $ = $list.key_find(data.proposals, proposal_id);
  if (!$.isOk()) {
    return $effect.none();
  } else {
    let proposal = $[0];
    return next(proposal);
  }
}

export function delete_proposal(data, proposal_id) {
  return $middleware.require_access_token(
    data,
    (access_token) => {
      return require_proposal(
        data,
        proposal_id,
        (_use0) => {
          let id = _use0.id;
          let pid = _use0.project_id;
          return $effect.from(
            (dispatch) => {
              let at = toList(["proposals", proposal_id]);
              let _pipe = $ask.to(new $ask.Heimdall(), at);
              let _pipe$1 = $ask.via(_pipe, new $http.Delete());
              let _pipe$2 = $ask.bearing(_pipe$1, access_token);
              let _pipe$3 = $ask.run(_pipe$2);
              $promise.await$(
                _pipe$3,
                (_) => {
                  let no_projects_proposals = (() => {
                    let _pipe$4 = data.proposals;
                    let _pipe$5 = $list.filter(
                      _pipe$4,
                      (p) => {
                        return (p[1].project_id === pid) && (p[1].id !== id);
                      },
                    );
                    return $list.is_empty(_pipe$5);
                  })();
                  if (!no_projects_proposals) {
                    return $promise.resolve(new Ok($dynamic.from(undefined)));
                  } else {
                    let _pipe$4 = $ask.to(
                      new $ask.Heimdall(),
                      toList(["projects", pid]),
                    );
                    let _pipe$5 = $ask.via(_pipe$4, new $http.Delete());
                    let _pipe$6 = $ask.bearing(_pipe$5, access_token);
                    let _pipe$7 = $ask.notify(
                      _pipe$6,
                      (_) => {
                        return dispatch(new $msg.ApiDeletedProject(pid));
                      },
                    );
                    return $ask.run(_pipe$7);
                  }
                },
              )
              return undefined;
            },
          );
        },
      );
    },
  );
}

export function delete_element_in_proposal(data, proposal_id, element_id) {
  return $middleware.require_access_token(
    data,
    (access_token) => {
      return require_proposal(
        data,
        proposal_id,
        (_) => {
          return $effect.from(
            (_) => {
              let at = toList(["proposals", proposal_id, "elements", element_id]);
              let _pipe = $ask.to(new $ask.Heimdall(), at);
              let _pipe$1 = $ask.via(_pipe, new $http.Delete());
              let _pipe$2 = $ask.bearing(_pipe$1, access_token);
              $ask.run(_pipe$2)
              return undefined;
            },
          );
        },
      );
    },
  );
}

export function update_proposal_name(data, id) {
  return $middleware.require_access_token(
    data,
    (access_token) => {
      return require_proposal(
        data,
        id,
        (proposal) => {
          return $effect.from(
            (dispatch) => {
              let at = toList(["proposals", id]);
              let _pipe = $ask.to(new $ask.Heimdall(), at);
              let _pipe$1 = $ask.bearing(_pipe, access_token);
              let _pipe$2 = $ask.via(_pipe$1, new $http.Patch());
              let _pipe$3 = $ask.with$(
                _pipe$2,
                $json.object(toList([["proposal", $proposal.encode(proposal)]])),
              );
              let _pipe$4 = $ask.expect(
                _pipe$3,
                $dynamic.field("proposal", $proposal.decode),
              );
              let _pipe$5 = $ask.notify(
                _pipe$4,
                (q) => {
                  return dispatch(new $msg.ApiReturnedProposals(toList([q])));
                },
              );
              $ask.run(_pipe$5)
              return undefined;
            },
          );
        },
      );
    },
  );
}
