/// <reference types="./project.d.mts" />
import * as $birl from "../../birl/birl.mjs";
import * as $decode from "../../decode/decode.mjs";
import * as $json from "../../gleam_json/gleam/json.mjs";
import * as $bool from "../../gleam_stdlib/gleam/bool.mjs";
import * as $dynamic from "../../gleam_stdlib/gleam/dynamic.mjs";
import * as $list from "../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../gleam_stdlib/gleam/option.mjs";
import * as $result from "../../gleam_stdlib/gleam/result.mjs";
import * as $proposal from "../data/proposal.mjs";
import * as $proposal_element from "../data/proposal_element.mjs";
import * as $status from "../data/status.mjs";
import * as $translate from "../data/translate.mjs";
import * as $decode_ from "../decode/extra.mjs";
import { toList, prepend as listPrepend, CustomType as $CustomType } from "../gleam.mjs";

export class Metadata extends $CustomType {
  constructor(languages, default_language) {
    super();
    this.languages = languages;
    this.default_language = default_language;
  }
}

export class Project extends $CustomType {
  constructor(id, name, owner, org_id, deadline, client, collaborators, metadata, created_at, status) {
    super();
    this.id = id;
    this.name = name;
    this.owner = owner;
    this.org_id = org_id;
    this.deadline = deadline;
    this.client = client;
    this.collaborators = collaborators;
    this.metadata = metadata;
    this.created_at = created_at;
    this.status = status;
  }
}

export function toggle_collaborator(project, user_id) {
  let all_collaborators$1 = listPrepend(project.owner, project.collaborators);
  let is_collaborator = $list.contains(all_collaborators$1, user_id);
  return project.withFields({
    collaborators: (() => {
      if (is_collaborator) {
        return $list.filter(
          project.collaborators,
          (u) => { return u !== user_id; },
        );
      } else {
        return listPrepend(user_id, project.collaborators);
      }
    })()
  });
}

export function from_proposal(proposal) {
  return new Project(
    "",
    proposal.name,
    proposal.owner,
    proposal.org_id,
    proposal.deadline,
    proposal.client,
    proposal.collaborators,
    new Metadata(
      proposal.metadata.languages,
      proposal.metadata.default_language,
    ),
    proposal.created_at,
    proposal.status,
  );
}

export function get_languages(p, q) {
  return $bool.guard(
    $list.is_empty(p.metadata.languages),
    toList([]),
    () => {
      if (q instanceof $proposal_element.Block) {
        return p.metadata.languages;
      } else {
        let q$1 = q.question;
        let $ = q$1.metadata.language;
        if ($ instanceof $option.None) {
          return p.metadata.languages;
        } else {
          let l = $[0];
          let _pipe = p.metadata.languages;
          let _pipe$1 = $list.append(_pipe, toList([l]));
          return $list.unique(_pipe$1);
        }
      }
    },
  );
}

function decode_languages(dyn) {
  let _pipe = $dynamic.optional(
    $dynamic.list($translate.decode_language_iso639_1_dyn),
  )(dyn);
  return $result.map(
    _pipe,
    (_capture) => { return $option.unwrap(_capture, toList([])); },
  );
}

export function encode_metadata(metadata) {
  return $json.object(
    toList([
      [
        "languages",
        $json.array(
          metadata.languages,
          (l) => {
            let _pipe = $translate.decode_language_iso639_1(l);
            return $json.string(_pipe);
          },
        ),
      ],
      [
        "default_language",
        $json.nullable(
          metadata.default_language,
          (l) => {
            let _pipe = $translate.decode_language_iso639_1(l);
            return $json.string(_pipe);
          },
        ),
      ],
    ]),
  );
}

export function encode(project) {
  return $json.object(
    toList([
      ["id", $json.string(project.id)],
      ["name", $json.string(project.name)],
      ["owner", $json.string(project.owner)],
      ["org_id", $json.string(project.org_id)],
      [
        "deadline",
        $json.string(
          (() => {
            let _pipe = project.deadline;
            return $birl.to_iso8601(_pipe);
          })(),
        ),
      ],
      ["client", $json.string(project.client)],
      ["collaborators", $json.array(project.collaborators, $json.string)],
      ["metadata", encode_metadata(project.metadata)],
      [
        "created_at",
        $json.string(
          (() => {
            let _pipe = project.created_at;
            return $birl.to_iso8601(_pipe);
          })(),
        ),
      ],
      ["status", $status.encode(project.status)],
    ]),
  );
}

export function all_collaborators(project) {
  return listPrepend(project.owner, project.collaborators);
}

export function decode(dyn) {
  let _pipe = $decode.into(
    $decode.parameter(
      (id) => {
        return $decode.parameter(
          (name) => {
            return $decode.parameter(
              (owner) => {
                return $decode.parameter(
                  (org_id) => {
                    return $decode.parameter(
                      (deadline) => {
                        return $decode.parameter(
                          (client) => {
                            return $decode.parameter(
                              (collaborators) => {
                                return $decode.parameter(
                                  (metadata) => {
                                    return $decode.parameter(
                                      (created_at) => {
                                        return $decode.parameter(
                                          (status) => {
                                            return new Project(
                                              id,
                                              name,
                                              owner,
                                              org_id,
                                              deadline,
                                              client,
                                              collaborators,
                                              metadata,
                                              created_at,
                                              status,
                                            );
                                          },
                                        );
                                      },
                                    );
                                  },
                                );
                              },
                            );
                          },
                        );
                      },
                    );
                  },
                );
              },
            );
          },
        );
      },
    ),
  );
  let _pipe$1 = $decode.field(_pipe, "id", $decode.string);
  let _pipe$2 = $decode.field(_pipe$1, "name", $decode.string);
  let _pipe$3 = $decode.field(_pipe$2, "owner", $decode.string);
  let _pipe$4 = $decode.field(_pipe$3, "org_id", $decode.string);
  let _pipe$5 = $decode.field(_pipe$4, "deadline", $decode_.time());
  let _pipe$6 = $decode.field(_pipe$5, "client", $decode.string);
  let _pipe$7 = $decode.field(
    _pipe$6,
    "collaborators",
    $decode.list($decode.string),
  );
  let _pipe$8 = $decode.field(
    _pipe$7,
    "metadata",
    $decode_.json(decode_metadata),
  );
  let _pipe$9 = $decode.field(_pipe$8, "created_at", $decode_.time());
  let _pipe$10 = $decode.field(
    _pipe$9,
    "status",
    $decode_.dynamic($status.decode),
  );
  return $decode.from(_pipe$10, dyn);
}

function decode_metadata(dyn) {
  let _pipe = $decode.into(
    $decode.parameter(
      (languages) => {
        return $decode.parameter(
          (default_language) => {
            return new Metadata(languages, default_language);
          },
        );
      },
    ),
  );
  let _pipe$1 = $decode.field(
    _pipe,
    "languages",
    $decode_.dynamic(decode_languages),
  );
  let _pipe$2 = $decode.field(
    _pipe$1,
    "default_language",
    $decode.optional($decode_.dynamic($translate.decode_language_iso639_1_dyn)),
  );
  return $decode.from(_pipe$2, dyn);
}
