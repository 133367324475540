import * as gleam from './gleam.mjs'

export async function toBitArray(file) {
  try {
    const content = await file.arrayBuffer()
    const bitArray = gleam.toBitArray(new Uint8Array(content))
    return new gleam.Ok(bitArray)
  } catch (error) {
    return new gleam.Error()
  }
}

export function getStacktrace() {
  const error = new Error()
  return error.stack
}

export function logStacktrace(stack) {
  console.log(stack)
}

export function toJSON(data) {
  try {
    return JSON.stringify(data)
  } catch (error) {
    return ''
  }
}
