/// <reference types="./data.d.mts" />
import * as $auth0 from "../../../auth0_client/auth0/client.mjs";
import * as $birl from "../../../birl/birl.mjs";
import * as $connector from "../../../common/data/connector.mjs";
import * as $confluence from "../../../common/data/connector/confluence.mjs";
import * as $notion from "../../../common/data/connector/notion.mjs";
import * as $connector_settings from "../../../common/data/connector_settings.mjs";
import * as $copilot_question from "../../../common/data/copilot_question.mjs";
import * as $data_point from "../../../common/data/data_point.mjs";
import * as $data_source from "../../../common/data/data_source.mjs";
import * as $proposal_builder from "../../../common/data/ephemeral/proposal_builder.mjs";
import * as $notification from "../../../common/data/notification.mjs";
import * as $project from "../../../common/data/project.mjs";
import * as $proposal from "../../../common/data/proposal.mjs";
import * as $proposal_element from "../../../common/data/proposal_element.mjs";
import * as $question from "../../../common/data/proposal_element/question.mjs";
import * as $qualification_matrix from "../../../common/data/qualification_matrix.mjs";
import * as $tag from "../../../common/data/tag.mjs";
import * as $translate from "../../../common/data/translate.mjs";
import * as $user from "../../../common/data/user.mjs";
import * as $bool from "../../../gleam_stdlib/gleam/bool.mjs";
import * as $dict from "../../../gleam_stdlib/gleam/dict.mjs";
import * as $dynamic from "../../../gleam_stdlib/gleam/dynamic.mjs";
import * as $float from "../../../gleam_stdlib/gleam/float.mjs";
import * as $int from "../../../gleam_stdlib/gleam/int.mjs";
import * as $list from "../../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../../gleam_stdlib/gleam/option.mjs";
import { Some } from "../../../gleam_stdlib/gleam/option.mjs";
import * as $result from "../../../gleam_stdlib/gleam/result.mjs";
import * as $string from "../../../gleam_stdlib/gleam/string.mjs";
import * as $content_library from "../../data/content_library.mjs";
import * as $route from "../../data/route.mjs";
import * as $copilot_input from "../../data/ui/copilot_input.mjs";
import * as $loading from "../../data/ui/loading.mjs";
import * as $feature_flags from "../../feature_flags.mjs";
import { readPermissions as read_permissions } from "../../frontend.ffi.mjs";
import {
  Ok,
  Error,
  toList,
  prepend as listPrepend,
  CustomType as $CustomType,
  makeError,
  divideFloat,
  isEqual,
} from "../../gleam.mjs";
import * as $utils from "../../utils.mjs";

export class Data extends $CustomType {
  constructor(access_token, client, collaborators_proposal_opened, connectors, content_library, copilot_input, proposal_filters, copilot_threads, custom_rewording_input, display_modal, feed_opened, loading, more_proposal_opened, more_proposal_unsubscriber, proposal_builder, filter_proposal_opened, notifications, notifications_unread, permissions, projects, proposals, proposal_block_page_size, elements, route, language, running_requests, tags, translations, user, users, qualification_matrix, collapsed_navbar, opened_projects) {
    super();
    this.access_token = access_token;
    this.client = client;
    this.collaborators_proposal_opened = collaborators_proposal_opened;
    this.connectors = connectors;
    this.content_library = content_library;
    this.copilot_input = copilot_input;
    this.proposal_filters = proposal_filters;
    this.copilot_threads = copilot_threads;
    this.custom_rewording_input = custom_rewording_input;
    this.display_modal = display_modal;
    this.feed_opened = feed_opened;
    this.loading = loading;
    this.more_proposal_opened = more_proposal_opened;
    this.more_proposal_unsubscriber = more_proposal_unsubscriber;
    this.proposal_builder = proposal_builder;
    this.filter_proposal_opened = filter_proposal_opened;
    this.notifications = notifications;
    this.notifications_unread = notifications_unread;
    this.permissions = permissions;
    this.projects = projects;
    this.proposals = proposals;
    this.proposal_block_page_size = proposal_block_page_size;
    this.elements = elements;
    this.route = route;
    this.language = language;
    this.running_requests = running_requests;
    this.tags = tags;
    this.translations = translations;
    this.user = user;
    this.users = users;
    this.qualification_matrix = qualification_matrix;
    this.collapsed_navbar = collapsed_navbar;
    this.opened_projects = opened_projects;
  }
}

export class Connectors extends $CustomType {
  constructor(fetched, confluence, confluence_opened, notion, notion_opened, google_folder_id, settings) {
    super();
    this.fetched = fetched;
    this.confluence = confluence;
    this.confluence_opened = confluence_opened;
    this.notion = notion;
    this.notion_opened = notion_opened;
    this.google_folder_id = google_folder_id;
    this.settings = settings;
  }
}

export class DeleteProposal extends $CustomType {
  constructor(id) {
    super();
    this.id = id;
  }
}

export class DeleteDataSource extends $CustomType {
  constructor(id) {
    super();
    this.id = id;
  }
}

export class DeleteQuestionInProposal extends $CustomType {
  constructor(proposal_id, question_id) {
    super();
    this.proposal_id = proposal_id;
    this.question_id = question_id;
  }
}

export class IncompleteQualificationMatrix extends $CustomType {}

export class DangerousQualificationMatrix extends $CustomType {}

export class NoModal extends $CustomType {}

export class Elements extends $CustomType {
  constructor(by_proposal, data_sources, data_points) {
    super();
    this.by_proposal = by_proposal;
    this.data_sources = data_sources;
    this.data_points = data_points;
  }
}

export function new$(client, route, collapsed_navbar, project_id) {
  return new Data(
    new $option.None(),
    client,
    new $option.None(),
    new Connectors(
      toList([]),
      $confluence.new$(),
      false,
      $notion.new$(),
      false,
      "",
      toList([]),
    ),
    $content_library.new$(),
    $copilot_input.new$(),
    new $proposal.ProposalFilters(new $proposal.AllStatus(), new $option.None()),
    toList([]),
    "",
    new NoModal(),
    false,
    $loading.new$(),
    new $option.None(),
    new $option.None(),
    $proposal_builder.init(project_id),
    false,
    toList([]),
    false,
    toList([]),
    toList([]),
    toList([]),
    40,
    new Elements($dict.new$(), $dict.new$(), $dict.new$()),
    route,
    $utils.get_navigator_language(),
    toList([]),
    toList([]),
    $dict.new$(),
    new $option.None(),
    toList([]),
    toList([]),
    collapsed_navbar,
    $dict.new$(),
  );
}

export function set_modal(data, display_modal) {
  return data.withFields({ display_modal: display_modal });
}

export function update_route(data, route) {
  return data.withFields({ route: route });
}

export function update_copilot_input(data, value) {
  let copilot_input = data.copilot_input.withFields({ value: value });
  return data.withFields({ copilot_input: copilot_input });
}

export function update_custom_rewording_input(data, value) {
  let custom_rewording_input = value;
  return data.withFields({ custom_rewording_input: custom_rewording_input });
}

export function mark_as_loaded(data, mapper) {
  return data.withFields({ loading: mapper(data.loading) });
}

export function set_proposal_filters(model, proposal_filters) {
  return model.withFields({ proposal_filters: proposal_filters });
}

export function toggle_filter_proposal_opened(model) {
  return model.withFields({
    filter_proposal_opened: !model.filter_proposal_opened
  });
}

export function close_filter_proposal(model) {
  return model.withFields({ filter_proposal_opened: false });
}

export function update_access_token(data, access_token) {
  let _pipe = read_permissions(access_token);
  let _pipe$1 = $result.replace_error(_pipe, toList([]));
  let _pipe$2 = $result.then$(_pipe$1, $dynamic.list($dynamic.string));
  let _pipe$3 = $result.unwrap(_pipe$2, toList([]));
  return ((permissions) => {
    return data.withFields({
      access_token: new Some(access_token),
      permissions: permissions
    });
  })(_pipe$3);
}

export function update_connected_user(data, user) {
  return data.withFields({ user: new Some(user) });
}

function clear_popup(model) {
  return model.withFields({
    feed_opened: false,
    collaborators_proposal_opened: new $option.None(),
    more_proposal_opened: new $option.None(),
    content_library: model.content_library.withFields({ selected: $dict.new$() }),
    filter_proposal_opened: model.filter_proposal_opened && $result.is_ok(
      $route.current_proposal_id(model.route),
    )
  });
}

function remove_same_id(val, id) {
  if (val instanceof Some && (isEqual(val[0], id))) {
    let old_id = val[0];
    return new $option.None();
  } else {
    let value = val;
    return value;
  }
}

export function empty_popup(model, id) {
  let is_feed = id === "feed";
  return $bool.guard(
    is_feed,
    model.withFields({ feed_opened: false }),
    () => {
      return $bool.lazy_guard(
        id === "all",
        () => { return clear_popup(model); },
        () => {
          if (id.startsWith("collaborator-")) {
            let id$1 = id.slice(13);
            let collaborators_proposal_opened = remove_same_id(
              model.collaborators_proposal_opened,
              id$1,
            );
            return model.withFields({
              collaborators_proposal_opened: collaborators_proposal_opened
            });
          } else if (id.startsWith("ai-")) {
            let id$1 = id.slice(3);
            let more_proposal_opened = remove_same_id(
              model.more_proposal_opened,
              id$1,
            );
            return model.withFields({
              more_proposal_opened: more_proposal_opened
            });
          } else if (id === "proposal-filter") {
            return model.withFields({ filter_proposal_opened: false });
          } else {
            return model;
          }
        },
      );
    },
  );
}

export function is_connected(model) {
  return $option.is_some(model.access_token);
}

export function add_proposal_questions(model, elements) {
  let $ = $list.first(elements);
  if (!$.isOk()) {
    return model;
  } else {
    let element = $[0];
    let proposal_id = $proposal_element.proposal_id(element);
    let elements$1 = $list.map(
      elements,
      (q) => { return [$proposal_element.id(q), q]; },
    );
    let _pipe = model.elements.by_proposal;
    let _pipe$1 = $dict.insert(_pipe, proposal_id, elements$1);
    return ((by_proposal) => {
      return model.withFields({
        elements: model.elements.withFields({ by_proposal: by_proposal })
      });
    })(_pipe$1);
  }
}

export function upsert_translations(data, t) {
  let translations = (() => {
    let _pipe = t;
    let _pipe$1 = $list.map(_pipe, (t) => { return [t.id, t]; });
    let _pipe$2 = $dict.from_list(_pipe$1);
    return $dict.combine(
      _pipe$2,
      data.translations,
      (new$, _) => { return new$; },
    );
  })();
  return data.withFields({ translations: translations });
}

export function delete_translations(model, s) {
  let translations = (() => {
    let _pipe = model.translations;
    return $dict.filter(
      _pipe,
      (k, _) => {
        let $ = $string.split(k, "_");
        if (!$.hasLength(3)) {
          throw makeError(
            "let_assert",
            "data/model/data",
            291,
            "",
            "Pattern match failed, no pattern matched the value.",
            { value: $ }
          )
        }
        let id = $.tail.tail.head;
        return id !== s;
      },
    );
  })();
  return model.withFields({ translations: translations });
}

export function reset_copilot_input(model) {
  return model.withFields({ copilot_input: $copilot_input.new$() });
}

export function reset_custom_rewording_input(model) {
  return model.withFields({ custom_rewording_input: "" });
}

export function add_questions_data_sources(model, data_sources) {
  let _pipe = model.elements.data_sources;
  let _pipe$1 = $dict.merge(_pipe, data_sources);
  return ((data_sources) => {
    return model.withFields({
      elements: model.elements.withFields({ data_sources: data_sources })
    });
  })(_pipe$1);
}

export function add_questions_data_points(model, data_points) {
  let _pipe = model.elements.data_points;
  let _pipe$1 = $dict.merge(_pipe, data_points);
  return ((data_points) => {
    return model.withFields({
      elements: model.elements.withFields({ data_points: data_points })
    });
  })(_pipe$1);
}

export function empty_proposal_builder(model) {
  let $ = model.route;
  if ($ instanceof $route.Projects &&
  $[0] instanceof $route.AddProposal &&
  !$[0].loading) {
    let project_id = $[0].project_id;
    return model.withFields({
      proposal_builder: $proposal_builder.init(project_id)
    });
  } else {
    return model;
  }
}

export function empty_opened_projects(data) {
  return data.withFields({ opened_projects: $dict.new$() });
}

export function set_proposal_builder(model, proposal_builder) {
  return model.withFields({ proposal_builder: proposal_builder });
}

export function set_content_library(model, content_library) {
  return model.withFields({ content_library: content_library });
}

export function set_connectors(model, connectors) {
  return model.withFields({ connectors: connectors });
}

function count_validated_questions(questions) {
  return $list.fold(
    questions,
    [0, 0],
    (_use0, _use1) => {
      let validated = _use0[0];
      let total = _use0[1];
      let question = _use1[1];
      if (question instanceof $proposal_element.Block) {
        return [validated, total];
      } else {
        let question$1 = question.question;
        let $ = question$1.validated;
        if ($) {
          return [validated + 1, total + 1];
        } else {
          return [validated, total + 1];
        }
      }
    },
  );
}

function compute_proposal_progress(elements) {
  let $ = count_validated_questions(elements);
  let validated = $[0];
  let total = $[1];
  let _pipe = (divideFloat(
    $int.to_float(validated),
    $int.to_float($int.max(total, 1))
  ));
  let _pipe$1 = $float.multiply(_pipe, 100.0);
  return $float.round(_pipe$1);
}

export function set_questions(model, proposal_id, elements) {
  let progress = compute_proposal_progress(elements);
  let by_proposal = $dict.insert(
    model.elements.by_proposal,
    proposal_id,
    elements,
  );
  let elements$1 = model.elements.withFields({ by_proposal: by_proposal });
  let proposals = (() => {
    let _pipe = model.proposals;
    let _pipe$1 = $list.key_find(_pipe, proposal_id);
    let _pipe$2 = $result.map(
      _pipe$1,
      (p) => { return p.withFields({ progress: progress }); },
    );
    let _pipe$3 = $result.map(
      _pipe$2,
      (_capture) => {
        return $list.key_set(model.proposals, proposal_id, _capture);
      },
    );
    return $result.unwrap(_pipe$3, model.proposals);
  })();
  return model.withFields({ elements: elements$1, proposals: proposals });
}

export function upsert_proposals(model, proposals) {
  let _pipe = $list.fold(
    proposals,
    model.proposals,
    (proposals, proposal) => {
      return $list.key_set(proposals, proposal.id, proposal);
    },
  );
  let _pipe$1 = ((proposals) => {
    return model.withFields({ proposals: proposals });
  })(_pipe);
  return mark_as_loaded(_pipe$1, $loading.proposals);
}

export function upsert_projects(model, projects) {
  let _pipe = $list.fold(
    projects,
    model.projects,
    (projects, project) => {
      return $list.key_set(projects, project.id, project);
    },
  );
  let _pipe$1 = $list.sort(
    _pipe,
    (a, b) => { return $birl.compare(b[1].created_at, a[1].created_at); },
  );
  let _pipe$2 = ((projects) => {
    return model.withFields({ projects: projects });
  })(_pipe$1);
  return mark_as_loaded(_pipe$2, $loading.projects);
}

export function delete_project(model, project_id) {
  let _pipe = model.projects;
  let _pipe$1 = $list.filter(
    _pipe,
    (project) => { return project[0] !== project_id; },
  );
  let _pipe$2 = ((projects) => {
    return model.withFields({ projects: projects });
  })(_pipe$1);
  return mark_as_loaded(_pipe$2, $loading.projects);
}

export function get_proposal_questions(model, proposal_id) {
  let _pipe = model.elements.by_proposal;
  let _pipe$1 = $dict.get(_pipe, proposal_id);
  let _pipe$2 = $result.map(
    _pipe$1,
    (_capture) => {
      return $list.filter_map(
        _capture,
        (element) => {
          let $ = element[1];
          if ($ instanceof $proposal_element.Block) {
            return new Error(undefined);
          } else {
            let q = $.question;
            return new Ok([element[0], q]);
          }
        },
      );
    },
  );
  return $result.unwrap(_pipe$2, toList([]));
}

export function get_proposal_elements(model, proposal_id) {
  let _pipe = model.elements.by_proposal;
  let _pipe$1 = $dict.get(_pipe, proposal_id);
  return $result.unwrap(_pipe$1, toList([]));
}

export function upsert_proposal_element(model, element) {
  let id = $proposal_element.id(element);
  let proposal_id = $proposal_element.proposal_id(element);
  let _pipe = get_proposal_elements(model, proposal_id);
  let _pipe$1 = $list.key_set(_pipe, id, element);
  return ((_capture) => { return set_questions(model, proposal_id, _capture); })(
    _pipe$1,
  );
}

export function set_notifications(model, notifications) {
  let notifications_unread = $list.any(
    notifications,
    (n) => { return !n.read; },
  );
  return model.withFields({
    notifications: notifications,
    notifications_unread: notifications_unread
  });
}

export function recompute_qa_order(elements) {
  return $list.index_map(
    elements,
    (element, qa_order) => {
      let $ = element[1];
      if ($ instanceof $proposal_element.Block) {
        return element;
      } else {
        let q = $.question;
        let question = q.withFields({ qa_order: qa_order });
        return [element[0], new $proposal_element.Question(question)];
      }
    },
  );
}

export function remove_question(model, proposal_id, question_id) {
  let by_proposal = (() => {
    let _pipe = model.elements.by_proposal;
    let _pipe$1 = $dict.get(_pipe, proposal_id);
    let _pipe$2 = $result.map(
      _pipe$1,
      (_capture) => {
        return $list.filter(_capture, (q) => { return q[0] !== question_id; });
      },
    );
    let _pipe$3 = $result.map(_pipe$2, recompute_qa_order);
    let _pipe$4 = $result.map(
      _pipe$3,
      (_capture) => {
        return $dict.insert(model.elements.by_proposal, proposal_id, _capture);
      },
    );
    return $result.unwrap(_pipe$4, model.elements.by_proposal);
  })();
  let progress = (() => {
    let _pipe = by_proposal;
    let _pipe$1 = $dict.get(_pipe, proposal_id);
    let _pipe$2 = $result.unwrap(_pipe$1, toList([]));
    return compute_proposal_progress(_pipe$2);
  })();
  let proposals = (() => {
    let _pipe = model.proposals;
    let _pipe$1 = $list.key_find(_pipe, proposal_id);
    let _pipe$2 = $result.map(
      _pipe$1,
      (p) => { return p.withFields({ progress: progress }); },
    );
    let _pipe$3 = $result.map(
      _pipe$2,
      (_capture) => {
        return $list.key_set(model.proposals, proposal_id, _capture);
      },
    );
    return $result.unwrap(_pipe$3, model.proposals);
  })();
  let elements = model.elements.withFields({ by_proposal: by_proposal });
  return model.withFields({ elements: elements, proposals: proposals });
}

function upsert_notification(notifications, notification) {
  if (notifications.atLeastLength(1)) {
    let n = notifications.head;
    let rest = notifications.tail;
    let $ = n.id === notification.id;
    if ($) {
      return new Ok(listPrepend(notification, rest));
    } else {
      let _pipe = upsert_notification(rest, notification);
      return $result.map(_pipe, (value) => { return listPrepend(n, value); });
    }
  } else {
    return new Error(undefined);
  }
}

function upsert(qs, question) {
  if (qs.atLeastLength(1)) {
    let q = qs.head;
    let rest = qs.tail;
    let $ = q.id === question.id;
    if ($) {
      return listPrepend(question, rest);
    } else {
      return listPrepend(q, upsert(rest, question));
    }
  } else {
    return toList([question]);
  }
}

export function add_copilot_questions(model, questions) {
  let _pipe = $list.fold(
    questions,
    model.copilot_threads,
    (threads, question) => {
      let q = (() => {
        let _pipe = $list.key_find(threads, question.thread_id);
        return $result.unwrap(_pipe, toList([]));
      })();
      let _pipe = upsert(q, question);
      let _pipe$1 = $list.sort(
        _pipe,
        (a, b) => { return $birl.compare(a.created_at, b.created_at); },
      );
      return ((_capture) => {
        return $list.key_set(threads, question.thread_id, _capture);
      })(_pipe$1);
    },
  );
  return ((copilot_threads) => {
    return model.withFields({ copilot_threads: copilot_threads });
  })(_pipe);
}

export function update_org_user(model, user_id, function$) {
  let _pipe = model.users;
  let _pipe$1 = $list.map(
    _pipe,
    (user) => {
      return $bool.guard(
        user.id !== user_id,
        user,
        () => { return user.withFields({ function: new Some(function$) }); },
      );
    },
  );
  return ((users) => { return model.withFields({ users: users }); })(_pipe$1);
}

export function set_notification(model, notification) {
  let _pipe = upsert_notification(model.notifications, notification);
  let _pipe$1 = $result.lazy_unwrap(
    _pipe,
    () => { return listPrepend(notification, model.notifications); },
  );
  return ((_capture) => { return set_notifications(model, _capture); })(_pipe$1);
}

export function find_current_user(model) {
  return $list.find(
    model.users,
    (user) => {
      let connected_user = $option.map(model.user, (u) => { return u.sub; });
      return isEqual(new Some(user.id), connected_user);
    },
  );
}

export function find_current_user_id(model) {
  let user = find_current_user(model);
  return $result.map(user, (u) => { return u.id; });
}

export function current_org_id(model) {
  return $option.then$(model.user, (u) => { return u.org_id; });
}

export function is_qualification_matrix_hidden(model) {
  let _pipe = current_org_id(model);
  let _pipe$1 = $option.map(
    _pipe,
    (_capture) => {
      return $feature_flags.authorized(
        new $feature_flags.QualificationMatrix(),
        _capture,
      );
    },
  );
  let _pipe$2 = $option.unwrap(_pipe$1, false);
  return $bool.negate(_pipe$2);
}

export function is_translations_hidden(model) {
  let _pipe = current_org_id(model);
  let _pipe$1 = $option.map(
    _pipe,
    (_capture) => {
      return $feature_flags.authorized(
        new $feature_flags.Translations(),
        _capture,
      );
    },
  );
  let _pipe$2 = $option.unwrap(_pipe$1, false);
  return $bool.negate(_pipe$2);
}
