import * as gleam from './gleam.mjs'

export function getJSON(editor) {
  return editor.getJSON()
}

export function bold(editor) {
  const from = editor.view.state.selection.from
  const to = editor.view.state.selection.to
  editor.chain().toggleBold().setTextSelection({ from, to }).run()
}

export function italic(editor) {
  const from = editor.view.state.selection.from
  const to = editor.view.state.selection.to
  editor.chain().toggleItalic().setTextSelection({ from, to }).run()
}

export function underline(editor) {
  const from = editor.view.state.selection.from
  const to = editor.view.state.selection.to
  editor.chain().toggleUnderline().setTextSelection({ from, to }).run()
}

export function isActive(editor, selector) {
  return editor.isActive(selector)
}
