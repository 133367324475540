/// <reference types="./block.d.mts" />
import * as $decode from "../../../decode/decode.mjs";
import * as $json from "../../../gleam_json/gleam/json.mjs";
import * as $option from "../../../gleam_stdlib/gleam/option.mjs";
import * as $kind from "../../data/docx/kind.mjs";
import * as $decode_ from "../../decode/extra.mjs";
import { toList, CustomType as $CustomType } from "../../gleam.mjs";

export class Block extends $CustomType {
  constructor(element_type, id, owner, content, doc_id_order, metadata, proposal_id, qa_order) {
    super();
    this.element_type = element_type;
    this.id = id;
    this.owner = owner;
    this.content = content;
    this.doc_id_order = doc_id_order;
    this.metadata = metadata;
    this.proposal_id = proposal_id;
    this.qa_order = qa_order;
  }
}

export class Metadata extends $CustomType {}

export function encode_metadata(_) {
  return $json.object(toList([]));
}

export function encode(element) {
  return $json.object(
    toList([
      ["element_type", $json.string($kind.to_string(element.element_type))],
      ["id", $json.string(element.id)],
      ["content", $json.string(element.content)],
      ["doc_id_order", $json.int(element.doc_id_order)],
      ["metadata", encode_metadata(element.metadata)],
      ["proposal_id", $json.string(element.proposal_id)],
      ["owner", $json.nullable(element.owner, $json.string)],
      ["qa_order", $json.int(element.qa_order)],
    ]),
  );
}

function decode_metadata(dyn) {
  let _pipe = $decode.into(new Metadata());
  return $decode.from(_pipe, dyn);
}

export function decode(dyn) {
  let _pipe = $decode.into(
    $decode.parameter(
      (id) => {
        return $decode.parameter(
          (content) => {
            return $decode.parameter(
              (element_type) => {
                return $decode.parameter(
                  (doc_id_order) => {
                    return $decode.parameter(
                      (metadata) => {
                        return $decode.parameter(
                          (proposal_id) => {
                            return $decode.parameter(
                              (owner) => {
                                return $decode.parameter(
                                  (qa_order) => {
                                    return new Block(
                                      element_type,
                                      id,
                                      owner,
                                      content,
                                      doc_id_order,
                                      metadata,
                                      proposal_id,
                                      qa_order,
                                    );
                                  },
                                );
                              },
                            );
                          },
                        );
                      },
                    );
                  },
                );
              },
            );
          },
        );
      },
    ),
  );
  let _pipe$1 = $decode.field(_pipe, "id", $decode.string);
  let _pipe$2 = $decode.field(_pipe$1, "content", $decode.string);
  let _pipe$3 = $decode.field(
    _pipe$2,
    "element_type",
    $decode_.dynamic($kind.decode),
  );
  let _pipe$4 = $decode.field(_pipe$3, "doc_id_order", $decode.int);
  let _pipe$5 = $decode.field(
    _pipe$4,
    "metadata",
    $decode_.dynamic(decode_metadata),
  );
  let _pipe$6 = $decode.field(_pipe$5, "proposal_id", $decode.string);
  let _pipe$7 = $decode.field(
    _pipe$6,
    "owner",
    $decode.optional($decode.string),
  );
  let _pipe$8 = $decode.field(_pipe$7, "qa_order", $decode.int);
  return $decode.from(_pipe$8, dyn);
}
