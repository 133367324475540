/// <reference types="./states.d.mts" />
import * as $bright from "../../../../bright/bright.mjs";
import * as $project from "../../../../common/data/project.mjs";
import * as $layout from "../../../../ds/ds/components/layout.mjs";
import * as $skeleton from "../../../../ds/ds/components/skeleton.mjs";
import * as $list from "../../../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../../../gleam_stdlib/gleam/option.mjs";
import { None } from "../../../../gleam_stdlib/gleam/option.mjs";
import * as $a from "../../../../lustre/lustre/attribute.mjs";
import * as $el from "../../../../lustre/lustre/element.mjs";
import * as $h from "../../../../lustre/lustre/element/html.mjs";
import * as $model from "../../../data/model.mjs";
import * as $data from "../../../data/model/data.mjs";
import { Data } from "../../../data/model/data.mjs";
import { toList } from "../../../gleam.mjs";
import * as $common from "../../../view/projects/show/common.mjs";
import * as $s from "../../../view/styles/proposals.mjs";

export function no_questions_placeholder() {
  return $s.no_questions_wrapper(
    toList([]),
    toList([
      $s.empty_sheet(
        toList([]),
        toList([$el.text("This sheet does not contain any questions.")]),
      ),
      $s.empty_sheet(
        toList([]),
        toList([$el.text("However it will be kept as-is during the export.")]),
      ),
    ]),
  );
}

function no_questions_found() {
  return $s.no_questions_wrapper(
    toList([]),
    toList([
      $s.empty_sheet(
        toList([]),
        toList([$el.text("No proposal has been found.")]),
      ),
      $s.empty_sheet(toList([]), toList([$el.text("Come back to home.")])),
    ]),
  );
}

export function not_found(model) {
  let $ = $bright.data(model);
  let route = $.route;
  return $el.fragment(
    toList([
      $layout.column(
        toList([$layout.gap(8)]),
        toList([]),
        toList([
          $common.proposal_back(),
          $layout.row(
            toList([$layout.gap(8), $layout.justify("space-between")]),
            toList([]),
            toList([
              $h.div(
                toList([$a.style(toList([["height", "25px"]]))]),
                toList([]),
              ),
              $common.export_button(new None()),
            ]),
          ),
        ]),
      ),
      $layout.row(
        toList([$layout.justify("space-between")]),
        toList([]),
        toList([$common.mode_bar(new None(), route, false)]),
      ),
      $s.questions_wrapper(toList([]), toList([no_questions_found()])),
    ]),
  );
}

function loading_question() {
  return $s.question_card(
    toList([]),
    toList([
      $s.question_card_title(
        toList([]),
        toList([
          $s.question_number(toList([]), toList([$skeleton.skeleton(80, 20)])),
          $s.question_status(toList([]), toList([$skeleton.skeleton(80, 30)])),
          $s.question_question(
            toList([]),
            toList([$skeleton.skeleton(200, 25)]),
          ),
          $s.question_owner(toList([]), toList([$skeleton.skeleton(150, 40)])),
        ]),
      ),
      $layout.column(
        toList([$layout.gap(10)]),
        toList([$a.style(toList([["grid-column", "1"], ["grid-row", "2"]]))]),
        toList([$skeleton.full_width(60)]),
      ),
      $s.question_actions(
        toList([]),
        toList([$skeleton.skeleton(40, 40), $skeleton.skeleton(40, 40)]),
      ),
      $s.question_sources(
        toList([]),
        toList([$skeleton.skeleton(80, 20), $skeleton.skeleton(150, 20)]),
      ),
    ]),
  );
}

function loading_questions() {
  return $s.questions_wrapper(
    toList([]),
    toList([
      $s.question_questions(
        toList([]),
        $list.map($list.range(0, 5), (_) => { return loading_question(); }),
      ),
      $s.question_spreadsheet_sheets(
        toList([]),
        toList([$skeleton.skeleton(200, 25)]),
      ),
    ]),
  );
}

export function proposal_loading(model) {
  let $ = $bright.data(model);
  let route = $.route;
  return $el.fragment(
    toList([
      $layout.column(
        toList([$layout.gap(8)]),
        toList([]),
        toList([
          $common.proposal_back(),
          $layout.row(
            toList([$layout.gap(8), $layout.justify("space-between")]),
            toList([]),
            toList([
              $skeleton.skeleton(200, 25),
              $common.export_button(new None()),
            ]),
          ),
        ]),
      ),
      $layout.row(
        toList([$layout.justify("space-between")]),
        toList([]),
        toList([$common.mode_bar(new None(), route, false)]),
      ),
      loading_questions(),
    ]),
  );
}

export function proposal_questions_loading(model, project) {
  let $ = $bright.data(model);
  let route = $.route;
  return $el.fragment(
    toList([
      $layout.column(
        toList([$layout.gap(8)]),
        toList([]),
        toList([
          $common.proposal_back(),
          $layout.row(
            toList([$layout.gap(8), $layout.justify("space-between")]),
            toList([]),
            toList([
              $common.proposal_title(project),
              $common.export_button(new None()),
            ]),
          ),
        ]),
      ),
      $layout.row(
        toList([$layout.justify("space-between")]),
        toList([]),
        toList([
          $common.mode_bar(new None(), route, false),
          $skeleton.skeleton(24, 24),
        ]),
      ),
      loading_questions(),
    ]),
  );
}
