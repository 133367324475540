/// <reference types="./editor.d.mts" />
import * as $effect from "../../../../lustre/lustre/effect.mjs";
import {
  getJSON as get_json,
  isActive as is_active,
  bold as do_bold,
  italic as do_italic,
  underline as do_underline,
} from "../../../tiptap.ffi.mjs";

export { get_json };

export function bold(editor) {
  return $effect.from((_) => { return do_bold(editor); });
}

export function is_bold(editor) {
  return is_active(editor, "bold");
}

export function italic(editor) {
  return $effect.from((_) => { return do_italic(editor); });
}

export function is_italic(editor) {
  return is_active(editor, "italic");
}

export function underline(editor) {
  return $effect.from((_) => { return do_underline(editor); });
}

export function is_underline(editor) {
  return is_active(editor, "underline");
}
