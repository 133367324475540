/// <reference types="./loader.d.mts" />
import * as $colors from "../../../ds/ds/colors.mjs";
import * as $layout from "../../../ds/ds/components/layout.mjs";
import * as $skeleton from "../../../ds/ds/components/skeleton.mjs";
import * as $display from "../../../ds/ds/typography/display.mjs";
import * as $int from "../../../gleam_stdlib/gleam/int.mjs";
import * as $html from "../../../lustre/lustre/element/html.mjs";
import * as $s from "../../../sketch/sketch.mjs";
import * as $size from "../../../sketch/sketch/size.mjs";
import { percent, px } from "../../../sketch/sketch/size.mjs";
import * as $h from "../../../sketch_magic/sketch/magic/element/html.mjs";
import { toList } from "../../gleam.mjs";

function movable_text(delay) {
  return $s.class$(
    toList([
      $s.compose($display.sm()),
      $s.position("absolute"),
      $s.animation_name("fading-text"),
      $s.animation_timing_function("ease-in"),
      $s.animation_fill_mode("both"),
      $s.animation_delay($int.to_string(delay) + "ms"),
      $s.animation_duration("20s"),
      $s.animation_play_state("running"),
      $s.animation_iteration_count("infinite"),
      $s.white_space("nowrap"),
      $s.left(percent(50)),
      $s.color($colors.light.grey_800),
    ]),
  );
}

export function loader() {
  return $layout.row(
    toList([$layout.justify("center")]),
    toList([]),
    toList([
      $html.div(
        toList([]),
        toList([
          $h.div(
            $s.class$(toList([$s.position("relative"), $s.height(px(60))])),
            toList([]),
            toList([
              $h.div(
                movable_text(0),
                toList([]),
                toList([$h.text("Reading document")]),
              ),
              $h.div(
                movable_text(4000),
                toList([]),
                toList([$h.text("Analyzing content")]),
              ),
              $h.div(
                movable_text(8000),
                toList([]),
                toList([$h.text("Absorbing data")]),
              ),
              $h.div(
                movable_text(12_000),
                toList([]),
                toList([$h.text("Polishing render")]),
              ),
              $h.div(
                movable_text(16_000),
                toList([]),
                toList([$h.text("Improving format")]),
              ),
            ]),
          ),
          $skeleton.skeleton(300, 10),
        ]),
      ),
    ]),
  );
}
