/// <reference types="./translation.d.mts" />
import * as $translate from "../../../common/data/translate.mjs";
import * as $dropdown from "../../../ds/ds/components/dropdown.mjs";
import * as $icons from "../../../ds/ds/icons.mjs";
import * as $bool from "../../../gleam_stdlib/gleam/bool.mjs";
import * as $list from "../../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../../gleam_stdlib/gleam/option.mjs";
import * as $el from "../../../lustre/lustre/element.mjs";
import { toList } from "../../gleam.mjs";

function language_to_choice(language, default$) {
  let title = $translate.display_language(language, default$);
  let value = $translate.decode_language_iso639_1(language);
  return $dropdown.choice(title, value);
}

export function selector(languages, placeholder, default_language, on_select) {
  return $bool.lazy_guard(
    $option.is_none(default_language),
    $el.none,
    () => {
      return $bool.lazy_guard(
        $list.is_empty(languages),
        $el.none,
        () => {
          let placeholder$1 = $translate.display_language(
            placeholder,
            default_language,
          );
          let on_select$1 = (l) => {
            return on_select($translate.language_from_iso639(l));
          };
          return $dropdown.dropdown(
            toList([
              $dropdown.panel_right(),
              $dropdown.placeholder(placeholder$1),
              $dropdown.icon($icons.translate()),
              $dropdown.on_selected(on_select$1),
            ]),
            $list.map(
              languages,
              (_capture) => {
                return language_to_choice(_capture, default_language);
              },
            ),
          );
        },
      );
    },
  );
}
