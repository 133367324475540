export type Element = HTMLElement | Text | SVGElement | null | false
export type Attribute =
  | { kind: 'none' }
  | { kind: 'attr'; name: string; value: string }
  | { kind: 'handler'; name: string; value: (event: Event) => void }

function el(tagName: string) {
  return function (attributes: Attribute[], children: Element[]) {
    const node = document.createElement(tagName)
    attributes.forEach(prop => {
      switch (prop.kind) {
        case 'none':
          return
        case 'handler':
          return addListener(node, prop.name, event => {
            prop.value(event)
            return null
          })
        case 'attr': {
          const { name, value } = prop
          if (name === 'class') {
            const previous = node.getAttribute(name)
            const classes = previous ? [previous, value].join(' ') : value
            node.setAttribute(name, classes)
          } else if (name === 'style') {
            const previous = node.getAttribute(name)
            const classes = previous ? [previous, value].join(';') : value
            node.setAttribute(name, classes)
          } else {
            node.setAttribute(name, value)
          }
        }
      }
    })
    node.append(...children.filter(c => !!c))
    return node
  }
}

function addListener(
  node: HTMLElement,
  name: string,
  value: (event: Event) => null
) {
  node.addEventListener(name, value)
}

export function element(
  tagName: string,
  attributes: Attribute[],
  children: Element[]
) {
  return el(tagName)(attributes, children)
}

export const div = el('div')
export const p = el('p')
export const button = el('button')
export const span = el('span')
export const table = el('table')
export const tbody = el('tbody')
export const thead = el('thead')
export const tr = el('tr')
export const td = el('td')
export const th = el('th')
export const img = (attributes: Attribute[]) => el('img')(attributes, [])

export function attribute(name: string, value: string): Attribute {
  return { name, value, kind: 'attr' }
}

export function on(name: string, value: (event: Event) => void): Attribute {
  return { name, value, kind: 'handler' }
}

export function text(content: string) {
  return document.createTextNode(content)
}

export const class_ = (value: string) => attribute('class', value)
export const id = (value: string) => attribute('id', value)
export const src = (value: string) => attribute('src', value)
export const contentEditable = (value: boolean) =>
  attribute('contentEditable', `${value}`)

export function style(styles: { [key: string]: string | number }): Attribute {
  const value = Object.entries(styles)
    .map(([key, value]) => {
      key = uncamelize(key)
      if (typeof value === 'number') value = `${value}px`
      return `${key}: ${value}`
    })
    .join(';')
  return { name: 'style', value, kind: 'attr' }
}

export function none(): Attribute {
  return { kind: 'none' }
}

export const onClick = (value: (event: Event) => void) => on('click', value)

function uncamelize(text: string, separator = '-') {
  return text
    .replace(/[A-Z]/g, letter => separator + letter.toLowerCase())
    .replace('/^' + separator + '/', '')
}
