/// <reference types="./label.d.mts" />
import * as $status from "../../../common/data/status.mjs";
import * as $label from "../../../ds/ds/components/label.mjs";
import { toList } from "../../gleam.mjs";

export function from_status(status) {
  let content = $status.to_string(status);
  let status$1 = (() => {
    if (status instanceof $status.Won) {
      return $label.success();
    } else if (status instanceof $status.Cancelled) {
      return $label.error();
    } else if (status instanceof $status.InProgress) {
      return $label.current();
    } else if (status instanceof $status.Lost) {
      return $label.error();
    } else {
      return $label.neutral();
    }
  })();
  return $label.label(toList([status$1, $label.content(content)]));
}
