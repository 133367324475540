/// <reference types="./proposal_element.d.mts" />
import * as $decode from "../../decode/decode.mjs";
import * as $int from "../../gleam_stdlib/gleam/int.mjs";
import * as $option from "../../gleam_stdlib/gleam/option.mjs";
import * as $a from "../../lustre/lustre/attribute.mjs";
import * as $el from "../../lustre/lustre/element.mjs";
import * as $block from "../data/proposal_element/block.mjs";
import * as $question from "../data/proposal_element/question.mjs";
import * as $decode_ from "../decode/extra.mjs";
import { Ok, Error, toList, CustomType as $CustomType } from "../gleam.mjs";

export class Question extends $CustomType {
  constructor(question) {
    super();
    this.question = question;
  }
}

export class Block extends $CustomType {
  constructor(block) {
    super();
    this.block = block;
  }
}

export function encode(el) {
  if (el instanceof Question) {
    let question$1 = el.question;
    return $question.encode(question$1);
  } else {
    let block$1 = el.block;
    return $block.encode(block$1);
  }
}

export function question(el) {
  if (el instanceof Question) {
    let question$1 = el.question;
    return new Ok(question$1);
  } else {
    return new Error(undefined);
  }
}

export function block(el) {
  if (el instanceof Block) {
    let element = el.block;
    return new Ok(element);
  } else {
    return new Error(undefined);
  }
}

export function decode(dyn) {
  let _pipe = $decode.one_of(
    toList([
      (() => {
        let _pipe = $decode_.dynamic($question.decode);
        return $decode.map(_pipe, (var0) => { return new Question(var0); });
      })(),
      (() => {
        let _pipe = $decode_.dynamic($block.decode);
        return $decode.map(_pipe, (var0) => { return new Block(var0); });
      })(),
    ]),
  );
  return $decode.from(_pipe, dyn);
}

export function id(el) {
  if (el instanceof Block) {
    let e = el.block;
    return e.id;
  } else {
    let q = el.question;
    return q.id;
  }
}

export function proposal_id(el) {
  if (el instanceof Block) {
    let e = el.block;
    return e.proposal_id;
  } else {
    let q = el.question;
    return q.proposal_id;
  }
}

export function owner(el) {
  if (el instanceof Block) {
    let e = el.block;
    return e.owner;
  } else {
    let q = el.question;
    return q.owner;
  }
}

export function doc_id_order(el) {
  if (el instanceof Block) {
    let e = el.block;
    return e.doc_id_order;
  } else {
    let q = el.question;
    return q.doc_id_order;
  }
}

export function qa_order(el) {
  if (el instanceof Block) {
    let e = el.block;
    return e.qa_order;
  } else {
    let q = el.question;
    return q.qa_order;
  }
}

function map_attribute(value, data_key) {
  let _pipe = value;
  let _pipe$1 = $option.map(
    _pipe,
    (_capture) => { return $a.attribute(data_key, _capture); },
  );
  return $option.lazy_unwrap(_pipe$1, $a.none);
}

export function to_editor(el, profile_picture, initials) {
  let order = $int.to_string(doc_id_order(el));
  let index = $a.attribute("data-index", order);
  let id$1 = $a.attribute("data-id", id(el));
  let _pipe = (() => {
    if (el instanceof Block) {
      let e = el.block;
      let content = $a.attribute("dangerous-unescaped-html", e.content);
      return $el.element(
        "steerlab-block",
        toList([index, id$1, content]),
        toList([]),
      );
    } else {
      let q = el.question;
      let content = el.question.content;
      let answer = el.question.answer;
      let long = answer.long;
      let custom = answer.custom;
      let answer$1 = (() => {
        let $ = $option.or(custom, long);
        if ($ instanceof $option.Some && $[0] === "") {
          return "<p></p>";
        } else if ($ instanceof $option.Some) {
          let value = $[0];
          return value;
        } else {
          return "<p></p>";
        }
      })();
      let status = $a.attribute(
        "data-status",
        (() => {
          let $ = q.validated;
          if ($) {
            return "complete";
          } else {
            return "in-review";
          }
        })(),
      );
      let picture = map_attribute(profile_picture, "data-picture");
      let initials$1 = map_attribute(initials, "data-initials");
      let content$1 = $a.attribute("dangerous-unescaped-html", content);
      let answer$2 = $a.attribute("dangerous-unescaped-html", answer$1);
      return $el.element(
        "steerlab-question",
        toList([index, id$1]),
        toList([
          (() => {
            let _pipe = toList([id$1, content$1, status, picture, initials$1]);
            return ((_capture) => {
              return $el.element("steerlab-subject", _capture, toList([]));
            })(_pipe);
          })(),
          $el.element("steerlab-answer", toList([answer$2, id$1]), toList([])),
        ]),
      );
    }
  })();
  return $el.to_string(_pipe);
}
