import { Extension } from '@tiptap/core'

export interface LineHeightOptions {
  types: string[]
  heights: string[]
  defaultHeight: string
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    lineHeight: {
      /** Set the line height attribute. */
      setLineHeight: (height: string) => ReturnType
      /** Unset the text align attribute. */
      unsetLineHeight: () => ReturnType
    }
  }
}

export const LineHeight = Extension.create<LineHeightOptions>({
  name: 'lineHeight',

  addOptions() {
    const types = ['heading', 'paragraph']
    const heights = ['100%', '115%', '150%', '200%', '250%', '300%']
    const defaultHeight = '100%'
    return { types, heights, defaultHeight }
  },

  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          lineHeight: {
            default: this.options.defaultHeight,
            parseHTML: el => el.style.lineHeight || this.options.defaultHeight,
            renderHTML: attrs => {
              if (attrs.lineHeight === this.options.defaultHeight) return {}
              return { style: `line-height: ${attrs.lineHeight}` }
            },
          },
        },
      },
    ]
  },

  addCommands() {
    return {
      setLineHeight: (height: string) => {
        return ({ commands }) => {
          if (!this.options.heights.includes(height)) return false
          return this.options.types.every(type => {
            return commands.updateAttributes(type, { lineHeight: height })
          })
        }
      },

      unsetLineHeight: () => {
        return ({ commands }) => {
          return this.options.types.every(type =>
            commands.resetAttributes(type, 'lineHeight')
          )
        }
      },
    }
  },
})
