/// <reference types="./table.d.mts" />
import * as $json from "../../../gleam_json/gleam/json.mjs";
import * as $dict from "../../../gleam_stdlib/gleam/dict.mjs";
import * as $decode from "../../../gleam_stdlib/gleam/dynamic/decode.mjs";
import * as $int from "../../../gleam_stdlib/gleam/int.mjs";
import * as $list from "../../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../../gleam_stdlib/gleam/option.mjs";
import { None } from "../../../gleam_stdlib/gleam/option.mjs";
import * as $pair from "../../../gleam_stdlib/gleam/pair.mjs";
import * as $a from "../../../lustre/lustre/attribute.mjs";
import * as $element from "../../../lustre/lustre/element.mjs";
import * as $html from "../../../lustre/lustre/element/html.mjs";
import * as $kind from "../../data/docx/kind.mjs";
import * as $paragraph from "../../data/docx/paragraph.mjs";
import * as $text from "../../data/docx/text.mjs";
import { toList, CustomType as $CustomType } from "../../gleam.mjs";

export class Table extends $CustomType {
  constructor(index, tag, rows, metadata) {
    super();
    this.index = index;
    this.tag = tag;
    this.rows = rows;
    this.metadata = metadata;
  }
}

export class Row extends $CustomType {
  constructor(index, is_header, cells) {
    super();
    this.index = index;
    this.is_header = is_header;
    this.cells = cells;
  }
}

export class Cell extends $CustomType {
  constructor(index, children, styles) {
    super();
    this.index = index;
    this.children = children;
    this.styles = styles;
  }
}

export class Text extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class Paragraph extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class Metadata extends $CustomType {
  constructor(kind) {
    super();
    this.kind = kind;
  }
}

export function to_html(table) {
  let index = $a.attribute("data-index", $int.to_string(table.index));
  return $element.element(
    table.tag,
    toList([index]),
    $list.map(
      table.rows,
      (row) => {
        let index$1 = $a.attribute("data-index", $int.to_string(row.index));
        let node = (() => {
          let $ = row.is_header;
          if ($) {
            return $html.th;
          } else {
            return $html.tr;
          }
        })();
        return node(
          toList([index$1]),
          $list.map(
            row.cells,
            (cell) => {
              let index$2 = $a.attribute(
                "data-index",
                $int.to_string(cell.index),
              );
              let style = $a.style(cell.styles);
              return $html.td(
                toList([index$2, style]),
                $list.map(
                  cell.children,
                  (content) => {
                    if (content instanceof Paragraph) {
                      let p = content[0];
                      return $paragraph.to_html(p);
                    } else {
                      let t = content[0];
                      return $text.to_html(t);
                    }
                  },
                ),
              );
            },
          ),
        );
      },
    ),
  );
}

export function to_labelling(table) {
  let index = $a.attribute("data-index", $int.to_string(table.index));
  let kind = (() => {
    let _pipe = table.metadata.kind;
    let _pipe$1 = $option.map(_pipe, $kind.to_string);
    let _pipe$2 = $option.map(
      _pipe$1,
      (_capture) => { return $a.attribute("kind", _capture); },
    );
    return $option.unwrap(_pipe$2, $a.none());
  })();
  let content = to_html(table);
  return $element.element(
    "document-labeller",
    toList([index, kind]),
    toList([content]),
  );
}

function type_decoder() {
  return $decode.then$(
    $decode.string,
    (string) => {
      if (string === "table") {
        return $decode.success(undefined);
      } else {
        return $decode.failure(undefined, "table");
      }
    },
  );
}

function cell_decoder() {
  return $decode.field(
    "index",
    $decode.int,
    (index) => {
      return $decode.optional_field(
        "children",
        toList([]),
        (() => {
          let _pipe = (() => {
            let _pipe = $paragraph.decoder();
            return $decode.map(_pipe, (var0) => { return new Paragraph(var0); });
          })();
          return $decode.list(_pipe);
        })(),
        (children) => {
          return $decode.optional_field(
            "cell_style",
            toList([]),
            (() => {
              let _pipe = $decode.dict($decode.string, $decode.string);
              return $decode.map(_pipe, $dict.to_list);
            })(),
            (styles) => {
              return $decode.success(new Cell(index, children, styles));
            },
          );
        },
      );
    },
  );
}

function row_decoder() {
  return $decode.field(
    "index",
    $decode.int,
    (index) => {
      return $decode.optional_field(
        "is_header",
        false,
        $decode.bool,
        (is_header) => {
          return $decode.optional_field(
            "cells",
            toList([]),
            $decode.list(cell_decoder()),
            (cells) => {
              return $decode.success(new Row(index, is_header, cells));
            },
          );
        },
      );
    },
  );
}

function encode_cell(cell) {
  return $json.object(
    toList([
      ["index", $json.int(cell.index)],
      [
        "children",
        $json.array(
          cell.children,
          (content) => {
            if (content instanceof Paragraph) {
              let p = content[0];
              return $paragraph.encode(p);
            } else {
              let t = content[0];
              return $text.encode(t);
            }
          },
        ),
      ],
      [
        "cell_style",
        (() => {
          let _pipe = cell.styles;
          let _pipe$1 = $list.map(
            _pipe,
            (_capture) => { return $pair.map_second(_capture, $json.string); },
          );
          return $json.object(_pipe$1);
        })(),
      ],
    ]),
  );
}

function encode_row(row) {
  return $json.object(
    toList([
      ["index", $json.int(row.index)],
      ["is_header", $json.bool(row.is_header)],
      ["cells", $json.array(row.cells, encode_cell)],
    ]),
  );
}

export function encode(table) {
  return $json.object(
    toList([
      ["type", $json.string("table")],
      ["index", $json.int(table.index)],
      ["html_tag", $json.string(table.tag)],
      ["rows", $json.array(table.rows, encode_row)],
      ["metadata", $json.object(toList([]))],
    ]),
  );
}

function metadata_decoder() {
  let decoder$1 = $decode.optional($kind.decoder());
  return $decode.optional_field(
    "kind",
    new None(),
    decoder$1,
    (kind) => { return $decode.success(new Metadata(kind)); },
  );
}

function empty_metadata() {
  return new Metadata(new None());
}

export function decoder() {
  return $decode.field(
    "type",
    type_decoder(),
    (_) => {
      return $decode.field(
        "index",
        $decode.int,
        (index) => {
          return $decode.field(
            "html_tag",
            $decode.string,
            (tag) => {
              return $decode.optional_field(
                "rows",
                toList([]),
                $decode.list(row_decoder()),
                (rows) => {
                  return $decode.optional_field(
                    "metadata",
                    empty_metadata(),
                    metadata_decoder(),
                    (metadata) => {
                      return $decode.success(
                        new Table(index, tag, rows, metadata),
                      );
                    },
                  );
                },
              );
            },
          );
        },
      );
    },
  );
}
