/// <reference types="./proposal.d.mts" />
import * as $birl from "../../birl/birl.mjs";
import * as $decode from "../../decode/decode.mjs";
import * as $json from "../../gleam_json/gleam/json.mjs";
import * as $dict from "../../gleam_stdlib/gleam/dict.mjs";
import * as $dynamic from "../../gleam_stdlib/gleam/dynamic.mjs";
import * as $list from "../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../gleam_stdlib/gleam/option.mjs";
import { Some } from "../../gleam_stdlib/gleam/option.mjs";
import * as $result from "../../gleam_stdlib/gleam/result.mjs";
import * as $string from "../../gleam_stdlib/gleam/string.mjs";
import * as $question from "../data/proposal_element/question.mjs";
import * as $qualification_matrix from "../data/qualification_matrix.mjs";
import * as $spreadsheet from "../data/spreadsheet.mjs";
import * as $status from "../data/status.mjs";
import * as $tag from "../data/tag.mjs";
import * as $translate from "../data/translate.mjs";
import * as $decode_ from "../decode/extra.mjs";
import {
  Ok,
  Error,
  toList,
  prepend as listPrepend,
  CustomType as $CustomType,
  isEqual,
} from "../gleam.mjs";
import * as $dynamic_ from "../gleam/dynamic/extra.mjs";

export class QuestionnaireResponse extends $CustomType {
  constructor(name, column, kind) {
    super();
    this.name = name;
    this.column = column;
    this.kind = kind;
  }
}

export class Document extends $CustomType {
  constructor(tags, languages, qualification_matrix, default_language) {
    super();
    this.tags = tags;
    this.languages = languages;
    this.qualification_matrix = qualification_matrix;
    this.default_language = default_language;
  }
}

export class Questionnaire extends $CustomType {
  constructor(tags, languages, qualification_matrix, default_language, sheets, responses, worksheets) {
    super();
    this.tags = tags;
    this.languages = languages;
    this.qualification_matrix = qualification_matrix;
    this.default_language = default_language;
    this.sheets = sheets;
    this.responses = responses;
    this.worksheets = worksheets;
  }
}

export class Display extends $CustomType {
  constructor(type_) {
    super();
    this.type_ = type_;
  }
}

export class Sheets extends $CustomType {}

export class Doc extends $CustomType {}

export class Proposal extends $CustomType {
  constructor(id, display, org_id, name, deadline, owner, client, collaborators, progress, status, metadata, created_at, kind, project_id, google_sheet_id) {
    super();
    this.id = id;
    this.display = display;
    this.org_id = org_id;
    this.name = name;
    this.deadline = deadline;
    this.owner = owner;
    this.client = client;
    this.collaborators = collaborators;
    this.progress = progress;
    this.status = status;
    this.metadata = metadata;
    this.created_at = created_at;
    this.kind = kind;
    this.project_id = project_id;
    this.google_sheet_id = google_sheet_id;
  }
}

export class Validated extends $CustomType {}

export class InReview extends $CustomType {}

export class AllStatus extends $CustomType {}

export class ProposalFilters extends $CustomType {
  constructor(status, owner) {
    super();
    this.status = status;
    this.owner = owner;
  }
}

export function encode_kind(kind) {
  if (kind instanceof Doc) {
    return "doc";
  } else {
    return "sheets";
  }
}

export function decode_kind(dyn) {
  let $ = $dynamic.string(dyn);
  if ($.isOk()) {
    let k = $[0];
    if (k === "doc") {
      return new Ok(new Doc());
    } else if (k === "sheets") {
      return new Ok(new Sheets());
    } else {
      return new Error(toList([]));
    }
  } else {
    let e = $[0];
    return new Error(e);
  }
}

export function encode_metadata(metadata) {
  if (metadata instanceof Document) {
    return $json.object(
      toList([
        ["type", $json.string("document")],
        [
          "languages",
          $json.array(
            metadata.languages,
            (language) => {
              let _pipe = language;
              let _pipe$1 = $translate.decode_language_iso639_1(_pipe);
              return $json.string(_pipe$1);
            },
          ),
        ],
        [
          "default_language",
          $json.nullable(
            metadata.default_language,
            (language) => {
              let _pipe = $translate.decode_language_iso639_1(language);
              return $json.string(_pipe);
            },
          ),
        ],
        ["tags", $json.array(metadata.tags, $tag.encode)],
        [
          "qualification_matrix",
          $json.nullable(
            metadata.qualification_matrix,
            (q) => {
              return $json.array(
                q,
                (_use0) => {
                  let matrix = _use0[0];
                  let answer = _use0[1];
                  return $json.object(
                    toList([
                      ["item", $qualification_matrix.encode(matrix)],
                      ["state", $qualification_matrix.encode_answer(answer)],
                    ]),
                  );
                },
              );
            },
          ),
        ],
      ]),
    );
  } else {
    return $json.object(
      toList([
        ["type", $json.string("questionnaire")],
        [
          "languages",
          $json.array(
            metadata.languages,
            (language) => {
              let _pipe = language;
              let _pipe$1 = $translate.decode_language_iso639_1(_pipe);
              return $json.string(_pipe$1);
            },
          ),
        ],
        [
          "default_language",
          $json.nullable(
            metadata.default_language,
            (language) => {
              let _pipe = $translate.decode_language_iso639_1(language);
              return $json.string(_pipe);
            },
          ),
        ],
        ["tags", $json.array(metadata.tags, $tag.encode)],
        ["sheets", $json.array(metadata.sheets, $json.string)],
        [
          "responses",
          $json.array(
            metadata.responses,
            (response) => {
              return $json.object(
                toList([
                  ["name", $json.string(response.name)],
                  ["column", $json.int(response.column)],
                  ["kind", $question.encode_question_type(response.kind)],
                ]),
              );
            },
          ),
        ],
        [
          "worksheets",
          $json.array(
            metadata.worksheets,
            (w) => {
              return $json.object(
                toList([
                  ["name", $json.string(w.name)],
                  ["start_index", $json.int(w.start_index)],
                  [
                    "content",
                    $json.array(
                      w.content,
                      (r) => { return $json.array(r, $spreadsheet.encode_cell); },
                    ),
                  ],
                  [
                    "columns_type",
                    $spreadsheet.encode_columns_type(w.columns_type),
                  ],
                  ["rows_type", $spreadsheet.encode_rows_type(w.rows_type)],
                ]),
              );
            },
          ),
        ],
        [
          "qualification_matrix",
          $json.nullable(
            metadata.qualification_matrix,
            (q) => {
              return $json.array(
                q,
                (_use0) => {
                  let matrix = _use0[0];
                  let answer = _use0[1];
                  return $json.object(
                    toList([
                      ["item", $qualification_matrix.encode(matrix)],
                      ["state", $qualification_matrix.encode_answer(answer)],
                    ]),
                  );
                },
              );
            },
          ),
        ],
      ]),
    );
  }
}

function decode_languages(dyn) {
  let _pipe = $dynamic.optional(
    $dynamic.list($translate.decode_language_iso639_1_dyn),
  )(dyn);
  return $result.map(
    _pipe,
    (_capture) => { return $option.unwrap(_capture, toList([])); },
  );
}

function decode_string_to_cell(dyn) {
  let _pipe = $dynamic.optional($dynamic.string)(dyn);
  return $result.map(
    _pipe,
    (s) => { return new $spreadsheet.Cell(s, toList([])); },
  );
}

export function encode_display(display) {
  return $json.object(toList([["type", $json.string(display.type_)]]));
}

export function encode(proposal) {
  return $json.object(
    toList([
      ["id", $json.string(proposal.id)],
      ["display", encode_display(proposal.display)],
      ["org_id", $json.string(proposal.org_id)],
      ["name", $json.string(proposal.name)],
      [
        "deadline",
        (() => {
          let _pipe = proposal.deadline;
          let _pipe$1 = $birl.to_iso8601(_pipe);
          return $json.string(_pipe$1);
        })(),
      ],
      ["owner", $json.string(proposal.owner)],
      ["client", $json.string(proposal.client)],
      ["collaborators", $json.array(proposal.collaborators, $json.string)],
      ["progress", $json.int(proposal.progress)],
      ["status", $status.encode(proposal.status)],
      ["metadata", encode_metadata(proposal.metadata)],
      [
        "created_at",
        (() => {
          let _pipe = proposal.created_at;
          let _pipe$1 = $birl.to_iso8601(_pipe);
          return $json.string(_pipe$1);
        })(),
      ],
      [
        "kind",
        (() => {
          let _pipe = proposal.kind;
          let _pipe$1 = encode_kind(_pipe);
          return $json.string(_pipe$1);
        })(),
      ],
      ["project_id", $json.string(proposal.project_id)],
      [
        "google_sheet_id",
        $json.nullable(proposal.google_sheet_id, $json.string),
      ],
    ]),
  );
}

export function is_collaborator(proposal, user_id) {
  let all_collaborators = listPrepend(proposal.owner, proposal.collaborators);
  return $list.contains(all_collaborators, user_id);
}

export function count_qualification_matrix_answers(qualification_matrix) {
  return $list.fold(
    qualification_matrix,
    [0.0, 0.0, 0.0],
    (_use0, val) => {
      let yes = _use0[0];
      let no = _use0[1];
      let total = _use0[2];
      let total$1 = total + 1.0;
      let $ = val[1];
      if ($ instanceof $qualification_matrix.Yes) {
        return [yes + 1.0, no, total$1];
      } else if ($ instanceof $qualification_matrix.No) {
        return [yes, no + 1.0, total$1];
      } else {
        return [yes, no, total$1];
      }
    },
  );
}

export function initial_question_kinds(proposal, sheet) {
  let $ = proposal.metadata;
  if ($ instanceof Document) {
    return $question.default_kinds(toList([]));
  } else {
    let responses = $.responses;
    let _pipe = $list.filter_map(
      responses,
      (response) => {
        let $1 = isEqual(new Some(response.name), sheet);
        if ($1) {
          return new Ok(response.kind);
        } else {
          return new Error(undefined);
        }
      },
    );
    let _pipe$1 = $list.unique(_pipe);
    return $question.default_kinds(_pipe$1);
  }
}

function decode_questionnaire() {
  let _pipe = $decode.into(
    $decode.parameter(
      (_) => {
        return $decode.parameter(
          (tags) => {
            return $decode.parameter(
              (languages) => {
                return $decode.parameter(
                  (qualification_matrix) => {
                    return $decode.parameter(
                      (default_language) => {
                        return $decode.parameter(
                          (sheets) => {
                            return $decode.parameter(
                              (responses) => {
                                return $decode.parameter(
                                  (worksheets) => {
                                    return new Questionnaire(
                                      tags,
                                      languages,
                                      qualification_matrix,
                                      default_language,
                                      sheets,
                                      responses,
                                      worksheets,
                                    );
                                  },
                                );
                              },
                            );
                          },
                        );
                      },
                    );
                  },
                );
              },
            );
          },
        );
      },
    ),
  );
  let _pipe$1 = $decode.field(
    _pipe,
    "type",
    $decode.then$(
      $decode.string,
      (t) => {
        if (t === "questionnaire") {
          return $decode.into(t);
        } else {
          return $decode.fail("type");
        }
      },
    ),
  );
  let _pipe$2 = $decode.field(
    _pipe$1,
    "tags",
    $decode.list($decode_.dynamic($tag.decode)),
  );
  let _pipe$3 = $decode.field(
    _pipe$2,
    "languages",
    $decode_.dynamic(decode_languages),
  );
  let _pipe$4 = $decode.field(
    _pipe$3,
    "qualification_matrix",
    $decode.optional($decode.list(decode_qualification_matrix())),
  );
  let _pipe$5 = $decode.field(
    _pipe$4,
    "default_language",
    $decode.optional($decode_.dynamic($translate.decode_language_iso639_1_dyn)),
  );
  let _pipe$6 = $decode.field(_pipe$5, "sheets", $decode.list($decode.string));
  let _pipe$7 = $decode.field(
    _pipe$6,
    "responses",
    $decode.list(decode_questionnaire_response()),
  );
  return $decode.field(_pipe$7, "worksheets", $decode.list(decode_worksheet()));
}

export function decode(dyn) {
  let _pipe = $decode.into(
    $decode.parameter(
      (id) => {
        return $decode.parameter(
          (display) => {
            return $decode.parameter(
              (org_id) => {
                return $decode.parameter(
                  (name) => {
                    return $decode.parameter(
                      (deadline) => {
                        return $decode.parameter(
                          (owner) => {
                            return $decode.parameter(
                              (client) => {
                                return $decode.parameter(
                                  (collaborators) => {
                                    return $decode.parameter(
                                      (progress) => {
                                        return $decode.parameter(
                                          (status) => {
                                            return $decode.parameter(
                                              (metadata) => {
                                                return $decode.parameter(
                                                  (created_at) => {
                                                    return $decode.parameter(
                                                      (kind) => {
                                                        return $decode.parameter(
                                                          (project_id) => {
                                                            return $decode.parameter(
                                                              (google_sheet_id) => {
                                                                return new Proposal(
                                                                  id,
                                                                  display,
                                                                  org_id,
                                                                  name,
                                                                  deadline,
                                                                  owner,
                                                                  client,
                                                                  collaborators,
                                                                  progress,
                                                                  status,
                                                                  metadata,
                                                                  created_at,
                                                                  kind,
                                                                  project_id,
                                                                  google_sheet_id,
                                                                );
                                                              },
                                                            );
                                                          },
                                                        );
                                                      },
                                                    );
                                                  },
                                                );
                                              },
                                            );
                                          },
                                        );
                                      },
                                    );
                                  },
                                );
                              },
                            );
                          },
                        );
                      },
                    );
                  },
                );
              },
            );
          },
        );
      },
    ),
  );
  let _pipe$1 = $decode.field(
    _pipe,
    "id",
    (() => {
      let _pipe$1 = $dynamic_.uuid;
      let _pipe$2 = $decode_.dynamic(_pipe$1);
      return $decode.map(_pipe$2, $string.lowercase);
    })(),
  );
  let _pipe$2 = $decode.field(_pipe$1, "display", $decode_.json(decode_display));
  let _pipe$3 = $decode.field(_pipe$2, "org_id", $decode.string);
  let _pipe$4 = $decode.field(_pipe$3, "name", $decode.string);
  let _pipe$5 = $decode.field(_pipe$4, "deadline", $decode_.time());
  let _pipe$6 = $decode.field(_pipe$5, "owner", $decode.string);
  let _pipe$7 = $decode.field(_pipe$6, "client", $decode.string);
  let _pipe$8 = $decode.field(
    _pipe$7,
    "collaborators",
    $decode.list($decode.string),
  );
  let _pipe$9 = $decode.field(_pipe$8, "progress", $decode.int);
  let _pipe$10 = $decode.field(
    _pipe$9,
    "status",
    $decode_.dynamic($status.decode),
  );
  let _pipe$11 = $decode.field(
    _pipe$10,
    "metadata",
    $decode_.json(decode_metadata),
  );
  let _pipe$12 = $decode.field(_pipe$11, "created_at", $decode_.time());
  let _pipe$13 = $decode.field(_pipe$12, "kind", $decode_.dynamic(decode_kind));
  let _pipe$14 = $decode.field(
    _pipe$13,
    "project_id",
    (() => {
      let _pipe$14 = $decode.string;
      let _pipe$15 = $decode.optional(_pipe$14);
      return $decode.map(
        _pipe$15,
        (_capture) => { return $option.unwrap(_capture, "unknown"); },
      );
    })(),
  );
  let _pipe$15 = $decode.field(
    _pipe$14,
    "google_sheet_id",
    $decode.optional($decode.string),
  );
  return $decode.from(_pipe$15, dyn);
}

export function decode_metadata(dyn) {
  let _pipe = $decode.one_of(
    toList([decode_document(), decode_questionnaire()]),
  );
  return $decode.from(_pipe, dyn);
}

function decode_questionnaire_response() {
  let _pipe = $decode.into(
    $decode.parameter(
      (name) => {
        return $decode.parameter(
          (column) => {
            return $decode.parameter(
              (kind) => { return new QuestionnaireResponse(name, column, kind); },
            );
          },
        );
      },
    ),
  );
  let _pipe$1 = $decode.field(_pipe, "name", $decode.string);
  let _pipe$2 = $decode.field(_pipe$1, "column", $decode.int);
  return ((d) => {
    return $decode.one_of(
      toList([
        $decode.field(
          d,
          "kind",
          $decode_.dynamic($question.decode_question_type),
        ),
        $decode.map(d, (d) => { return d(new $question.Long()); }),
      ]),
    );
  })(_pipe$2);
}

function decode_qualification_matrix() {
  let _pipe = $decode.into(
    $decode.parameter(
      (item) => {
        return $decode.parameter((state) => { return [item, state]; });
      },
    ),
  );
  let _pipe$1 = $decode.field(
    _pipe,
    "item",
    $decode_.dynamic($qualification_matrix.decode),
  );
  return $decode.field(
    _pipe$1,
    "state",
    $decode_.dynamic($qualification_matrix.decode_answer),
  );
}

function decode_document() {
  let _pipe = $decode.into(
    $decode.parameter(
      (_) => {
        return $decode.parameter(
          (tags) => {
            return $decode.parameter(
              (languages) => {
                return $decode.parameter(
                  (qualification_matrix) => {
                    return $decode.parameter(
                      (default_language) => {
                        return new Document(
                          tags,
                          languages,
                          qualification_matrix,
                          default_language,
                        );
                      },
                    );
                  },
                );
              },
            );
          },
        );
      },
    ),
  );
  let _pipe$1 = $decode.field(
    _pipe,
    "type",
    $decode.then$(
      $decode.string,
      (t) => {
        if (t === "document") {
          return $decode.into(t);
        } else {
          return $decode.fail("type");
        }
      },
    ),
  );
  let _pipe$2 = $decode.field(
    _pipe$1,
    "tags",
    $decode.list($decode_.dynamic($tag.decode)),
  );
  let _pipe$3 = $decode.field(
    _pipe$2,
    "languages",
    $decode_.dynamic(decode_languages),
  );
  let _pipe$4 = $decode.field(
    _pipe$3,
    "qualification_matrix",
    $decode.optional($decode.list(decode_qualification_matrix())),
  );
  return $decode.field(
    _pipe$4,
    "default_language",
    $decode.optional($decode_.dynamic($translate.decode_language_iso639_1_dyn)),
  );
}

function decode_cell(dyn) {
  let _pipe = $decode.into(
    $decode.parameter(
      (value) => {
        return $decode.parameter(
          (dropdown) => { return new $spreadsheet.Cell(value, dropdown); },
        );
      },
    ),
  );
  let _pipe$1 = $decode.field(_pipe, "value", $decode.optional($decode.string));
  let _pipe$2 = $decode.field(_pipe$1, "dropdown", $decode.list($decode.string));
  return $decode.from(_pipe$2, dyn);
}

function spreadsheet_type_from_string(from_string) {
  let _pipe = from_string;
  let _pipe$1 = $spreadsheet.decode_type(_pipe);
  let _pipe$2 = $decode_.dynamic(_pipe$1);
  let _pipe$3 = $decode.optional(_pipe$2);
  return $decode.map(
    _pipe$3,
    (_capture) => { return $option.unwrap(_capture, $dict.new$()); },
  );
}

function decode_worksheet() {
  let _pipe = $decode.into(
    $decode.parameter(
      (name) => {
        return $decode.parameter(
          (start_index) => {
            return $decode.parameter(
              (content) => {
                return $decode.parameter(
                  (columns_type) => {
                    return $decode.parameter(
                      (rows_type) => {
                        return new $spreadsheet.Worksheet(
                          name,
                          start_index,
                          content,
                          columns_type,
                          rows_type,
                        );
                      },
                    );
                  },
                );
              },
            );
          },
        );
      },
    ),
  );
  let _pipe$1 = $decode.field(_pipe, "name", $decode.string);
  let _pipe$2 = $decode.field(
    _pipe$1,
    "start_index",
    (() => {
      let _pipe$2 = $decode.optional($decode.int);
      return $decode.map(
        _pipe$2,
        (_capture) => { return $option.unwrap(_capture, 0); },
      );
    })(),
  );
  let _pipe$3 = $decode.field(
    _pipe$2,
    "content",
    $decode.list(
      $decode.list(
        $decode.one_of(
          toList([
            $decode_.json(decode_cell),
            $decode_.dynamic(decode_string_to_cell),
          ]),
        ),
      ),
    ),
  );
  let _pipe$4 = $decode.field(
    _pipe$3,
    "columns_type",
    (() => {
      let _pipe$4 = $spreadsheet.column_type_from_string;
      return spreadsheet_type_from_string(_pipe$4);
    })(),
  );
  return $decode.field(
    _pipe$4,
    "rows_type",
    (() => {
      let _pipe$5 = $spreadsheet.row_type_from_string;
      return spreadsheet_type_from_string(_pipe$5);
    })(),
  );
}

export function decode_display(dyn) {
  let _pipe = $decode.into(
    $decode.parameter((type_) => { return new Display(type_); }),
  );
  let _pipe$1 = $decode.field(_pipe, "type", $decode.string);
  return $decode.from(_pipe$1, dyn);
}
