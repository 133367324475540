/// <reference types="./spreadsheet_selector.d.mts" />
import { File } from "../../common/data/ephemeral/file.mjs";
import * as $selector from "../../common/data/ephemeral/spreadsheet_selector.mjs";
import { SpreadsheetSelector } from "../../common/data/ephemeral/spreadsheet_selector.mjs";
import * as $spreadsheet from "../../common/data/spreadsheet.mjs";
import * as $form_data from "../../gleam_fetch/gleam/fetch/form_data.mjs";
import * as $http from "../../gleam_http/gleam/http.mjs";
import * as $promise from "../../gleam_javascript/gleam/javascript/promise.mjs";
import * as $json from "../../gleam_json/gleam/json.mjs";
import * as $bool from "../../gleam_stdlib/gleam/bool.mjs";
import * as $dict from "../../gleam_stdlib/gleam/dict.mjs";
import * as $function from "../../gleam_stdlib/gleam/function.mjs";
import * as $list from "../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../gleam_stdlib/gleam/option.mjs";
import * as $pair from "../../gleam_stdlib/gleam/pair.mjs";
import * as $result from "../../gleam_stdlib/gleam/result.mjs";
import * as $toast from "../../grille_pain/grille_pain/lustre/toast.mjs";
import * as $effect from "../../lustre/lustre/effect.mjs";
import * as $mime_types from "../../mime_types/mime_types.mjs";
import * as $file from "../../plinth/plinth/browser/file.mjs";
import * as $data from "../data/model/data.mjs";
import * as $msg from "../data/msg.mjs";
import * as $ask from "../frontend/ask.mjs";
import * as $middleware from "../frontend/middleware.mjs";
import { Ok, Error, toList, makeError, isEqual } from "../gleam.mjs";

function find_previous_column_type(selector, sheet, index) {
  return $list.find_map(
    selector.columns_type,
    (_use0) => {
      let name = _use0[0];
      let column_types = _use0[1];
      let error = new Error(new $spreadsheet.UndefinedColumn());
      return $bool.guard(
        name !== sheet,
        error,
        () => {
          let _pipe = $dict.get(column_types, index);
          return $result.try_recover(
            _pipe,
            (_) => { return new Ok(new $spreadsheet.UndefinedColumn()); },
          );
        },
      );
    },
  );
}

function update_column_type(_, selector, msg) {
  if (!(msg instanceof $msg.UserChangedColumnType)) {
    throw makeError(
      "let_assert",
      "update/spreadsheet_selector",
      108,
      "update_column_type",
      "Pattern match failed, no pattern matched the value.",
      { value: msg }
    )
  }
  let sheet = msg.sheet;
  let index = msg.index;
  let value = msg.type_;
  let previous_type = find_previous_column_type(selector, sheet, index);
  let _pipe = (() => {
    let $ = isEqual(previous_type, new Ok(new $spreadsheet.QuestionColumn()));
    if (!$) {
      return $selector.update_column_type(selector, sheet, index, value);
    } else {
      let _pipe = $selector.reset_invalid_lines(selector, sheet);
      return $selector.update_column_type(_pipe, sheet, index, value);
    }
  })();
  return $pair.new$(_pipe, $effect.none());
}

export function is_xlsx_fetched(msg) {
  if (msg instanceof $msg.ApiFetchedXlsx && msg.decoded_sheet.isOk()) {
    return true;
  } else {
    return false;
  }
}

function xlsx_fetched(selector, xlsx) {
  let _pipe = selector;
  let _pipe$1 = $selector.update_xlsx(_pipe, xlsx);
  return (() => {
    let $ = $list.first(xlsx.data);
    if (!$.isOk()) {
      return $function.identity;
    } else {
      let worksheet = $[0];
      return (_capture) => {
        return $selector.update_selected_sheet(_capture, worksheet.name);
      };
    }
  })()(_pipe$1);
}

function send_excel_decoding_request(model, file) {
  return $middleware.require_access_token(
    model,
    (access_token) => {
      return $middleware.require_org_id(
        model,
        (org_id) => {
          return $middleware.require_user_id(
            model,
            (user_id) => {
              return $effect.from(
                (dispatch) => {
                  return $function.tap(
                    undefined,
                    (_) => {
                      let _pipe = $ask.to(
                        new $ask.Nabu(),
                        toList(["decode-excel"]),
                      );
                      let _pipe$1 = $ask.via(_pipe, new $http.Post());
                      let _pipe$2 = $ask.bearing(_pipe$1, access_token);
                      let _pipe$3 = $ask.data(
                        _pipe$2,
                        (() => {
                          let org_id$1 = $json.string(org_id);
                          let user_id$1 = $json.string(user_id);
                          let _pipe$3 = $json.object(
                            toList([
                              ["org_id", org_id$1],
                              ["user_id", user_id$1],
                            ]),
                          );
                          let _pipe$4 = $json.to_string(_pipe$3);
                          let _pipe$5 = ((_capture) => {
                            return $form_data.append(
                              $form_data.new$(),
                              "content",
                              _capture,
                            );
                          })(_pipe$4);
                          return $form_data.append_bits(
                            _pipe$5,
                            "blob",
                            file.content,
                          );
                        })(),
                      );
                      let _pipe$4 = $ask.expect(
                        _pipe$3,
                        $spreadsheet.decode(file.content),
                      );
                      let _pipe$5 = $ask.notify(
                        _pipe$4,
                        (q) => {
                          return dispatch(new $msg.ApiFetchedXlsx(new Ok(q)));
                        },
                      );
                      return $ask.run(_pipe$5);
                    },
                  );
                },
              );
            },
          );
        },
      );
    },
  );
}

function send_detect_rows_request(model, file, columns_type, sheet) {
  return $middleware.require_access_token(
    model,
    (access_token) => {
      return $middleware.require_org_id(
        model,
        (org_id) => {
          return $middleware.require_user_id(
            model,
            (user_id) => {
              return $effect.from(
                (dispatch) => {
                  return $function.tap(
                    undefined,
                    (_) => {
                      return $option.map(
                        file,
                        (file) => {
                          let _pipe = $ask.to(
                            new $ask.Nabu(),
                            toList(["detect-rows"]),
                          );
                          let _pipe$1 = $ask.via(_pipe, new $http.Post());
                          let _pipe$2 = $ask.bearing(_pipe$1, access_token);
                          let _pipe$3 = $ask.data(
                            _pipe$2,
                            (() => {
                              let encode = (_capture) => {
                                return $pair.map_second(
                                  _capture,
                                  $spreadsheet.encode_columns_type,
                                );
                              };
                              let _pipe$3 = $json.object(
                                toList([
                                  [
                                    "columns_type",
                                    $json.object(
                                      $list.map(columns_type, encode),
                                    ),
                                  ],
                                  ["sheet", $json.string(sheet)],
                                  ["user_id", $json.string(user_id)],
                                  ["org_id", $json.string(org_id)],
                                ]),
                              );
                              let _pipe$4 = $json.to_string(_pipe$3);
                              let _pipe$5 = ((_capture) => {
                                return $form_data.append(
                                  $form_data.new$(),
                                  "content",
                                  _capture,
                                );
                              })(_pipe$4);
                              return $form_data.append_bits(
                                _pipe$5,
                                "blob",
                                file.content,
                              );
                            })(),
                          );
                          let _pipe$4 = $ask.expect(
                            _pipe$3,
                            $spreadsheet.decode_type(
                              $spreadsheet.row_type_from_string,
                            ),
                          );
                          let _pipe$5 = $ask.notify(
                            _pipe$4,
                            (r) => {
                              return dispatch(
                                new $msg.ApiAutoDetectedXlsxRows(sheet, r),
                              );
                            },
                          );
                          return $ask.run(_pipe$5);
                        },
                      );
                    },
                  );
                },
              );
            },
          );
        },
      );
    },
  );
}

function update_question_column(model, selector, msg) {
  if (!(msg instanceof $msg.UserChangedColumnType)) {
    throw makeError(
      "let_assert",
      "update/spreadsheet_selector",
      97,
      "update_question_column",
      "Pattern match failed, no pattern matched the value.",
      { value: msg }
    )
  }
  let sheet = msg.sheet;
  let index = msg.index;
  let value = msg.type_;
  let $ = $selector.update_column_type(selector, sheet, index, value);
  let selector$1 = $;
  let columns_type = $.columns_type;
  let file = $.file;
  return [
    selector$1,
    send_detect_rows_request(model, file, columns_type, sheet),
  ];
}

const error_msg = "The spreadsheet is too huge. Please, upload a file with less questions, or contact us directly.";

export function update(model, selector, msg) {
  if (msg instanceof $msg.UserChangedColumnType &&
  msg.type_ instanceof $spreadsheet.QuestionColumn) {
    return update_question_column(model, selector, msg);
  } else if (msg instanceof $msg.UserChangedColumnType) {
    return update_column_type(model, selector, msg);
  } else if (msg instanceof $msg.UserChangedInvalidLine) {
    let sheet = msg.sheet;
    let index = msg.index;
    let validity = msg.validity;
    let _pipe = $selector.update_invalid_line(selector, sheet, index, validity);
    return $pair.new$(_pipe, $effect.none());
  } else if (msg instanceof $msg.UserUpdatedSelectedSheet) {
    let sheet = msg.sheet;
    let _pipe = $selector.update_selected_sheet(selector, sheet);
    return $pair.new$(_pipe, $effect.none());
  } else if (msg instanceof $msg.UserSkippedSelectedSheet) {
    let current_sheet = msg.current_sheet;
    let next_sheet = msg.next_sheet;
    let _pipe = $selector.skip_selected_sheet(
      selector,
      current_sheet,
      next_sheet,
    );
    return $pair.new$(_pipe, $effect.none());
  } else if (msg instanceof $msg.ApiFetchedXlsx && msg.decoded_sheet.isOk()) {
    let xlsx = msg.decoded_sheet[0];
    return [xlsx_fetched(selector, xlsx), $effect.none()];
  } else if (msg instanceof $msg.ApiFetchedXlsx && !msg.decoded_sheet.isOk()) {
    return [selector, $toast.error(error_msg)];
  } else if (msg instanceof $msg.UserSelectedXlsx) {
    let file = msg.file;
    return [
      selector,
      $effect.from(
        (dispatch) => {
          return $function.tap(
            undefined,
            (_) => {
              return $promise.map(
                $file.bytes(file),
                (content) => {
                  return $result.map(
                    $mime_types.from_string($file.mime(file)),
                    (mime_type) => {
                      let file$1 = new File(
                        $file.name(file),
                        content,
                        mime_type,
                      );
                      return dispatch(new $msg.BrowserDecodedXlsxFile(file$1));
                    },
                  );
                },
              );
            },
          );
        },
      ),
    ];
  } else if (msg instanceof $msg.BrowserDecodedXlsxFile) {
    let file_ = msg.file;
    let file = new $option.Some(file_);
    let _pipe = selector.withFields({ file: file });
    return $pair.new$(_pipe, send_excel_decoding_request(model, file_));
  } else if (msg instanceof $msg.ApiAutoDetectedXlsxRows) {
    let sheet = msg.sheet;
    let rows_type = msg.rows_type;
    let _pipe = (s, r, v) => {
      return $selector.update_invalid_line(s, sheet, r, v);
    };
    let _pipe$1 = ((_capture) => {
      return $dict.fold(rows_type, selector, _capture);
    })(_pipe);
    return $pair.new$(_pipe$1, $effect.none());
  } else {
    throw makeError(
      "panic",
      "update/spreadsheet_selector",
      88,
      "update",
      "Should be handled outside of selector.update",
      {}
    )
  }
}
