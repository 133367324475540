/// <reference types="./pagination.d.mts" />
import * as $colors from "../../../ds/ds/colors.mjs";
import * as $s from "../../../sketch/sketch.mjs";
import * as $size from "../../../sketch/sketch/size.mjs";
import { percent, px } from "../../../sketch/sketch/size.mjs";
import * as $h from "../../../sketch_magic/sketch/magic/element/html.mjs";
import { toList } from "../../gleam.mjs";

function page_selector_border() {
  return $s.class$(
    toList([
      $s.border_left("1px solid " + $colors.light.grey_400),
      $s.border_right("1px solid " + $colors.light.grey_400),
      $s.border_bottom("1px solid " + $colors.light.grey_400),
      $s.border_bottom_right_radius(px(8)),
    ]),
  );
}

export function page_selector(border, attrs, children) {
  let _pipe = $s.class$(
    toList([
      $s.width(percent(100)),
      $s.align_items("center"),
      $s.justify_content("end"),
      $s.display("flex"),
      $s.flex_direction("row"),
      $s.padding(px(8)),
      $s.cursor("pointer"),
      (() => {
        if (!border) {
          return $s.none();
        } else {
          return $s.compose(page_selector_border());
        }
      })(),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

function page_selector_button() {
  return $s.class$(
    toList([
      $s.font_family("inherit"),
      $s.display("flex"),
      $s.align_items("center"),
      $s.justify_content("center"),
      $s.padding(px(6)),
      $s.border_right("1px solid " + $colors.light.grey_400),
      $s.border_top("1px solid " + $colors.light.grey_400),
      $s.border_bottom("1px solid " + $colors.light.grey_400),
      $s.height(percent(100)),
      $s.width(px(40)),
      $s.transition("color .3s, background .3s"),
      $s.hover(toList([$s.background($colors.light.grey_200)])),
      $s.disabled(
        toList([
          $s.color($colors.light.grey_200),
          $s.background($colors.light.white),
          $s.cursor("default"),
        ]),
      ),
    ]),
  );
}

export function page_selector_prev(attrs, children) {
  let _pipe = $s.class$(
    toList([
      $s.compose(page_selector_button()),
      $s.border_left("1px solid " + $colors.light.grey_400),
      $s.border_radius_("8px 0px 0px 8px"),
    ]),
  );
  return $h.button(_pipe, attrs, children);
}

export function page_selector_next(attrs, children) {
  let _pipe = $s.class$(
    toList([
      $s.compose(page_selector_button()),
      $s.border_radius_("0px 8px 8px 0px"),
    ]),
  );
  return $h.button(_pipe, attrs, children);
}

export function page_selector_page(selected, attrs, children) {
  let _pipe = $s.class$(
    toList([
      $s.compose(page_selector_button()),
      (() => {
        if (selected) {
          return $s.background($colors.light.grey_200);
        } else {
          return $s.background($colors.light.white);
        }
      })(),
      (() => {
        if (selected) {
          return $s.cursor("default");
        } else {
          return $s.cursor("pointer");
        }
      })(),
    ]),
  );
  return $h.button(_pipe, attrs, children);
}

export function page_selector_counter(attrs, children) {
  let _pipe = $s.class$(
    toList([
      $s.font_family("inherit"),
      $s.display("flex"),
      $s.align_items("center"),
      $s.justify_content("center"),
      $s.height(percent(100)),
      $s.padding(px(6)),
      $s.width(px(100)),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}
