/// <reference types="./page_break.d.mts" />
import * as $json from "../../../gleam_json/gleam/json.mjs";
import * as $decode from "../../../gleam_stdlib/gleam/dynamic/decode.mjs";
import * as $int from "../../../gleam_stdlib/gleam/int.mjs";
import * as $option from "../../../gleam_stdlib/gleam/option.mjs";
import { None } from "../../../gleam_stdlib/gleam/option.mjs";
import * as $a from "../../../lustre/lustre/attribute.mjs";
import * as $html from "../../../lustre/lustre/element/html.mjs";
import * as $kind from "../../data/docx/kind.mjs";
import { toList, CustomType as $CustomType } from "../../gleam.mjs";

export class PageBreak extends $CustomType {
  constructor(index, metadata) {
    super();
    this.index = index;
    this.metadata = metadata;
  }
}

export class Metadata extends $CustomType {
  constructor(kind) {
    super();
    this.kind = kind;
  }
}

export function to_html(page_break) {
  let index = $a.attribute("data-index", $int.to_string(page_break.index));
  return $html.hr(toList([index]));
}

function type_decoder() {
  return $decode.then$(
    $decode.string,
    (string) => {
      if (string === "page_break") {
        return $decode.success(undefined);
      } else {
        return $decode.failure(undefined, "page_break");
      }
    },
  );
}

function metadata_decoder() {
  let decoder$1 = $decode.optional($kind.decoder());
  return $decode.optional_field(
    "kind",
    new None(),
    decoder$1,
    (kind) => { return $decode.success(new Metadata(kind)); },
  );
}

export function encode(page_break) {
  return $json.object(
    toList([
      ["type", $json.string("page_break")],
      ["index", $json.int(page_break.index)],
      ["html_tag", $json.string("")],
      ["metadata", $json.object(toList([]))],
    ]),
  );
}

function empty_metadata() {
  return new Metadata(new None());
}

export function decoder() {
  return $decode.field(
    "type",
    type_decoder(),
    (_) => {
      return $decode.field(
        "index",
        $decode.int,
        (index) => {
          return $decode.optional_field(
            "metadata",
            empty_metadata(),
            metadata_decoder(),
            (metadata) => {
              return $decode.success(new PageBreak(index, metadata));
            },
          );
        },
      );
    },
  );
}
