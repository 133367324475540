import { Node, NodeViewRendererProps } from '@tiptap/core'
import * as h from '../utils/html'

type Kind = 'other' | 'question' | 'section' | 'subsection'

export const DocumentLabeller = Node.create({
  name: 'documentLabeller',
  group: 'block',
  content: 'block*',
  addAttributes() {
    return {
      kind: { default: 'other' as Kind },
      openedMenu: { default: false },
      index: {
        default: -1,
        parseHTML: element => element.getAttribute('data-index'),
        renderHTML: attributes => ({ ['data-index']: attributes.index }),
      },
    }
  },
  parseHTML() {
    return [{ tag: 'document-labeller' }]
  },
  renderHTML({ HTMLAttributes }) {
    return ['document-labeller', HTMLAttributes, 0]
  },
  addNodeView() {
    // prettier-ignore
    return (props) => {
      const { node } = props
      const contentDOM = h.div([h.class_('labeller-content')], [])

      const isVisible = node.attrs.openedMenu || node.attrs.kind !== 'other'
      const visibleClass = isVisible ? h.class_('visible') : h.none()
      const { background, color } = selectColors(node.attrs.kind)
      const bgColor = h.style(isVisible ? { background } : {})
      const textColor = h.style(isVisible ? { color } : {})

      const dom =
        h.div([h.class_('labeller-view'), visibleClass], [
          h.div([h.class_('labeller-label'), bgColor, textColor, onMenuClick(props)], [
            h.div([h.style({ paddingTop: 1, width: 18, height: 18 })], [
              h.img([h.style({ width: '100%' }), h.src(kindToIcon(node.attrs.kind))]),
            ]),
            h.text(`${kindToString(node.attrs.kind)}`),
            h.div([h.style({ paddingTop: 4, width: 20, height: 20 })], [
              h.img([h.style({ width: '100%' }), h.src("/assets/editor/arrow-down.svg")]),
            ]),
            node.attrs.openedMenu && (
              h.div([h.class_('labeller-label-display')], [
                panelButton(props, 'other'),
                panelButton(props, 'question'),
                panelButton(props, 'section'),
                panelButton(props, 'subsection'),
              ])
            ),
          ]),
          h.div([h.class_('labeller-content-wrapper'), bgColor], [contentDOM])
        ])
      return { dom, contentDOM }
    }
  },
})

function panelButton(props: NodeViewRendererProps, kind: Kind) {
  const klass = h.class_('labeller-label-label')
  const onClick = onLabelClick(props, kind)
  const style_ = h.style({ background: 'white', color: '#888' })
  const label = kindToString(kind)
  return h.button([klass, onClick, style_], [h.text(label)])
}

function onMenuClick(props: NodeViewRendererProps) {
  return h.onClick(event => {
    const { getPos, view, node } = props
    event.stopPropagation()
    const pos = getPos()
    const openedMenu = !node.attrs.openedMenu
    const tx = view.state.tr.setNodeAttribute(pos, 'openedMenu', openedMenu)
    view.dispatch(tx)
  })
}

function onLabelClick(props: NodeViewRendererProps, kind: Kind) {
  return h.onClick(event => {
    const { getPos, view, node } = props
    event.stopPropagation()
    const pos = getPos()
    const openedMenu = false
    const tx = view.state.tr
      .setNodeMarkup(pos, undefined, { ...node.attrs, kind, openedMenu })
      .setMeta('index', node.attrs.index)
      .setMeta('kind', kind)
      .setMeta('action', 'labelling')
      .setMeta('previous-kind', node.attrs.kind)
    view.dispatch(tx)
  })
}

function selectColors(kind: Kind) {
  switch (kind) {
    case 'question':
      return { background: '#F0FDD1', color: '#87B024' } as const
    case 'section':
    case 'subsection':
      return { background: '#eef4fe', color: '#3b75ca' } as const
    case 'other':
    default:
      return { background: '#f5f5f5', color: '#888' } as const
  }
}

function kindToString(kind: Kind) {
  switch (kind) {
    case 'question':
      return 'Question'
    case 'section':
      return 'Section'
    case 'subsection':
      return 'Subsection'
    case 'other':
    default:
      return 'Uncategorised'
  }
}

function kindToIcon(kind: Kind) {
  switch (kind) {
    case 'question':
      return '/assets/editor/question.svg'
    case 'section':
      return '/assets/editor/h1.svg'
    case 'subsection':
      return '/assets/editor/h2.svg'
    case 'other':
      return '/assets/editor/other.svg'
  }
}
