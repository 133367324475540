/// <reference types="./analytics.d.mts" />
import * as $bright from "../../bright/bright.mjs";
import * as $user from "../../common/data/user.mjs";
import * as $colors from "../../ds/ds/colors.mjs";
import * as $layout from "../../ds/ds/components/layout.mjs";
import * as $pp from "../../ds/ds/components/profile_picture.mjs";
import * as $tooltip from "../../ds/ds/components/tooltip.mjs";
import * as $float from "../../gleam_stdlib/gleam/float.mjs";
import * as $int from "../../gleam_stdlib/gleam/int.mjs";
import * as $list from "../../gleam_stdlib/gleam/list.mjs";
import * as $a from "../../lustre/lustre/attribute.mjs";
import * as $el from "../../lustre/lustre/element.mjs";
import * as $h from "../../lustre/lustre/element/html.mjs";
import * as $random from "../../prng/prng/random.mjs";
import * as $seed from "../../prng/prng/seed.mjs";
import * as $model from "../data/model.mjs";
import * as $charts from "../elements/charts.mjs";
import * as $dataset from "../elements/charts/dataset.mjs";
import { toList } from "../gleam.mjs";
import * as $common from "../view/common.mjs";
import * as $s from "../view/styles/analytics.mjs";

export function pie_chart_win_rate() {
  return $s.section(
    toList([$a.style(toList([["align-items", "start"]]))]),
    toList([
      $s.section_title(toList([]), toList([$h.text("Win rate")])),
      $s.statistics_content_wrapper(
        toList([]),
        toList([
          $s.statistics_content(
            toList([]),
            toList([
              $charts.pie(
                toList([
                  new $dataset.Dataset(
                    toList([$colors.light.success_600, $colors.light.error_600]),
                    toList([70.0, 30.0]),
                    toList([]),
                  ),
                ]),
              ),
              $s.statistics_wrapper(
                toList([]),
                toList([
                  $s.statistics_value(
                    toList([]),
                    toList([
                      $s.statistics_label(
                        toList([]),
                        toList([
                          $s.statistics_color($colors.light.success_600),
                          $h.text("Won"),
                        ]),
                      ),
                      $h.text("70%"),
                    ]),
                  ),
                  $s.statistics_value(
                    toList([]),
                    toList([
                      $s.statistics_label(
                        toList([]),
                        toList([
                          $s.statistics_color($colors.light.error_600),
                          $h.text("Loss"),
                        ]),
                      ),
                      $h.text("30%"),
                    ]),
                  ),
                  $common.separator(),
                  $s.statistics_value(
                    toList([]),
                    toList([
                      $s.statistics_colored_label(
                        $colors.light.success_800,
                        toList([]),
                        toList([$h.text("Won ARR")]),
                      ),
                      $h.text("$1,200,000"),
                    ]),
                  ),
                  $s.statistics_value(
                    toList([]),
                    toList([
                      $s.statistics_colored_label(
                        $colors.light.error_800,
                        toList([]),
                        toList([$h.text("Lost ARR")]),
                      ),
                      $h.text("$500,000"),
                    ]),
                  ),
                ]),
              ),
            ]),
          ),
        ]),
      ),
    ]),
  );
}

export function pie_chart_progress() {
  return $s.section(
    toList([$a.style(toList([["align-items", "start"]]))]),
    toList([
      $s.section_title(toList([]), toList([$h.text("Progress")])),
      $s.statistics_content_wrapper(
        toList([]),
        toList([
          $s.statistics_content(
            toList([]),
            toList([
              $charts.pie(
                toList([
                  new $dataset.Dataset(
                    toList([$colors.light.blue_600, $colors.light.success_600]),
                    toList([20.0, 80.0]),
                    toList([]),
                  ),
                ]),
              ),
              $s.statistics_wrapper(
                toList([]),
                toList([
                  $s.statistics_value(
                    toList([]),
                    toList([
                      $s.statistics_label(
                        toList([]),
                        toList([
                          $s.statistics_color($colors.light.success_600),
                          $h.text("Validated"),
                        ]),
                      ),
                      $h.text("80%"),
                    ]),
                  ),
                  $s.statistics_value(
                    toList([]),
                    toList([
                      $s.statistics_label(
                        toList([]),
                        toList([
                          $s.statistics_color($colors.light.blue_600),
                          $h.text("In review"),
                        ]),
                      ),
                      $h.text("20%"),
                    ]),
                  ),
                  $common.separator(),
                  $s.statistics_value(
                    toList([]),
                    toList([
                      $s.statistics_colored_label(
                        $colors.light.success_600,
                        toList([]),
                        toList([$h.text("# Answers validated")]),
                      ),
                      $h.text("125"),
                    ]),
                  ),
                  $s.statistics_value(
                    toList([]),
                    toList([
                      $s.statistics_colored_label(
                        $colors.light.blue_600,
                        toList([]),
                        toList([$h.text("# Answers in review")]),
                      ),
                      $h.text("21"),
                    ]),
                  ),
                ]),
              ),
            ]),
          ),
        ]),
      ),
    ]),
  );
}

export function bar_plot_proposals() {
  let fake_data = toList([
    12.0,
    6.0,
    15.0,
    12.0,
    3.0,
    13.0,
    12.0,
    10.0,
    1.0,
    5.0,
    6.0,
    4.0,
  ]);
  let fake_data_labels = toList([
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ]);
  return $s.section(
    toList([$a.style(toList([["align-items", "start"]]))]),
    toList([
      $s.section_title(toList([]), toList([$h.text("Number of proposals")])),
      $s.statistics_content_wrapper(
        toList([]),
        toList([
          $s.statistics_content(
            toList([]),
            toList([
              $charts.bar(
                "vertical",
                toList([
                  new $dataset.Dataset(
                    $list.repeat(
                      $colors.light.grey_200,
                      $list.length(fake_data),
                    ),
                    fake_data,
                    fake_data_labels,
                  ),
                ]),
              ),
            ]),
          ),
        ]),
      ),
    ]),
  );
}

export function time_spent_responding() {
  let fake_data = toList([
    20.0,
    40.0,
    34.0,
    25.0,
    50.0,
    24.0,
    25.0,
    40.0,
    80.0,
    50.0,
    60.0,
  ]);
  let fake_data_labels = toList([
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ]);
  return $s.section(
    toList([$a.style(toList([["align-items", "start"]]))]),
    toList([
      $s.section_title(toList([]), toList([$h.text("Number of proposals")])),
      $s.statistics_content_wrapper(
        toList([]),
        toList([
          $s.statistics_content(
            toList([]),
            toList([
              $charts.bar(
                "vertical",
                toList([
                  new $dataset.Dataset(
                    $list.repeat(
                      $colors.light.grey_200,
                      $list.length(fake_data),
                    ),
                    fake_data,
                    fake_data_labels,
                  ),
                ]),
              ),
            ]),
          ),
        ]),
      ),
    ]),
  );
}

export function generate_fake_progress() {
  let generator = $random.float(0.0, 100.0);
  let answers_validated = (() => {
    let _pipe = $random.sample(generator, $seed.random());
    return $float.round(_pipe);
  })();
  let answers_in_review = 100 - answers_validated;
  return [answers_validated, answers_in_review];
}

function progress_bar_half(position, kind, width, content, tooltip) {
  let width$1 = $int.to_string(width) + "%";
  let style = $a.style(toList([["width", width$1], ["transition", "all .2s"]]));
  let color = (() => {
    if (kind instanceof $s.Validated) {
      return $colors.light.success_600;
    } else {
      return $colors.light.blue_600;
    }
  })();
  return $tooltip.tooltip(
    toList([$tooltip.position(position), style]),
    $s.progress_bar_element(color, kind, toList([$h.text(content)])),
    $s.progress_bar_tooltip(toList([]), toList([$h.text(tooltip)])),
  );
}

export function view_user_progress_bar(user) {
  let $ = generate_fake_progress();
  let answers_validated = $[0];
  let answers_in_review = $[1];
  let in_review_content = $int.to_string(answers_in_review) + " questions in review";
  let in_review_explanation = ("There is " + $int.to_string(answers_in_review)) + " questions in review";
  let validated_content = $int.to_string(answers_validated) + " questions validated";
  let validated_explanation = ("There is " + $int.to_string(answers_validated)) + " questions validated";
  return $el.fragment(
    toList([
      $common.name_small(
        toList([]),
        toList([
          $pp.from_user(user.picture, user.initials, new $pp.Medium()),
          $el.text(user.name),
        ]),
      ),
      $s.progress_bar(
        toList([]),
        toList([
          progress_bar_half(
            $tooltip.left,
            new $s.Validated(),
            answers_validated,
            validated_content,
            validated_explanation,
          ),
          progress_bar_half(
            $tooltip.right,
            new $s.InReview(),
            answers_in_review,
            in_review_content,
            in_review_explanation,
          ),
        ]),
      ),
    ]),
  );
}

export function progress_per_collaborator(users) {
  return $s.section(
    toList([$a.style(toList([["align-items", "start"]]))]),
    toList([
      $s.section_title(
        toList([]),
        toList([$h.text("Progress per collaborator")]),
      ),
      $s.statistics_content_wrapper(
        toList([$a.style(toList([["width", "100%"]]))]),
        toList([
          $s.statistics_content(
            toList([]),
            toList([
              $s.progress_content_wrapper(
                toList([]),
                $list.map(
                  users,
                  (user) => { return view_user_progress_bar(user); },
                ),
              ),
            ]),
          ),
        ]),
      ),
    ]),
  );
}

export function view(model) {
  let $ = $bright.unwrap(model);
  let model$1 = $[0];
  return $el.fragment(
    toList([
      $layout.row(
        toList([$layout.justify("space-between")]),
        toList([]),
        toList([pie_chart_win_rate(), bar_plot_proposals()]),
      ),
      $layout.row(
        toList([$layout.justify("space-between")]),
        toList([]),
        toList([time_spent_responding(), pie_chart_progress()]),
      ),
      progress_per_collaborator(model$1.users),
    ]),
  );
}
