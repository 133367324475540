/// <reference types="./messages.d.mts" />
import * as $connector from "../../../common/data/connector.mjs";
import * as $connector_settings from "../../../common/data/connector_settings.mjs";
import * as $data_point from "../../../common/data/data_point.mjs";
import * as $data_source from "../../../common/data/data_source.mjs";
import * as $notification from "../../../common/data/notification.mjs";
import * as $proposal from "../../../common/data/proposal.mjs";
import * as $proposal_element from "../../../common/data/proposal_element.mjs";
import * as $translate from "../../../common/data/translate.mjs";
import * as $user from "../../../common/data/user.mjs";
import * as $dynamic from "../../../gleam_stdlib/gleam/dynamic.mjs";
import * as $result from "../../../gleam_stdlib/gleam/result.mjs";
import { Error, toList, CustomType as $CustomType } from "../../gleam.mjs";

export class ApiSentProposalUpdated extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class ApiSentConnectorRegistered extends $CustomType {
  constructor(x0, x1) {
    super();
    this[0] = x0;
    this[1] = x1;
  }
}

export class ApiSentConnectorSettingsUpdated extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class ApiSentNotificationUpdated extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class ApiSentDataSourcesUpdated extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class ApiSentUserDataUpdated extends $CustomType {
  constructor(x0, x1, x2) {
    super();
    this[0] = x0;
    this[1] = x1;
    this[2] = x2;
  }
}

export class ApiSentTranslation extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class ApiSentElementUpdated extends $CustomType {
  constructor(x0, x1, x2) {
    super();
    this[0] = x0;
    this[1] = x1;
    this[2] = x2;
  }
}

function decode_proposal_updated(dyn) {
  return $dynamic.decode1(
    (var0) => { return new ApiSentProposalUpdated(var0); },
    $dynamic.field("proposal", $proposal.decode),
  )(dyn);
}

function decode_element_updated(dyn) {
  return $dynamic.decode3(
    (var0, var1, var2) => { return new ApiSentElementUpdated(var0, var1, var2); },
    $dynamic.field("element", $proposal_element.decode),
    $dynamic.field("data_sources", $dynamic.list($data_source.decode)),
    $dynamic.field("data_points", $dynamic.list($data_point.decode)),
  )(dyn);
}

function decode_connector_registered(dyn) {
  return $dynamic.decode2(
    (var0, var1) => { return new ApiSentConnectorRegistered(var0, var1); },
    $dynamic.field("connector", $connector.decode),
    $dynamic.field("status", $dynamic.bool),
  )(dyn);
}

function decode_connector_settings_updated(dyn) {
  return $dynamic.decode1(
    (var0) => { return new ApiSentConnectorSettingsUpdated(var0); },
    $dynamic.field("connector_settings", $connector_settings.decode),
  )(dyn);
}

function decode_notification_updated(dyn) {
  return $dynamic.decode1(
    (var0) => { return new ApiSentNotificationUpdated(var0); },
    $dynamic.field("notification", $notification.decode),
  )(dyn);
}

function decode_data_sources_updated(dyn) {
  return $dynamic.decode1(
    (var0) => { return new ApiSentDataSourcesUpdated(var0); },
    $dynamic.field("data_sources", $dynamic.list($data_source.decode)),
  )(dyn);
}

function decode_user_data_updated(dyn) {
  return $dynamic.decode3(
    (var0, var1, var2) => {
      return new ApiSentUserDataUpdated(var0, var1, var2);
    },
    $dynamic.field("user_id", $dynamic.string),
    $dynamic.field("org_id", $dynamic.string),
    $dynamic.field("function", $user.decode_function),
  )(dyn);
}

function decode_translation(dyn) {
  return $dynamic.decode1(
    (var0) => { return new ApiSentTranslation(var0); },
    $dynamic.field("translation", $translate.decode_translation),
  )(dyn);
}

export function decode_incoming_message(dyn) {
  return $result.try$(
    $dynamic.field("type", $dynamic.string)(dyn),
    (type_) => {
      if (type_ === "proposal") {
        return decode_proposal_updated(dyn);
      } else if (type_ === "proposal_element") {
        return decode_element_updated(dyn);
      } else if (type_ === "connector_registered") {
        return decode_connector_registered(dyn);
      } else if (type_ === "connector_settings") {
        return decode_connector_settings_updated(dyn);
      } else if (type_ === "notification") {
        return decode_notification_updated(dyn);
      } else if (type_ === "data_sources") {
        return decode_data_sources_updated(dyn);
      } else if (type_ === "user_data") {
        return decode_user_data_updated(dyn);
      } else if (type_ === "translation") {
        return decode_translation(dyn);
      } else {
        let found = type_;
        let expected = "A valid type for incoming message";
        let path = toList(["incoming_message", "type"]);
        return new Error(
          toList([new $dynamic.DecodeError(expected, found, path)]),
        );
      }
    },
  );
}
